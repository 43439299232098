import React from 'react';
import { Link } from 'react-router-dom';
import { Field, FieldArray, reduxForm } from 'redux-form';
import { validate, validators } from 'validate-redux-form';
//import {renderSearchCreateSelect, renderField, renderFieldCheck} from "../../Utils/renderField";
import {renderSearchCreateSelect, renderField, renderTextArea, renderFieldCheck, modalTextArea} from "../../Utils/renderField/renderField";
import {LAB} from "../../../../utility/variables";
import {api} from "../../../../api/api";

const getRefillable = (search) => {
    let opciones = [];
    return api.get('refillable/getRefillable', {search, record_type: LAB}).then((data) => {
        console.log("Data: ", data);
        data.results.forEach(item => {
            opciones.push({
                value: item.id,
                label: item.texto
            });
        });
        return opciones;
    }).catch(()=>[]);
}

export const renderPlanRow =({ fields, meta: {error, submitFailed}, edit, view}) => (
    <React.Fragment>
    <div className='d-flex flex-column w-100'>
        <div className='d-flex flex-row w-100'>
            <div className='d-flex flex-column justify-content-start align-items-center w-50'>
                <div
                    className='d-flex flex-row w-100 justify-content-center mb-2'
                    style={{color:'#0a5995', fontWeight: 'bold'}}
                >
                    <div className='d-flex flex-row w-50'>EXAMEN SOLICITADO</div>
                    <div className='d-flex flex-row w-25'>
                        <div className='w-50'>OD</div>
                        <div className='w-50'>OS</div>
                    </div>
                </div>

                {fields.map((field, index) => (
                    <React.Fragment key={index}>
                    {/*index === 3 &&
                    <div className='d-flex flex-row w-100 justify-content-center mb-2'>
                        <div className='d-flex flex-row w-50'>Refracción</div>
                        <div className='d-flex flex-row w-25'>
                            <div className='w-50'>
                                <Field
                                    name='refraction_od'
                                    component='input'
                                    type='text'
                                    style={{
                                        width: '90%',
                                        outline: 'none',
                                        borderRadius: 10,
                                        border: 'solid 1px #0a5995',
                                        padding: '1px 3px 1px',
                                    }}
                                />
                            </div>
                            <div className='w-50'>
                                <Field
                                    name='refraction_os'
                                    component='input'
                                    type='text'
                                    style={{
                                        width: '90%',
                                        outline: 'none',
                                        borderRadius: 10,
                                        border: 'solid 1px #0a5995',
                                        padding: '1px 3px 1px',
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    */}


                    {(
                        fields.get(index).nombre.toLowerCase() === "biometría de inmersión" ||
                        fields.get(index).nombre.toLowerCase() === "biometria de inmersion" ||
                        fields.get(index).nombre.toLowerCase() === 'biometría de inmersion'  ||
                        fields.get(index).nombre.toLowerCase() === 'biometria de inmersión'
                    ) &&
                    <React.Fragment>
                        <div className='d-flex flex-row w-100 justify-content-center mb-2'>
                            <div className='d-flex flex-row w-50'>KOD</div>
                            <div className='d-flex flex-row w-25'>
                                <div className='w-50'>
                                    <Field
                                        name='koo_od'
                                        component='input'
                                        type='text'
                                        style={{
                                            width: '90%',
                                            outline: 'none',
                                            borderRadius: 10,
                                            border: 'solid 1px #0a5995',
                                            padding: '1px 3px 1px',
                                        }}
                                        disabled={view}
                                    />
                                </div>
                                <div className='w-50'>
                                    <Field
                                        name='koo_os'
                                        component='input'
                                        type='text'
                                        style={{
                                            width: '90%',
                                            outline: 'none',
                                            borderRadius: 10,
                                            border: 'solid 1px #0a5995',
                                            padding: '1px 3px 1px',
                                        }}
                                        disabled={view}
                                    />
                                </div>
                                <div className='d-flex w-50'>
                                    X<Field
                                        name='kod_eje'
                                        component='input'
                                        type='text'
                                        style={{
                                            width: '90%',
                                            outline: 'none',
                                            borderRadius: 10,
                                            border: 'solid 1px #0a5995',
                                            padding: '1px 3px 1px',
                                        }}
                                        disabled={view}
                                    />
                                </div>                                
                            </div>
                        </div>
                        <div className='d-flex flex-row w-100 justify-content-center mb-2'>
                            <div className='d-flex flex-row w-50'>KOS</div>
                            <div className='d-flex flex-row w-25'>
                                <div className='w-50'>
                                    <Field
                                        name='kos_od'
                                        component='input'
                                        type='text'
                                        style={{
                                            width: '90%',
                                            outline: 'none',
                                            borderRadius: 10,
                                            border: 'solid 1px #0a5995',
                                            padding: '1px 3px 1px',
                                        }}
                                        disabled={view}
                                    />
                                </div>
                                <div className='w-50'>
                                    <Field
                                        name='kos_os'
                                        component='input'
                                        type='text'
                                        style={{
                                            width: '90%',
                                            outline: 'none',
                                            borderRadius: 10,
                                            border: 'solid 1px #0a5995',
                                            padding: '1px 3px 1px',
                                        }}
                                        disabled={view}
                                    />
                                </div>
                                <div className='d-flex w-50'>
                                    X<Field
                                        name='kos_eje'
                                        component='input'
                                        type='text'
                                        style={{
                                            width: '90%',
                                            outline: 'none',
                                            borderRadius: 10,
                                            border: 'solid 1px #0a5995',
                                            padding: '1px 3px 1px',
                                        }}
                                        disabled={view}
                                    />
                                </div>                                 
                            </div>
                        </div>
                    </React.Fragment>
                    }

                    {/*index === 11 &&
                    <div className='d-flex flex-row w-100 justify-content-center mb-2'>
                        <div className='d-flex flex-row justify-content-center w-100'>
                            <div className='d-flex flex-row'>OD</div>
                            <div className='w-15 ml-2'>
                                <Field
                                    name='oct_od'
                                    component='input'
                                    type='text'
                                    style={{
                                        width: '100%',
                                        outline: 'none',
                                        borderRadius: 10,
                                        border: 'solid 1px #0a5995',
                                        padding: '1px 3px 1px',
                                    }}
                                />
                            </div>
                            <div className='d-flex flex-row ml-2'>OS</div>
                            <div className='w-15 ml-2'>
                                <Field
                                    name='oct_os'
                                    component='input'
                                    type='text'
                                    style={{
                                        width: '100%',
                                        outline: 'none',
                                        borderRadius: 10,
                                        border: 'solid 1px #0a5995',
                                        padding: '1px 3px 1px',
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    */}

                    {((fields.get(index).plan && fields.get(index).plan.tipo_plan === 1) || fields.get(index).tipo_plan === 1) &&
                        <div className="d-flex flex-row w-100 justify-content-center align-items-start" key={index}>
                            <div className="d-flex">
                                <Field
                                    name={`${field}.id`}
                                    component='input'
                                    type='hidden'
                                />
                            </div>

                            <div className="w-50 mr-2">
                                <Field
                                    name={`${field}.nombre`}
                                    component='textarea'
                                    style={{
                                        width: '100%',
                                        background: 'white',
                                        border: 'none',
                                        resize: 'none',
                                    }}
                                    disabled={view}
                                />
                            </div>

                            <div className='d-flex flex-row w-25'>
                                <div className="d-flex w-50">
                                    <Field
                                        name={`${field}.od`}
                                        component={renderFieldCheck}
                                        disabled={view}
                                    />
                                </div>

                                <div className="d-flex w-50">
                                    <Field
                                        name={`${field}.os`}
                                        component={renderFieldCheck}
                                        disabled={view}
                                    />
                                </div>
                            </div>
                        </div>
                        }
                        </React.Fragment>
                        )
                    )
                }
                <div className='d-flex flex-row w-100 justify-content-center mb-2'>
                    <div className='d-flex flex-row w-25'></div>
                    <div className='d-flex flex-row w-50'>
                        <div className='w-100 d-flex flex-row justify-content-between align-items-center'>
                            <label className='mr-2'>Otros</label>
                            <Field
                                name='examen_note'
                                component='input'
                                type='text'
                                style={{
                                    width: '90%',
                                    outline: 'none',
                                    borderRadius: 10,
                                    border: 'solid 1px #0a5995',
                                    padding: '1px 3px 1px',
                                }}
                                disabled={view}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className='d-flex flex-column justify-content-start align-items-center w-50'>
                <div
                    className='d-flex flex-row w-100 justify-content-center mb-2'
                    style={{color:'#0a5995', fontWeight: 'bold'}}
                >
                    <div className='d-flex flex-row w-50'>PROCEDIMIENTO SOLICITADO</div>
                    <div className='d-flex flex-row w-25'>
                        <div className='w-50'>OD</div>
                        <div className='w-50'>OS</div>
                    </div>
                </div>
            {fields.map((field, index) => (
                <React.Fragment key={index}>
                {((fields.get(index).plan && fields.get(index).plan.tipo_plan === 2) || fields.get(index).tipo_plan === 2) &&
                    <div className="d-flex flex-row w-100 justify-content-center align-items-start" key={index}>
                        <div className="d-flex">
                            <Field
                                name={`${field}.id`}
                                component='input'
                                type='hidden'
                            />
                        </div>

                        <div className="w-50 mr-2">
                            <Field
                                name={`${field}.nombre`}
                                component='textarea'
                                style={{
                                    width: '100%',
                                    background: 'white',
                                    border: 'none',
                                    resize: 'none',
                                }}
                                disabled={view}
                            />
                        </div>
                        <div className='d-flex flex-row w-25'>
                            <div className="d-flex w-50 flex-column align-items-center">
                                <Field
                                    name={`${field}.od`}
                                    component={renderFieldCheck}
                                    disabled={view}
                                />
                            </div>

                            <div className="d-flex w-50">
                                <Field
                                    name={`${field}.os`}
                                    component={renderFieldCheck}
                                    disabled={view}
                                />
                            </div>
                        </div>
                    </div>
                    }
                    </React.Fragment>
                    )
                )
            }
            <div className='d-flex flex-row w-100 justify-content-center mb-2'>
                <div className='d-flex flex-row w-25'></div>
                <div className='d-flex flex-row w-50'>
                    <div className='d-flex flex-row w-100'>
                        <label className='mr-2'>Otros</label>
                        <Field
                            name='surgery_note'
                            component='input'
                            type='text'
                            style={{
                                width: '90%',
                                outline: 'none',
                                borderRadius: 10,
                                border: 'solid 1px #0a5995',
                                padding: '1px 3px 1px',
                            }}
                            disabled={view}
                        />
                    </div>
                </div>
            </div>
            </div>
        </div>
        <div className='d-flex flex-column justify-content-center align-items-center w-100 mt-3 mb-5'>
            <div
                className='d-flex flex-row w-100 justify-content-center align-items-center'
                style={{
                    background: 'gray',
                    color: 'white',
                    fontWeight: 'bold',
                    padding: '8px 2px 8px',
                }}
            >
                <div className='d-flex w-40 justify-content-center'>Paquete</div>
                <div className='d-flex w-60 justify-content-center'>Exámenes que incluye</div>
                <div className='d-flex w-25'>
                    <div className='d-flex flex-row w-50 justify-content-center'>OD</div>
                    <div className='d-flex flex-row w-50 justify-content-center'>OS</div>
                </div>
            </div>
            <div className='d-flex flex-column w-100 justify-content-center align-items-center'>
        {fields.map((field, index) => (
                    <React.Fragment key={index}>
                    {((fields.get(index).plan && fields.get(index).plan.tipo_plan === 3) || fields.get(index).tipo_plan === 3) &&
                        <div className="d-flex flex-row w-100 align-items-center justify-content-between" key={index}>
                            <div className="d-flex">
                                <Field
                                    name={`${field}.id`}
                                    component='input'
                                    type='hidden'
                                />
                            </div>

                            <div className="d-flex w-40">
                                <Field
                                    name={`${field}.nombre`}
                                    component='textarea'
                                    style={{
                                        width: '100%',
                                        background: 'white',
                                        border: 'none',
                                        resize: 'none',
                                    }}
                                    disabled={view}
                                />
                            </div>

                            <div className="d-flex w-60">
                                <Field
                                    name={`${field}.incluye`}
                                    component='textarea'
                                    disabled={view}
                                    style={{
                                        width: '100%',
                                        background: 'white',
                                        border: 'none',
                                        resize: 'none',
                                    }}
                                />
                            </div>

                            <div className="d-flex w-25 justify-content-center">
                                <div className='d-flex w-50 justify-content-center'>
                                    <Field
                                        name={`${field}.od`}
                                        component={renderFieldCheck}
                                        disabled={view}
                                    />
                                </div>
                                <div className='d-flex w-50 justify-content-center'>
                                    <Field
                                        name={`${field}.os`}
                                        component={renderFieldCheck}
                                        disabled={view}
                                    />
                                </div>
                            </div>
                        </div>
                        }
                        </React.Fragment>
                        )
                    )
                }
            </div>
        </div>
    </div>
    </React.Fragment>
)

const formatearFecha = (fecha) => {
    const dia = String(fecha.getDate()).padStart(2, '0');
    const mes = String(fecha.getMonth() + 1).padStart(2, '0');
    const año = fecha.getFullYear();

    return `${dia}-${mes}-${año}`;
}

const Form = (props) => {
    const { handleSubmit } = props;
    const view = window.location.href.includes("reporte");

    return (
        <form onSubmit={handleSubmit} className="d-flex flex-column">
            <div className='d-flex flex-row w-100' style={{ justifyContent: 'flex-end' }}><h3>{formatearFecha(new Date())}</h3></div>
            {!view ? <h3><strong>ORDEN DE ESTUDIOS Y PROCEDIMIENTOS</strong></h3> : null} 
            <div className="d-flex flex-column justify-content-center ">
                <div className='d-flex flex-row justify-content-center w-100 mb-5'>
                    <div className='d-flex flex-column w-100'>
                        <label className="radio-inline mr-3 d-flex align-items-center w-100">
                            Historia Clínica y Diagnóstico
                        </label>
                        <div className="d-flex flex-row w-100">
                            <Field
                                name="historial_dx"
                                component={modalTextArea}
                                className="annotations"
                                disabled={view}
                            />
                        </div>                       
                    </div>
                </div>

                <div className='d-flex flex-row justify-content-center'>
                    <div className="mb-4">
                        <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                            <label htmlFor="tipo" className="">Laboratorios</label>
                        </div>
                        <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                            <Field name="laboratorio" autofocus loadOptions={getRefillable} isDisabled={view} component={renderSearchCreateSelect} type="text" className="no-border"/>
                        </div>
                    </div>
                </div>

                <div className='d-flex flex-row w-100 justify-content-center mb-2'>
                        <label className='text-danger w-25'>Refracción Final</label>
                        <label className='ml-3 text-danger'>OD:</label>
                        <div className='d-flex flex-row w-50 ml-1'>
                            <Field
                                name='refraction_od'
                                component='input'
                                type='text'
                                style={{
                                    outline: 'none',
                                    borderRadius: 10,
                                    border: 'solid 1px #0a5995',
                                    padding: '1px 3px 1px',
                                }}
                                disabled={view}
                            />
                        </div>
                        <label className='text-danger ml-1'>OS: &nbsp;</label>
                        <div className='d-flex flex-row'>
                            <Field
                                name='refraction_os'
                                component='input'
                                type='text'
                                style={{
                                    outline: 'none',
                                    borderRadius: 10,
                                    border: 'solid 1px #0a5995',
                                    padding: '1px 3px 1px',
                                }}
                                disabled={view}
                            />
                        </div>
                </div>

                <div className='d-flex flex-row w-100 justify-content-center mb-2'>
                        <label className='text-danger w-25'>Refracción Final Esfera</label>
                        <label className='ml-3 text-danger'>OD:</label>
                        <div className='d-flex flex-row w-50 ml-1'>
                            <Field
                                name='refraction_od_esf'
                                component='input'
                                type='text'
                                style={{
                                    outline: 'none',
                                    borderRadius: 10,
                                    border: 'solid 1px #0a5995',
                                    padding: '1px 3px 1px',
                                }}
                                disabled={true}
                            />
                        </div>
                        <label className='text-danger ml-1'>OS: &nbsp;</label>
                        <div className='d-flex flex-row'>
                            <Field
                                name='refraction_os_esf'
                                component='input'
                                type='text'
                                style={{
                                    outline: 'none',
                                    borderRadius: 10,
                                    border: 'solid 1px #0a5995',
                                    padding: '1px 3px 1px',
                                }}
                                disabled={true}
                            />
                        </div>
                </div>
                <div className='d-flex flex-row w-100 justify-content-center mb-2'>
                        <label className='text-danger w-25'>Refracción Final Cilindro</label>
                        <label className='ml-3 text-danger'>OD:</label>
                        <div className='d-flex flex-row w-50 ml-1'>
                            <Field
                                name='refraction_od_cl'
                                component='input'
                                type='text'
                                style={{
                                    outline: 'none',
                                    borderRadius: 10,
                                    border: 'solid 1px #0a5995',
                                    padding: '1px 3px 1px',
                                }}
                                disabled={true}
                            />
                        </div>
                        <label className='text-danger ml-1'>OS: &nbsp;</label>
                        <div className='d-flex flex-row'>
                            <Field
                                name='refraction_os_cl'
                                component='input'
                                type='text'
                                style={{
                                    outline: 'none',
                                    borderRadius: 10,
                                    border: 'solid 1px #0a5995',
                                    padding: '1px 3px 1px',
                                }}
                                disabled={true}
                            />
                        </div>
                </div>
                <div className='d-flex flex-row w-100 justify-content-center mb-2'>
                        <label className='text-danger w-25'>Refracción Final Eje</label>
                        <label className='ml-3 text-danger'>OD:</label>
                        <div className='d-flex flex-row w-50 ml-1'>
                            <Field
                                name='refraction_od_eje'
                                component='input'
                                type='text'
                                style={{
                                    outline: 'none',
                                    borderRadius: 10,
                                    border: 'solid 1px #0a5995',
                                    padding: '1px 3px 1px',
                                }}
                                disabled={true}
                            />
                        </div>
                        <label className='text-danger ml-1'>OS: &nbsp;</label>
                        <div className='d-flex flex-row'>
                            <Field
                                name='refraction_os_eje'
                                component='input'
                                type='text'
                                style={{
                                    outline: 'none',
                                    borderRadius: 10,
                                    border: 'solid 1px #0a5995',
                                    padding: '1px 3px 1px',
                                }}
                                disabled={true}
                            />
                        </div>
                </div>
                <div>
                    <FieldArray
                        name="planes"
                        component={renderPlanRow}
                        view={view}
                    />
                </div>
            </div>
        </form>
    )
};

const CrearForm = reduxForm({
    // a unique name for the form
    form: 'EducationalPlan',
    validate: (data) => {
        return validate(data, {
        });
    },
})(Form);

export default CrearForm;
