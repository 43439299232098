import React, {Component} from 'react';
import classNames from "classnames";
import {
    VISUAL_ACUITY,
    TONOMETRY,
    REFRACTOR,
    MUSCLES,
    LENSOMETRY,
    KERATOMERY,
    INDIRECT_EYE,
    AUTOREFRACTOR,
    LAMP,
    DIAGNOSTIC_PLAN,
    EDUCATIONAL_PLAN,
    ENDED,
    RECORD,
    OPTICS_ORDER,
} from "../../../../utility/variables";
import Swal from "sweetalert2";

export default class Step extends Component {
    state = {step: null};

    nextStep = () => {
        const { nextStep } = this.props;
        const { step } = this.state;

        if (step === ENDED) {
            Swal({
                title: '¿Está seguro que desea finalizar la consulta?',
                text: 'No podrá deshacer esta acción',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Sí, continuar',
                cancelButtonText: 'No, cancelar',
                reverseButtons: true
            }).then((result) => {
                if (result.value) {
                    nextStep(step);
                }
            })
        }else{
            nextStep(step);
        }
    };

    cambiarPaso = (paso) => {
        console.log("Llamando paso: ", paso);
        this.setState({step: paso}, ()=>this.forceUpdate());

    }

    render() {
        const {cancelar} = this.props;
        console.log("--Props step", this.props)
        return (
            <React.Fragment>
                <div className="text-center">
                    <h2 className="border-bottom">Paso del paciente</h2>
                </div>
                <div className="d-flex flex-column">
                    <div className="d-flex justify-content-between">
                        <div className="radio" style={{flex: 1}}>
                            <label htmlFor="service_type" className="radio-inline mr-5 d-flex align-items-center"
                                onClick={()=> this.cambiarPaso(RECORD)}
                            >
                                <input name="step" type={"radio"} value={RECORD} checked={this.state.step === RECORD ? true : false}
                                       className={classNames('form-control')} />
                                <span className="pl-2 step_complete">Antecedentes</span>
                            </label>
                        </div>

                        <div className="radio" style={{flex: 1}}>
                            <label htmlFor="service_type" className="radio-inline mr-5 d-flex align-items-center"
                                onClick={()=> this.cambiarPaso(MUSCLES)}
                            >
                                <input name="step" type={"radio"} value={MUSCLES} checked={this.state.step === MUSCLES ? true : false}
                                       className={classNames('form-control')} />
                                <span className={classNames('pl-2', { step_complete: this.props.complete_steps.includes(MUSCLES) })}>Músculos extraoculares</span>
                            </label>
                        </div>
                    </div>
                    <div className="d-flex justify-content-between">
                        <div className="radio" style={{flex: 1}}>
                            <label htmlFor="service_type" className="radio-inline mr-5 d-flex align-items-center"
                                onClick={()=> this.cambiarPaso(LENSOMETRY)}
                            >
                                <input name="step" type={"radio"} value={LENSOMETRY} checked={this.state.step === LENSOMETRY ? true : false}
                                       className={classNames('form-control')} />
                                <span className={classNames('pl-2', { step_complete: this.props.complete_steps.includes(LENSOMETRY) })}>Lensometría</span>
                            </label>
                        </div>
                        <div className="radio" style={{flex: 1}}>
                            <label htmlFor="service_type" className="radio-inline mr-5 d-flex align-items-center"
                                onClick={()=> this.cambiarPaso(LAMP)}
                            >
                                <input name="step" type={"radio"} value={LAMP} checked={this.state.step === LAMP ? true : false}
                                       className={classNames('form-control')} />
                                <span className={classNames('pl-2', { step_complete: this.props.complete_steps.includes(LAMP) })}>Lámpara de hendidura</span>
                            </label>
                        </div>
                    </div>
                    <div className="d-flex justify-content-between">
                        <div className="radio" style={{flex: 1}}>
                            <label htmlFor="service_type" className="radio-inline mr-5 d-flex align-items-center"
                                onClick={()=> this.cambiarPaso(VISUAL_ACUITY)}
                            >
                                <input name="step" type={"radio"} value={VISUAL_ACUITY} checked={this.state.step === VISUAL_ACUITY ? true : false}
                                       className={classNames('form-control')} />
                                <span className={classNames('pl-2', { step_complete: this.props.complete_steps.includes(VISUAL_ACUITY) })}>Agudeza Visual</span>
                            </label>
                        </div>
                        {/*
                        <div className="radio" style={{flex: 1}}>
                            <label htmlFor="service_type" className="radio-inline mr-5 d-flex align-items-center"
                                onClick={()=> this.cambiarPaso(INDIRECT_EYE)}
                            >
                                <input name="step" type={"radio"} value={INDIRECT_EYE} checked={this.state.step === INDIRECT_EYE ? true : false}
                                       className={classNames('form-control')} />
                                <span className={classNames('pl-2', { step_complete: this.props.complete_steps.includes(INDIRECT_EYE) })}>Fondo de ojo indirecto</span>
                            </label>
                        </div>
                         */}
                    </div>
                    <div className="d-flex justify-content-between">
                        {/*
                        <div className="radio" style={{flex: 1}}>
                            <label htmlFor="service_type" className="radio-inline mr-5 d-flex align-items-center"
                                onClick={()=> this.cambiarPaso(AUTOREFRACTOR)}
                            >
                                <input name="step" type={"radio"} value={AUTOREFRACTOR} checked={this.state.step === AUTOREFRACTOR ? true : false}
                                       className={classNames('form-control')} />
                                <span className={classNames('pl-2', { step_complete: this.props.complete_steps.includes(AUTOREFRACTOR) })}>Autorefractometro y DiP</span>
                            </label>
                        </div>
                        */}
                        <div className="radio" style={{flex: 1}}>
                            <label htmlFor="service_type" className="radio-inline mr-5 d-flex align-items-center"
                                onClick={()=> this.cambiarPaso(DIAGNOSTIC_PLAN)}
                            >
                                <input name="step" type={"radio"} value={DIAGNOSTIC_PLAN} checked={this.state.step === DIAGNOSTIC_PLAN ? true : false}
                                       className={classNames('form-control')} />
                                <span className={classNames('pl-2', { step_complete: this.props.complete_steps.includes(DIAGNOSTIC_PLAN) })}>Plan Dx Plan Tx Plan Educ</span>
                            </label>
                        </div>
                    </div>
                    <div className="d-flex justify-content-between">
                        <div className="radio" style={{flex: 1}}>
                            <label htmlFor="service_type" className="radio-inline mr-5 d-flex align-items-center"
                                onClick={()=> this.cambiarPaso(KERATOMERY)}
                            >
                                <input
                                    name="step"
                                    type={"radio"}
                                    value={KERATOMERY}
                                    checked={this.state.step === KERATOMERY ? true : false}
                                    className={classNames('form-control')}
                                />                                    
                                    {/*<span 
                                        className={classNames('pl-2', { step_complete: this.props.complete_steps.includes(KERATOMERY) })}
                                    >Queratómetro</span>*/}
                                    <span 
                                        className={classNames('pl-2', { step_complete: this.props.complete_steps.includes(KERATOMERY) })}
                                    >Autorefrac y Querat</span>                                    
                            </label>
                        </div>
                        <div className="radio" style={{flex: 1}}>
                            <label htmlFor="service_type" className="radio-inline mr-5 d-flex align-items-center"
                                onClick={()=> this.cambiarPaso(EDUCATIONAL_PLAN)}
                            >
                                <input name="step" type={"radio"} value={EDUCATIONAL_PLAN}  checked={this.state.step === EDUCATIONAL_PLAN ? true : false}
                                       className={classNames('form-control')} />
                                <span className={classNames('pl-2', { step_complete: this.props.complete_steps.includes(EDUCATIONAL_PLAN) })}>Estudios y Procedimientos</span>
                            </label>
                        </div>
                    </div>

                    <div className="d-flex justify-content-between">
                        <div className="radio" style={{flex: 1}}>
                            <label htmlFor="service_type" className="radio-inline mr-5 d-flex align-items-center"
                                onClick={()=> this.cambiarPaso(REFRACTOR)}
                            >
                                <input name="step" type={"radio"} value={REFRACTOR} checked={this.state.step === REFRACTOR ? true : false}
                                       className={classNames('form-control')} />
                                <span className={classNames('pl-2', { step_complete: this.props.complete_steps.includes(REFRACTOR) })}>Refracción</span>
                            </label>
                        </div>
                        <div className="radio" style={{flex: 1}}>
                            <label htmlFor="service_type" className="radio-inline mr-5 d-flex align-items-center"
                                onClick={()=> this.cambiarPaso(OPTICS_ORDER)}
                            >
                                <input name="step" type={"radio"} value={OPTICS_ORDER} checked={this.state.step === OPTICS_ORDER ? true : false}
                                       className={classNames('form-control')} />
                                <span className={classNames('pl-2', { step_complete: this.props.complete_steps.includes(OPTICS_ORDER) })}>Orden de óptica</span>
                            </label>
                        </div>
                    </div>
                    <div className="d-flex justify-content-between">
                        <div className="radio" style={{flex: 1}}>
                            <label htmlFor="service_type" className="radio-inline mr-5 d-flex align-items-center"
                                onClick={()=> this.cambiarPaso(TONOMETRY)}
                            >
                                <input name="step" type={"radio"} value={TONOMETRY} checked={this.state.step === TONOMETRY ? true : false}
                                       className={classNames('form-control')} />
                                <span className={classNames('pl-2', { step_complete: this.props.complete_steps.includes(TONOMETRY) })}>Tonometría</span>
                            </label>
                        </div>
                        <div className="radio" style={{flex: 1}}>
                            <label htmlFor="service_type" className="radio-inline mr-5 d-flex align-items-center"
                                onClick={()=> this.cambiarPaso(ENDED)}
                            >
                                <input name="step" type={"radio"} value={ENDED} checked={this.state.step === ENDED ? true : false}
                                       className={classNames('form-control')} />
                                <span style={{paddingLeft:"5px"}} className='text-danger'>Finalizar consulta</span>
                            </label>
                        </div>
                    </div>
                </div>
                <div className="d-flex justify-content-center align-items-center mt-4">
                    <div
                        className="btn btn-secondary mx-4"
                        onClick={cancelar}>
                        Cancelar
                    </div>
                    <div
                        className="btn btn-primary mx-4"
                        onClick={this.nextStep}>
                        Aceptar
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
