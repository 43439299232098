import React, {Component} from 'react';
import './medical_history';
import SurgeryHistory from './SurgeryHistory';
import ConsultHistory from "./ConsultHistory";
import DiagnosticHistory from './DiagnosticHistory';

/**
 * TAB:
 *  consulta
 *  cirugia
 *  diagnostico
 */

export default class MedicalHistory extends Component{
    componentWillUnmount() {
        this.props.resetStore();
    }
    state = {
        tab: 'consulta',
    }

    componentWillMount = () => {
        const {getPatientInfo, match} = this.props;
        getPatientInfo(match.params.id)
    }

    changeTab = (tab) => {
        this.setState({tab});
    }

    render(){
        const {
            patient,
            open_modal_scan,
            openModalScan,
            saveScan,
            openModalUpdateScan,
            open_modal_update_scan,
            cargando_archivo,
            cargando,
            deleteScanAppointment,
        } = this.props;

        const {tab} = this.state;

        const consult_active = tab === 'consulta' ? 'medical-history-tab-active' : '';
        const surgery_active = tab === 'cirugia' ? 'medical-history-tab-active' : '';
        const diagnostic_active = tab === 'diagnostico' ? 'medical-history-tab-active' : '';

        console.log("PROPS IN MEDICAL HISTORY: ", this.props);
        return(
            <div className="content">
                <div className='d-flex flex-row mt-3'>
                    <a href={'/#/patients'}
                        className='d-flex flex-row'
                    >
                        <img
                            style={{width: 25, height:25}}
                            src={require('../../../../assets/img/icons/return-icon-blue.png')}
                        /> &nbsp;
                        <h5 className='text-primary font-weight-bold'>{patient && patient.first_name} &nbsp;
                            {patient && patient.last_name}
                        </h5>
                    </a>
                </div>

                <div className='d-flex flex-column junstify-content-center align-items-center'>
                    <div
                        className='d-flex flex-row p-1 menu-histori'
                    >
                        <div
                            onClick={()=>this.changeTab('consulta')}
                            className={'medical-history-tab d-flex flex-row justify-content-center align-items-center ' + consult_active}
                        >
                                CONSULTA / RECONSULTA
                        </div>
                        <div
                            onClick={()=>this.changeTab('cirugia')}
                            className={'medical-history-tab d-flex flex-row justify-content-center align-items-center ' + surgery_active}
                        >
                                CIRUGÍAS
                        </div>
                        <div
                            onClick={()=>this.changeTab('diagnostico')}
                            className={'medical-history-tab d-flex flex-row justify-content-center align-items-center ' + diagnostic_active}
                        >
                            DIAGNÓSTICO / INTERPRETACIÓN
                        </div>
                    </div>
                </div>

                {tab === 'consulta' &&
                    <ConsultHistory
                        openModalScan={openModalScan}
                        open_modal_scan={open_modal_scan}
                        saveScan={saveScan}
                        openModalUpdateScan={openModalUpdateScan}
                        open_modal_update_scan={open_modal_update_scan}
                        cargando={cargando}
                        patient_id={this.props.match.params.id}
                        getConsultHistory={this.props.getConsultHistory}
                        consult_history={this.props.consult_history}
                        cargando_archivo={cargando_archivo}
                        deleteScanAppointment={deleteScanAppointment}
                        me={this.props.me}
                    />
                }
                {tab === 'cirugia' &&
                    <SurgeryHistory
                        getSurgeryHistory={this.props.getSurgeryHistory}
                        patient_id={this.props.match.params.id}
                        surgery_history={this.props.surgery_history}
                        me={this.props.me}
                    />
                }
                {tab === 'diagnostico' &&
                    <DiagnosticHistory
                        getDiagnosticHistory={this.props.getDiagnosticHistory}
                        patient_id={this.props.match.params.id}
                        diagnostic_history={this.props.diagnostic_history}
                        cargando_archivo={cargando_archivo}
                        me={this.props.me}
                    />
                }
            </div>
        );
    }
}
