import React from 'react';
import { Link } from 'react-router-dom';
import { Field, FieldArray, reduxForm } from 'redux-form';
import { validate, validators } from 'validate-redux-form';
import {renderSearchCreateSelect, renderField, renderTextArea, modalTextArea, renderFieldCheck} from "../../Utils/renderField/renderField";
import {api} from "../../../../api/api";
import { Tratamiento } from "../../Utils/Notations/tratamiento";
import {PL_DI} from "../../../../utility/variables";
import { PrintContainer, actions as printActions } from "../../Utils/Print";
import { NewTreatment } from '../../Utils/Notations/NewTreatment';

const getRefillable = (search) => {
    let opciones = [];
    return api.get('refillable/getRefillable', {search, record_type: PL_DI}).then((data) => {
        console.log("Data: ", data);
        data.results.forEach(item => {
            opciones.push({
                value: item.id,
                label: item.texto
            });
        });
        return opciones;
    }).catch(()=>[]);
}

export const diagnosticRenderRow =({ fields, meta: {error, submitFailed}, edit, view}) => (
    <React.Fragment>
        {
            fields.map((field, index) => (
                <tr key={index}>
                    <td>
                        <Field
                            name={`${field}.nombre`}
                            loadOptions={getRefillable}
                            component={renderSearchCreateSelect}
                            type="text"
                            className="no-border"
                            isDisabled={view}
                        />
                    </td>
                    <td>
                        <Field
                            name={`${field}.td_dp`}
                            component={Tratamiento} 
                            view={view}
                        />
                    </td>
                    <td className='text-center'>
                        {!view &&
                        <img className="action-img delete" src={require("../../../../../assets/img/icons/delete-icon.png")} onClick={() => fields.remove(index)}  alt="Eliminar" />
                        }
                    </td>
                </tr>
                )
            )

        }

        <tr>
            <td colSpan={2}>
                {submitFailed && (
                    <div className="invalid-feedback d-flex justify-content-center">
                        {error}
                    </div>
                )}
            </td>
            <td className='text-center'>
                {!view &&
                <img onClick={() => fields.push()} className="action-img watch" src={require("../../../../../assets/img/icons/add-icon.png")} alt="Agregar" />
                }
            </td>
        </tr>
    </React.Fragment>
)

export const DxRenderRow =({ fields, meta: {error, submitFailed}, edit, view}) => (
    <React.Fragment>
        {
            fields.map((field, index) => (
                <div key={index} style={{ display: "flex", "justifyContent": "flex-start", gap: "12px", alignItems: "center", marginBottom: "8px"}}>
                        <Field
                            name={`${field}.nombre`}
                            loadOptions={getRefillable}
                            component={renderSearchCreateSelect}
                            type="text"
                            className="no-border"
                            isDisabled={view}
                        />
                    
                        {!view &&
                        <img className="action-img delete" src={require("../../../../../assets/img/icons/delete-icon.png")} onClick={() => fields.remove(index)}  alt="Eliminar" />
                        }
                </div>    
                )
            )

        }
    
        <div style={{ display: "flex", flexDirection: "column"}}>
            {submitFailed && (
                <div className="invalid-feedback d-flex justify-content-center">
                    {error}
                </div>
            )}            
            <div className='text-center' style={{ display: "flex", justifyContent: "flex-end", width: "320px"}}>
                {!view &&
                    <React.Fragment>
                    {fields.length == 0 &&
                        <button
                        className='btn btn-sm btn-success'
                        onClick={() => fields.push()}
                        >
                            Agregar DX
                        </button>                                            
                    }
                    {
                        fields.length > 0 &&
                        <img onClick={() => fields.push()} className="action-img watch" src={require("../../../../../assets/img/icons/add-icon.png")} alt="Agregar" />
                    }
                    </React.Fragment>
                }
            </div>        
        </div>
    </React.Fragment>
)

export const TxRenderRow =({ fields, meta: {error, submitFailed}, edit, view}) => (
    <React.Fragment>
        {
            fields.map((field, index) => (
                <tr key={index}>                    
                    <td>
                        <Field
                            name={`${field}.td_dp`}
                            component={Tratamiento}
                            view={view}
                        />
                    </td>
                    <td className='text-center'>
                        {!view &&
                        <img className="action-img delete" src={require("../../../../../assets/img/icons/delete-icon.png")} onClick={() => fields.remove(index)}  alt="Eliminar" />
                        }
                    </td>
                </tr>
                )
            )

        }

        <tr>
            <td colSpan={2}>
                {submitFailed && (
                    <div className="invalid-feedback d-flex justify-content-center">
                        {error}
                    </div>
                )}
            </td>
            <td className='text-center'>
                {!view &&
                <img onClick={() => fields.push()} className="action-img watch" src={require("../../../../../assets/img/icons/add-icon.png")} alt="Agregar" />
                }
            </td>
        </tr>
    </React.Fragment>
)

export const TxRenderRowNew =({ fields, meta: {error, submitFailed}, edit, view}) => (
    <React.Fragment>
        {
            fields.map((field, index) => (
                <div key={index}>                    
                    
                        <Field
                            name={`${field}.td_dp`}
                            component={NewTreatment}
                            view={view}
                        />
                    
                    {/*                    
                    <td className='text-center'>
                        {!view &&
                        <img className="action-img delete" src={require("../../../../../assets/img/icons/delete-icon.png")} onClick={() => fields.remove(index)}  alt="Eliminar" />
                        }
                    </td>
                    */}
                </div>
                )
            )

        }
        
        <div style={{ display: "flex", justifyContent: "flex-end"}}>
                {!view &&
                <button
                    className='btn btn-sm btn-success'
                    onClick={() => fields.push()}
                >
                    Agregar TX
                </button>                
                }            
        </div>
    </React.Fragment>
)

const Form = (props) => {
    const { handleSubmit, appointment_id, patient } = props;
    const view = window.location.href.includes("reporte");

    return (
        <form onSubmit={handleSubmit} className="d-flex flex-column">
            {!view ?
                <div className='d-flex flex-row justify-content-between'>
                    {/*<h3><strong>PLAN Dx PLAN Tx PLAN Educ here</strong></h3>*/}
                    <h3><strong>PLAN / TRATAMIENTO (TX)</strong></h3>

                    {/*<button
                        className='btn btn-primary btn-sm'
                        onClick={(e) => {
                            e.preventDefault();
                            printActions.print('impresion-receta');
                        }}
                        style={{height: 30}}
                    >Imprimir receta</button>*/}

                    <a 
                        className='btn btn-primary btn-sm'                      
                        href={`#/consult-print/recipe/${appointment_id}/${patient}`}
                        target='blank'
                        style={{textDecoration:'None', color: 'white', height: 'min-content'}}
                    >   
                        IMPRIMIR RECETA
                    </a>
                </div>
            :
                null
            }
            <div className="d-flex flex-column justify-content-center w-100">                
                <h3>DX</h3>
                <FieldArray
                    name='dx'
                    component={DxRenderRow}
                    view={view}
                />                        
                <br /><h3 style={{ marginBottom: "0px"}}>TX</h3>         
                <FieldArray
                    name='txn'
                    component={TxRenderRowNew}
                    view={view}
                />
                <div className="d-flex flex-row w-100 mb-3">
                    <div className="d-flex flex-column w-75">
                    <label>Conducta</label> 
                    <Field
                        name="notes"
                        component={modalTextArea}
                        className="annotations"
                        disabled={view}
                    />
                    </div>
                    <div className='d-flex flex-row w-25 ml-3 align-items-end'>
                        <button
                            type="button"
                            className='btn btn-danger h-75'
                            onClick={()=>{props.change('notes', 'DIFERIDO');}}
                        >DIFERIDO</button>
                    </div>
                </div>
            </div>
            <PrintContainer name='impresion-receta' className="impresion">
                <div className='contenedor-receta'>                
                    {props.receta && props.receta.dx && props.receta.dx.length > 0 &&
                        <React.Fragment>
                            <p style={{ fontWeight: 700, marginBottom: '5px'}}>Diagnóstico</p>
                            <ul>
                                {props.receta.dx.map((diagnostico, key) => (
                                    <li key={key}>
                                        <span>{diagnostico.nombre.label}</span>
                                    </li>
                                ))}
                            </ul>
                            <br />
                        </React.Fragment>
                    }

                    {props.receta && props.receta.txn && props.receta.txn.length == 1 &&
                    <React.Fragment>
                            <p style={{ fontWeight: 700, marginBottom: '5px'}}>RECETA</p>
                            <React.Fragment>
                                {props.receta.txn[0].td_dp.map((treatment, key) => (
                                    <div key={key} className='mt-1'>
                                        {/*                                                 
                                            <strong>{treatment.commercial_name}</strong>
                                            <div>                                            
                                                <span className='ml-2'>#{treatment.quantity}</span>
                                                <span className='ml-2'>{treatment.presentation}</span>
                                            </div>

                                            <div>
                                                <span>({treatment.active_principle})</span>
                                                <small className='ml-2'><strong>principio activo</strong></small>
                                            </div>
                                            <div>
                                                <span>{treatment.dose}</span>
                                            </div>
                                            <div>
                                                <span>{treatment.frecuency}</span>
                                            </div>
                                            <div>
                                                <span>{treatment.application}</span>
                                                <span className='ml-2'>{treatment.frecuency}</span>
                                            </div>                                        
                                        
                                        */}

                                        <p style={{ marginBottom: 0, textDecoration: "underline" }}>Medicamento</p>
                                        <p style={{ marginTop: 0, marginBottom: 0 }}><strong>{treatment.commercial_name}</strong> {treatment.quantity} {treatment.presentation} (<span>Principio activo: <strong>{treatment.active_principle}</strong></span>)</p>
                                        
                                        <p style={{ marginTop: 0 }}>Administrar {treatment.dose} cada {treatment.frecuency} por {treatment.time_duration}</p>
                                    </div>
                                ))}                                                   
                            </React.Fragment>                            
                        </React.Fragment>
                    }
                    {/*props.receta && props.receta.dp_dpa.map((diagnostico, dk) => (
                        <React.Fragment>
                        <div key={dk} className='mt-3'>
                            <strong>{diagnostico.nombre.value}</strong>
                        </div>

                        {diagnostico.td_dp.map((tratamiento, tk) => (
                            <div key={tk} className='ml-4 mt-1'>
                                <div>
                                    <span>{tratamiento.commercial_name}</span>
                                    <span className='ml-2'>#{tratamiento.quantity}</span>
                                    <span className='ml-2'>{tratamiento.presentation}</span>
                                </div>

                                <div>
                                    <span>({tratamiento.active_principle})</span>
                                    <small className='ml-2'><strong>principio activo</strong></small>
                                </div>
                                <div>
                                    <span>{tratamiento.dose}</span>
                                </div>
                                <div>
                                    <span>{tratamiento.frecuency}</span>
                                </div>
                                <div>
                                    <span>{tratamiento.application}</span>
                                    <span className='ml-2'>{tratamiento.frecuency}</span>
                                </div>
                            </div>
                        ))
                        }
                        </React.Fragment>
                    ))*/}
                    <div className='mt-2'>{props.receta ? props.receta.notes : null}</div>
                </div>
            </PrintContainer>
        </form>
    )
};

const CrearForm = reduxForm({
    form: 'DiagnosticPlan',
    initialValues: {
        dx: [
            {value: '', label: ''}
        ],
        txn: [
            {
                td_dp: [
                    {                         
                        active_principle: '',
                        application: '',                
                        commercial_name: '',
                        dose: '',
                        frecuency: '',
                        presentation: '',
                        quantity: '',
                        time_duration: ''
                    },
                    { 
                        active_principle: '',
                        application: '',                
                        commercial_name: '',
                        dose: '',
                        frecuency: '',
                        presentation: '',
                        quantity: '',
                        time_duration: ''
                    },
                    { 
                        active_principle: '',
                        application: '',                
                        commercial_name: '',
                        dose: '',
                        frecuency: '',
                        presentation: '',
                        quantity: '',
                        time_duration: ''
                    }                    
                ]
            }
                        
        ]        
    },
    validate: (data) => {
        return validate(data, {
        });
    },
})(Form);

export default CrearForm;
