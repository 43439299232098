import React from 'react';
import { Link } from 'react-router-dom';
import {Field, formValueSelector, reduxForm} from 'redux-form';
import { validate, validators } from 'validate-redux-form';
import {renderSearchCreateSelect, renderField, renderSelectCustom} from "../../Utils/renderField";
import {api} from "../../../../api/api";
import {modalTextArea, renderDatePicker} from "../../Utils/renderField/renderField";
import { PrintContainer, actions as printActions } from "../../Utils/Print";
import newDatePicker from "../../Utils/NewDatepicker/";
import {connect} from "react-redux";
import AppointmentsForm from "../../Appointments/AppointmentsTodayForm";
import { Esf } from "../../Utils/Notations/lensometry/esf";
import { Cil } from "../../Utils/Notations/lensometry/cil";
import { Ejz } from "../../Utils/Notations/lensometry/eje";

let Form = (props) => {
    const {handleSubmit, orden_optica, dip, saveOpticsOrder} = props;
    const view = window.location.href.includes("reporte");
    console.log("PROPS DE ORDEN DE ÓPTICA ", props);

    const lens_type_exist = (orden_optica && orden_optica.monofocal && orden_optica.monofocal.replaceAll(" ", "") != "") ||
                            (orden_optica && orden_optica.progresive && orden_optica.progresive.replaceAll(" ", "") != "") ||
                            (orden_optica && orden_optica.bifocal && orden_optica.bifocal.replaceAll(" ", "") != "") ||
                            (orden_optica && orden_optica.type_other && orden_optica.type_other.replaceAll(" ", "") != "")
    
    const recomendations_exist = (orden_optica && orden_optica.blue_filter && orden_optica.blue_filter.replaceAll(" ", "") != "") ||
                                (orden_optica && orden_optica.anti_glare && orden_optica.anti_glare.replaceAll(" ", "") != "") ||
                                (orden_optica && orden_optica.polarized && orden_optica.polarized.replaceAll(" ", "") != "") ||
                                (orden_optica && orden_optica.polycarbonate && orden_optica.polycarbonate.replaceAll(" ", "") != "") ||
                                (orden_optica && orden_optica.sunglasses && orden_optica.sunglasses.replaceAll(" ", "") != "") ||
                                (orden_optica && orden_optica.had && orden_optica.had.replaceAll(" ", "") != "") ||
                                (orden_optica && orden_optica.transitions && orden_optica.transitions.replaceAll(" ", "") != "") ||
                                (orden_optica && orden_optica.recomendation_other && orden_optica.recomendation_other.replaceAll(" ", "") != "")
    
    const final_contact = orden_optica && ((orden_optica.od_pwr && orden_optica.od_pwr.replaceAll(" ", "") != "") || 
                        (orden_optica.os_pwr && orden_optica.os_pwr.replaceAll(" ", "") != "") || 
                        (orden_optica.od_cyl && orden_optica.od_cyl.replaceAll(" ", "") != "") || 
                        (orden_optica.os_cyl && orden_optica.os_cyl.replaceAll(" ", "") != "") ||
                        (orden_optica.od_eje && orden_optica.od_eje.replaceAll(" ", "") != "") || 
                        (orden_optica.os_eje && orden_optica.os_eje.replaceAll(" ", "") != "") || 
                        (orden_optica.od_bc && orden_optica.od_bc.replaceAll(" ", "") != "") || 
                        (orden_optica.os_bc && orden_optica.os_bc.replaceAll(" ", "") != "") || 
                        (orden_optica.od_add && orden_optica.od_add.replaceAll(" ", "") != "") || 
                        (orden_optica.os_add && orden_optica.os_add.replaceAll(" ", "") != "") || 
                        (orden_optica.od_marca && orden_optica.od_marca.replaceAll(" ", "") != "") || 
                        (orden_optica.os_marca && orden_optica.os_marca.replaceAll(" ", "") != "") || 
                        (orden_optica.od_otro && orden_optica.od_otro.replaceAll(" ", "") != "") || 
                        (orden_optica.os_otro && orden_optica.os_otro.replaceAll(" ", "") != "") ||
                        (orden_optica.od_dia && orden_optica.od_dia.replaceAll(" ", "") != "") ||
                        (orden_optica.os_dia && orden_optica.os_dia.replaceAll(" ", "") != ""))

    const { od_esf_blan, 
            os_esf_blan,
            od_cil_blan,
            os_cil_blan,        
        } = props;
                
    return (
        <React.Fragment>
        <form onSubmit={handleSubmit} className="d-flex flex-column">
            <div className='mt-3 mb-3' style={{borderBottom:'solid 1px black'}}>
                {!view ? <h4><strong>ORDEN DE ÓPTICA</strong></h4> : null}
            </div>
            <div className='d-flex flex-row justify-content-end'>
                {!view ?
                    <button
                        className='btn btn-primary btn-sm'
                        onClick={async (e) => {
                            e.preventDefault();
                            saveOpticsOrder()
                            setTimeout(() => {
                                printActions.print('impresion-orden-optica')
                            }, 1500);
                        }}
                    >Imprimir</button>
                : null}
            </div>
            <div className='d-flex flex-row justify-content-center mt-5'>
                <div className="d-flex flex-column justify-content-center px-0 px-sm-2 px-md-3 px-lg-3 w-100">
                    <div className='d-flex flex-column align-items-center'>
                        <div className='d-flex flex-row justify-content-center align-items-center'>
                            <label className='mr-2'>NOMBRE: </label>
                            <Field name="name" disabled={view} autofocus component={renderField} className='form-control'/>
                            <label className='ml-3 mr-2'>FECHA: </label>
                            {/*<Field name="date" disabled={view} component={renderDatePicker} />*/}
                            <Field name="date" isDisabled={view} component={newDatePicker} />
                        </div>
                        {/*Se oculto el campo Final recomendada*/}
                        <div className='d-flex flex-row justify-content-center align-items-center mt-3 w-75 invisible'>
                            <label className='mr-2'>Final recomendada</label>
                            <Field name="recommended_end" disabled={view} component={renderField} className='form-control'/>
                        </div>

                        <div className='d-flex flex-row justify-content-center align-items-top mt-3 w-75'>
                            <label className='mr-2'>Comentario</label>
                            <Field name="comment" disabled={view} component={modalTextArea} className='annotations'/>
                        </div>

                        <table className='table table-sm table-bordered optics-order-table-head mt-3 mb-4'>
                            <thead>
                                <tr>
                                    <th colSpan={2}></th>
                                    <th className='text-center'>ESFERA</th>
                                    <th className='text-center'>CILINDRO</th>
                                    <th className='text-center'>EJE</th>
                                    <th className='text-center'>PRISMA</th>
                                    <th className='text-center'>BASE</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                   <td rowSpan={2}>FINAL</td>
                                   <td>OD</td>
                                   <td><div style={{width:85}}><Field name='od_final_esfera' disabled={true} component={renderField} className='form-control' addClass='w-100'/></div></td>
                                   <td><div style={{width:85}}><Field name='od_final_cilindro' disabled={true} component={renderField} className='form-control' addClass='w-100'/></div></td>
                                   <td><div style={{width:85}}><Field name='od_final_eje' disabled={true} component={renderField} className='form-control' addClass='w-100'/></div></td>
                                   <td><div style={{width:85}}><Field name='od_final_prisma' disabled={view} component={renderField} className='form-control' addClass='w-100'/></div></td>
                                   <td><div style={{width:85}}><Field name='od_final_base' disabled={view} component={renderField} className='form-control' addClass='w-100'/></div></td>
                                </tr>
                                <tr>
                                   <td>OS</td>
                                   <td><div style={{width:85}}><Field name='os_final_esfera' disabled={true} component={renderField} className='form-control' addClass='w-100'/></div></td>
                                   <td><div style={{width:85}}><Field name='os_final_cilindro' disabled={true} component={renderField} className='form-control' addClass='w-100'/></div></td>
                                   <td><div style={{width:85}}><Field name='os_final_eje' disabled={true} component={renderField} className='form-control' addClass='w-100'/></div></td>
                                   <td><div style={{width:85}}><Field name='os_final_prisma' disabled={view} component={renderField} className='form-control' addClass='w-100'/></div></td>
                                   <td><div style={{width:85}}><Field name='os_final_base' disabled={view} component={renderField} className='form-control' addClass='w-100'/></div></td>
                                </tr>
                                <tr>
                                   <td rowSpan={2}>ADICIÓN</td>
                                   <td>OD</td>
                                   <td><div style={{width:85}}><Field name='od_adicion_esfera' disabled={true} component={renderField} className='form-control' addClass='w-100'/></div></td>
                                   <td><div style={{width:85}}><Field name='od_adicion_cilindro' disabled={view} component={renderField} className='form-control' addClass='w-100'/></div></td>
                                   <td><div style={{width:85}}><Field name='od_adicion_eje' disabled={view} component={renderField} className='form-control' addClass='w-100'/></div></td>
                                   <td><div style={{width:85}}><Field name='od_adicion_prisma' disabled={view} component={renderField} className='form-control' addClass='w-100'/></div></td>
                                   <td><div style={{width:85}}><Field name='od_adicion_base' disabled={view} component={renderField} className='form-control' addClass='w-100'/></div></td>
                                </tr>
                                <tr>
                                   <td>OS</td>
                                   <td><div style={{width:85}}><Field name='os_adicion_esfera' disabled={true} component={renderField} className='form-control' addClass='w-100'/></div></td>
                                   <td><div style={{width:85}}><Field name='os_adicion_cilindro' disabled={view} component={renderField} className='form-control' addClass='w-100'/></div></td>
                                   <td><div style={{width:85}}><Field name='os_adicion_eje' disabled={view} component={renderField} className='form-control' addClass='w-100'/></div></td>
                                   <td><div style={{width:85}}><Field name='os_adicion_prisma' disabled={view} component={renderField} className='form-control' addClass='w-100'/></div></td>
                                   <td><div style={{width:85}}><Field name='os_adicion_base' disabled={view} component={renderField} className='form-control' addClass='w-100'/></div></td>
                                </tr>
                            </tbody>
                        </table>


                        <div className='d-flex flex-column w-100'>
                            <label>Autorefractometro</label>
                            <ul>
                                <li className='align-items-center'>
                                    <div className='d-flex flex-row w-100'>
                                        <div className='w-25 mr-2'>DIP</div>
                                        <div className='w-50 d-flex flex-row justify-content-start'>
                                            <label style={{width:150}}>
                                                <Field name='dip' component={renderField} disabled={true} className='form-control' addClass='w-100'/>
                                            </label>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>

                        <div className='d-flex flex-row w-100 mt-3'>
                            <div className='d-flex flex-column w-50'>
                                <label>TIPO DE LENTE</label>
                                <ul>
                                    <li className='align-items-center'>
                                        <div className='d-flex flex-row w-100'>
                                            <div className='w-25 mr-2'>MONOFOCAL</div>
                                            <div className='w-50 d-flex flex-row justify-content-start'>
                                                <label style={{width:150}}>
                                                    <Field name='monofocal' component={renderField} disabled={view} className='form-control' addClass='w-100'/>
                                                </label>
                                            </div>
                                        </div>
                                    </li>
                                    <li className='align-items-center'>
                                        <div className='d-flex flex-row w-100'>
                                            <div className='w-25 mr-2'>PROGRESIVO</div>
                                            <div className='w-50'>
                                                <label style={{width:150}}>
                                                    <Field name='progresive' component={renderField} disabled={view} className='form-control' addClass='w-100'/>
                                                </label>
                                            </div>
                                        </div>
                                    </li>
                                    <li className='align-items-center'>
                                        <div className='d-flex flex-row w-100'>
                                            <div className='w-25 mr-2'>BIFOCAL</div>
                                            <div className='w-50'>
                                                <label style={{width:150}}>
                                                    <Field name='bifocal' component={renderField} disabled={view} className='form-control' addClass='w-100'/>
                                                </label>
                                            </div>
                                        </div>
                                    </li>
                                    <li className='align-items-center'>
                                        <div className='d-flex flex-row w-100'>
                                            <div className='w-25 mr-2'>OTRO</div>
                                            <div className='w-50'>
                                                <label style={{width:150}}>
                                                    <Field name='type_other' component={renderField} disabled={view} className='form-control' addClass='w-100'/>
                                                </label>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div className='d-flex flex-column w-50'>
                                <label>RECOMENDACIÓN</label>
                                <ul>
                                    <li className='align-items-center'>
                                        <div className='d-flex flex-row w-100'>
                                            <div className='w-50 mr-2'>FILTRO DE LUZ AZUL</div>
                                            <div className='w-50 d-flex flex-row justify-content-start'>
                                                <label style={{width:150}}>
                                                    <Field name='blue_filter' component={renderField} disabled={view} className='form-control' addClass='w-100' />
                                                </label>
                                            </div>
                                        </div>
                                    </li>
                                    <li className='align-items-center'>
                                        <div className='d-flex flex-row w-100'>
                                            <div className='w-50 mr-2'>ANTIREFLEJO</div>
                                            <div className='w-50 d-flex flex-row justify-content-start'>
                                                <label style={{width:150}}>
                                                    <Field name='anti_glare' component={renderField} disabled={view} className='form-control' addClass='w-100' />
                                                </label>
                                            </div>
                                        </div>
                                    </li>
                                    <li className='align-items-center'>
                                        <div className='d-flex flex-row w-100'>
                                            <div className='w-50 mr-2'>POLARIZADO</div>
                                            <div className='w-50 d-flex flex-row justify-content-start'>
                                                <label style={{width:150}}>
                                                    <Field name='polarized' component={renderField} disabled={view} className='form-control' addClass='w-100' />
                                                </label>
                                            </div>
                                        </div>
                                    </li>
                                    <li className='align-items-center'>
                                        <div className='d-flex flex-row w-100'>
                                            <div className='w-50 mr-2'>POLICARBONATO</div>
                                            <div className='w-50 d-flex flex-row justify-content-start'>
                                                <label style={{width:150}}>
                                                    <Field name='polycarbonate' component={renderField} disabled={view} className='form-control' addClass='w-100' />
                                                </label>
                                            </div>
                                        </div>
                                    </li>
                                    <li className='align-items-center'>
                                        <div className='d-flex flex-row w-100'>
                                            <div className='w-50 mr-2'>LENTES DE SOL</div>
                                            <div className='w-50 d-flex flex-row justify-content-start'>
                                                <label style={{width:150}}>
                                                    <Field name='sunglasses' component={renderField} disabled={view} className='form-control' addClass='w-100' />
                                                </label>
                                            </div>
                                        </div>
                                    </li>
                                    <li className='align-items-center'>
                                        <div className='d-flex flex-row w-100'>
                                            <div className='w-50 mr-2'>TEÑIDO</div>
                                            <div className='w-50 d-flex flex-row justify-content-start'>
                                                <label style={{width:150}}>
                                                    <Field name='had' component={renderField} disabled={view} className='form-control' addClass='w-100' />
                                                </label>
                                            </div>
                                        </div>
                                    </li>
                                    <li className='align-items-center'>
                                        <div className='d-flex flex-row w-100'>
                                            <div className='w-50 mr-2'>TRANSITIONS</div>
                                            <div className='w-50 d-flex flex-row justify-content-start'>
                                                <label style={{width:150}}>
                                                    <Field name='transitions' component={renderField} disabled={view} className='form-control' addClass='w-100' />
                                                </label>
                                            </div>
                                        </div>
                                    </li>
                                    <li className='align-items-center'>
                                        <div className='d-flex flex-row w-100'>
                                            <div className='w-50 mr-2'>OTROS</div>
                                            <div className='w-50 d-flex flex-row justify-content-start'>
                                                <label style={{width:150}}>
                                                    <Field name='recomendation_other' component={renderField} disabled={view} className='form-control' addClass='w-100' />
                                                </label>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className='d-flex flex-row mt-2'>FINAL EN LENTE DE CONTACTO ESCLERAL O RÍGIDO</div>
                        <table className='w-50 m-auto'>
                        <tbody>
                            <tr>
                                <td>
                                    <label className='radio-inline d-flex align-items-center'>
                                        ESCLERAL&nbsp;
                                        <Field
                                            name="contact_lens_type"
                                            component={renderField}
                                            type="radio"
                                            value={"1"}
                                            //onChange={()=>{props.change('od_mmhg', '')}}
                                            disabled={view}
                                        />
                                    </label>
                                </td>
                                <td>
                                    <label className='radio-inline d-flex align-items-center'>
                                        RÍGIDO&nbsp;
                                        <Field
                                            name="contact_lens_type"
                                            component={renderField}
                                            type="radio"
                                            value={"2"}
                                            //onChange={()=>{props.change('od_mmhg', '')}}
                                            disabled={view}
                                        />
                                    </label>
                                </td>
                            </tr>
                        </tbody>
                    </table>                        
                        <div className='d-flex flex-row'>
                            <table className='table table-bordered w-50 optics-order-table-head mt-1'>
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th className='text-center'>OD</th>
                                        <th className='text-center'>OS</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>PWR.</td>
                                        <td><div style={{width:150}}><Field name='od_pwr' addClass='w-100' disabled={view} component={renderField} className='form-control'/></div></td>
                                        <td><div style={{width:150}}><Field name='os_pwr' addClass='w-100' disabled={view} component={renderField} className='form-control'/></div></td>
                                    </tr>
                                    <tr>
                                        <td>CYL</td>
                                        <td><div style={{width:150}}><Field name='od_cyl' addClass='w-100' disabled={view} component={renderField} className='form-control'/></div></td>
                                        <td><div style={{width:150}}><Field name='os_cyl' addClass='w-100' disabled={view} component={renderField} className='form-control'/></div></td>
                                    </tr>
                                    <tr>
                                        <td>EJE</td>
                                        <td><div style={{width:150}}><Field name='od_eje' addClass='w-100' disabled={view} component={renderField} className='form-control'/></div></td>
                                        <td><div style={{width:150}}><Field name='os_eje' addClass='w-100' disabled={view} component={renderField} className='form-control'/></div></td>
                                    </tr>
                                    <tr>
                                        <td>B.C.</td>
                                        <td><div style={{width:150}}><Field name='od_bc' addClass='w-100' disabled={view} component={renderField} className='form-control'/></div></td>
                                        <td><div style={{width:150}}><Field name='os_bc' addClass='w-100' disabled={view} component={renderField} className='form-control'/></div></td>
                                    </tr>
                                    <tr>
                                        <td>DIA</td>
                                        <td><div style={{width:150}}><Field name='od_dia' addClass='w-100' disabled={view} component={renderField} className='form-control'/></div></td>
                                        <td><div style={{width:150}}><Field name='os_dia' addClass='w-100' disabled={view} component={renderField} className='form-control'/></div></td>
                                    </tr>
                                    <tr>
                                        <td>ADD</td>
                                        <td><div style={{width:150}}><Field name='od_add' addClass='w-100' disabled={view} component={renderField} className='form-control'/></div></td>
                                        <td><div style={{width:150}}><Field name='os_add' addClass='w-100' disabled={view} component={renderField} className='form-control'/></div></td>
                                    </tr>
                                    <tr>
                                        <td>MARCA</td>
                                        <td><div style={{width:150}}><Field name='od_marca' addClass='w-100' disabled={view} component={renderField} className='form-control'/></div></td>
                                        <td><div style={{width:150}}><Field name='os_marca' addClass='w-100' disabled={view} component={renderField} className='form-control'/></div></td>
                                    </tr>
                                    <tr>
                                        <td>OTRO</td>
                                        <td><div style={{width:150}}><Field name='od_otro' addClass='w-100' disabled={view} component={renderField} className='form-control'/></div></td>
                                        <td><div style={{width:150}}><Field name='os_otro' addClass='w-100' disabled={view} component={renderField} className='form-control'/></div></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div
                            className='d-flex flex-row mt-4'
                            style={{ display: "flex", justifyContent: "center" }}
                        >FINAL EN LENTE DE CONTACTO BLANDO</div>                        
                        <div className='d-flex flex-row'>
                            <table className='table table-bordered w-50 optics-order-table-head mt-1'>
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th className='text-center'>OD</th>
                                        <th className='text-center'>OS</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>ESF</td>
                                        <td className={`${od_esf_blan >= 0 ? 'color-verde' : 'color-rojo'} width-full`}>
                                            <div style={{width:150}}>
                                                <Field name='od_esf_blan' addClass='w-100' disabled={view} component={!view ? Esf : renderField} title={"OD (ESF)"} className='form-control'/>
                                            </div></td>
                                        <td className={`${os_esf_blan >= 0 ? 'color-verde' : 'color-rojo'} width-full`}>
                                            <div style={{width:150}}>
                                                <Field name='os_esf_blan' addClass='w-100' disabled={view} component={!view ? Esf : renderField} title={"OS (ESF)"} className='form-control'/>
                                            </div>
                                        </td>
                                    </tr>                                    
                                    <tr>
                                        <td>CIL</td>
                                        <td className={`${od_cil_blan >= 0 ? 'color-verde' : 'color-rojo'} width-full`}>
                                            <div style={{width:150}}>
                                                <Field name='od_cil_blan' addClass='w-100' disabled={view} component={!view ? Cil : renderField} title={"OD (CIL)"} className='form-control'/>
                                            </div>
                                        </td>
                                        <td className={`${os_cil_blan >= 0 ? 'color-verde' : 'color-rojo'} width-full`}>
                                            <div style={{width:150}}>
                                                <Field name='os_cil_blan' addClass='w-100' disabled={view} component={!view ? Cil : renderField} title={"OS (CIL)"} className='form-control'/>
                                            </div>
                                        </td>
                                    </tr> 
                                    <tr>
                                        <td>EJE</td>
                                        <td className='width-full'>
                                            <div style={{width:150}}>
                                                <Field name='od_eje_blan' addClass='w-100' disabled={view} component={!view ? Ejz : renderField} title={"OD (EJE)"} className='form-control'/>
                                            </div>
                                        </td>
                                        <td className='width-full'>
                                            <div style={{width:150}}>
                                                <Field name='os_eje_blan' addClass='w-100' disabled={view} component={!view ? Ejz : renderField} title={"OS (EJE)"} className='form-control'/>
                                            </div>
                                        </td>
                                    </tr>                                    
                                    <tr> 
                                        <td>MARCA</td>
                                        <td><div style={{width:150}}><Field name='od_marca_blan' addClass='w-100' disabled={view} component={renderField} className='form-control'/></div></td>
                                        <td><div style={{width:150}}><Field name='os_marca_blan' addClass='w-100' disabled={view} component={renderField} className='form-control'/></div></td>
                                    </tr>
                                    <tr>
                                        <td>OTRO</td>
                                        <td><div style={{width:150}}><Field name='od_otro_blan' addClass='w-100' disabled={view} component={renderField} className='form-control'/></div></td>
                                        <td><div style={{width:150}}><Field name='os_otro_blan' addClass='w-100' disabled={view} component={renderField} className='form-control'/></div></td>
                                    </tr>
                                </tbody>
                            </table> 
                        </div>

                        <div className='d-flex flex-row justify-content-center w-75 mt-4 mb-4'>
                            <label>Comentario</label>
                            <Field name="notes" disabled={view} component={modalTextArea} className='annotations ml-2'/>
                        </div>
                    </div>
                </div>
            </div>
        </form>

        {/* Agregado para la orden de óptica */}
        <PrintContainer name='impresion-orden-optica' className="impresion">
            <div className="contenedor-receta">
            <div className='d-flex flex-column justify-content-center align-items-center'>
            <br /><br />
            <h4>Orden de Óptica</h4>
            <table className='table table-sm table-bordered w-90'>
                <tbody>
                    <tr>
                        <td>NOMBRE: {orden_optica && orden_optica.name}</td>
                        <td>FECHA: {orden_optica && orden_optica.date}</td>
                    </tr>
                    <tr>
                        <td colSpan={2} className='text-center'>
                            Final recomendada: {orden_optica && orden_optica.recommended_end}
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={2} className=''>
                            <span className='font-weight-bold'>Comentario</span><br />
                            {orden_optica && orden_optica.comment}
                        </td>
                    </tr>
                </tbody>
            </table>

            <table className='table table-sm table-bordered w-90 optics-order-table-head'>
                <thead>
                    <tr className='text-dark'>
                        <th></th>
                        <th></th>
                        <th className='text-center'>Esfera</th>
                        <th className='text-center'>Cilindro</th>
                        <th className='text-center'>Eje</th>
                        <th className='text-center'>Prisma</th>
                        <th className='text-center'>Base</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td rowSpan={2}>FINAL</td>
                        <td>OD</td>
                        <td className='text-center'>{orden_optica && orden_optica.od_final_esfera}</td>
                        <td className='text-center'>{orden_optica && orden_optica.od_final_cilindro}</td>
                        <td className='text-center'>{orden_optica && orden_optica.od_final_eje}</td>
                        <td className='text-center'>{orden_optica && orden_optica.od_final_prisma}</td>
                        <td className='text-center'>{orden_optica && orden_optica.od_final_base}</td>
                    </tr>
                    <tr>
                        <td>OS</td>
                        <td className='text-center'>{orden_optica && orden_optica.os_final_esfera}</td>
                        <td className='text-center'>{orden_optica && orden_optica.os_final_cilindro}</td>
                        <td className='text-center'>{orden_optica && orden_optica.os_final_eje}</td>
                        <td className='text-center'>{orden_optica && orden_optica.os_final_prisma}</td>
                        <td className='text-center'>{orden_optica && orden_optica.os_final_base}</td>
                    </tr>
                    <tr>
                        <td rowSpan={2}>ADICIÓN</td>
                        <td>OD</td>
                        <td className='text-center'>{orden_optica && orden_optica.od_adicion_esfera}</td>
                        <td className='text-center'>{orden_optica && orden_optica.od_adicion_cilindro}</td>
                        <td className='text-center'>{orden_optica && orden_optica.od_adicion_eje}</td>
                        <td className='text-center'>{orden_optica && orden_optica.od_adicion_prisma}</td>
                        <td className='text-center'>{orden_optica && orden_optica.od_adicion_base}</td>
                    </tr>
                    <tr>
                        <td>OS</td>
                        <td className='text-center'>{orden_optica && orden_optica.os_adicion_esfera}</td>
                        <td className='text-center'>{orden_optica && orden_optica.os_adicion_cilindro}</td>
                        <td className='text-center'>{orden_optica && orden_optica.os_adicion_eje}</td>
                        <td className='text-center'>{orden_optica && orden_optica.os_adicion_prisma}</td>
                        <td className='text-center'>{orden_optica && orden_optica.os_adicion_base}</td>
                    </tr>
                </tbody>
            </table>

            <table className='table table-sm table-bordered w-90'>
                <tbody>
                    <tr>
                        {console.log("ORRRR", dip)}
                        <td>DIP: </td>
                        <td>{dip}</td>
                        <td> </td>
                        <td> </td>
                        <td> </td>
                        <td> </td>
                        <td> </td>
                    </tr>
                </tbody>
            </table>
            
            {(lens_type_exist || recomendations_exist) &&
            <div className="row" style={{ margin: '0px 25px 0px 25px' }}>
                {lens_type_exist &&
                <div className="col">
                    <table className='table table-sm table-bordered w-90 optics-order-table-head'>
                        <thead>
                            <tr className='text-dark'>
                                <th>TIPO DE LENTE</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {orden_optica && orden_optica.monofocal && orden_optica.monofocal.replaceAll(" ", "") != "" &&
                            <tr>
                                <td>MONOFOCAL</td>
                                <td>{orden_optica && orden_optica.monofocal}</td>
                            </tr>
                            }
                            {orden_optica && orden_optica.progresive && orden_optica.progresive.replaceAll(" ", "") != "" &&
                            <tr>
                                <td>PROGRESIVO</td>
                                <td>{orden_optica && orden_optica.progresive}</td>
                            </tr>
                            }
                            {orden_optica && orden_optica.bifocal && orden_optica.bifocal.replaceAll(" ", "") != "" &&
                            <tr>
                                <td>BIFOCAL</td>
                                <td>{orden_optica && orden_optica.bifocal}</td>
                            </tr>
                            }
                            {orden_optica && orden_optica.type_other && orden_optica.type_other.replaceAll(" ", "") != "" &&
                            <tr>
                                <td>OTRO</td>
                                <td>{orden_optica && orden_optica.type_other}</td>
                            </tr>
                            }
                        </tbody>
                    </table>
                </div>
                }
                {recomendations_exist &&
                <div className="col">
                    <table className='table table-sm table-bordered w-90 optics-order-table-head'>
                        <thead>
                            <tr className='text-dark'>
                                <th>RECOMENDACIÓN</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {orden_optica && orden_optica.blue_filter && orden_optica.blue_filter.replaceAll(" ", "") != "" &&
                            <tr>
                                <td>FILTRO DE LUZ AZUL</td>
                                <td>{orden_optica && orden_optica.blue_filter}</td>
                            </tr>
                            }
                            {orden_optica && orden_optica.anti_glare && orden_optica.anti_glare.replaceAll(" ", "") != "" &&
                            <tr>
                                <td>ANTIREFLEJO</td>
                                <td>{orden_optica && orden_optica.anti_glare}</td>
                            </tr>
                            }
                            {orden_optica && orden_optica.polarized && orden_optica.polarized.replaceAll(" ", "") != "" &&
                            <tr>
                                <td>POLARIZADO</td>
                                <td>{orden_optica && orden_optica.polarized}</td>
                            </tr>
                            }
                            {orden_optica && orden_optica.polycarbonate && orden_optica.polycarbonate.replaceAll(" ", "") != "" &&
                            <tr>
                                <td>POLICARBONATO</td>
                                <td>{orden_optica && orden_optica.polycarbonate}</td>
                            </tr>
                            }
                            {orden_optica && orden_optica.sunglasses && orden_optica.sunglasses.replaceAll(" ", "") != "" &&
                            <tr>
                                <td>LENTES DE SOL</td>
                                <td>{orden_optica && orden_optica.sunglasses}</td>
                            </tr>
                            }
                            {orden_optica && orden_optica.had && orden_optica.had.replaceAll(" ", "") != "" &&
                            <tr>
                                <td>TEÑIDO</td>
                                <td>{orden_optica && orden_optica.had}</td>
                            </tr>
                            }
                            {orden_optica && orden_optica.transitions && orden_optica.transitions.replaceAll(" ", "") != "" &&
                            <tr>
                                <td>TRANSITIONS</td>
                                <td>{orden_optica && orden_optica.transitions}</td>
                            </tr>
                            }
                            {orden_optica && orden_optica.recomendation_other && orden_optica.recomendation_other.replaceAll(" ", "") != "" &&
                            <tr>
                                <td>OTROS</td>
                                <td>{orden_optica && orden_optica.recomendation_other}</td>
                            </tr>
                            }
                        </tbody>
                    </table>
                </div>
                }
            </div>
            }

            {final_contact &&
            <table className='table table-sm table-bordered w-90 optics-order-table-head'>
                <thead>
                    <tr className='text-dark'>
                        <th></th>
                        <th className='text-center'>OD</th>
                        <th className='text-center'>OS</th>
                    </tr>
                </thead>
                <tbody>
                    {orden_optica && ((orden_optica.od_pwr && orden_optica.od_pwr.replaceAll(" ", "") != "") || 
                    (orden_optica.os_pwr && orden_optica.os_pwr.replaceAll(" ", "") != "")) &&
                    <tr>
                        <td className='text-center'>PWR.</td>
                        <td className='text-center'>{orden_optica && orden_optica.od_pwr}</td>
                        <td className='text-center'>{orden_optica && orden_optica.os_pwr}</td>
                    </tr>
                    }
                    {orden_optica && ((orden_optica.od_cyl && orden_optica.od_cyl.replaceAll(" ", "") != "") || 
                    (orden_optica.os_cyl && orden_optica.os_cyl.replaceAll(" ", "") != "")) &&
                    <tr>
                        <td className='text-center'>CYL</td>
                        <td className='text-center'>{orden_optica && orden_optica.od_cyl}</td>
                        <td className='text-center'>{orden_optica && orden_optica.os_cyl}</td>
                    </tr>
                    }
                    {orden_optica && ((orden_optica.od_eje && orden_optica.od_eje.replaceAll(" ", "") != "") || 
                    (orden_optica.os_eje && orden_optica.os_eje.replaceAll(" ", "") != "")) &&
                    <tr>
                        <td className='text-center'>EJE</td>
                        <td className='text-center'>{orden_optica && orden_optica.od_eje}</td>
                        <td className='text-center'>{orden_optica && orden_optica.os_eje}</td>
                    </tr>
                    }
                    {orden_optica && ((orden_optica.od_bc && orden_optica.od_bc.replaceAll(" ", "") != "") || 
                    (orden_optica.os_bc && orden_optica.os_bc.replaceAll(" ", "") != "")) &&
                    <tr>
                        <td className='text-center'>B.C.</td>
                        <td className='text-center'>{orden_optica && orden_optica.od_bc}</td>
                        <td className='text-center'>{orden_optica && orden_optica.os_bc}</td>
                    </tr>
                    }
                    {orden_optica && ((orden_optica.od_dia && orden_optica.od_dia.replaceAll(" ", "") != "") || 
                    (orden_optica.os_dia && orden_optica.os_dia.replaceAll(" ", "") != "")) &&
                    <tr>
                        <td className='text-center'>DIA</td>
                        <td className='text-center'>{orden_optica && orden_optica.od_dia}</td>
                        <td className='text-center'>{orden_optica && orden_optica.os_dia}</td>
                    </tr>
                    }
                    {orden_optica && ((orden_optica.od_add && orden_optica.od_add.replaceAll(" ", "") != "") || 
                    (orden_optica.os_add && orden_optica.os_add.replaceAll(" ", "") != "")) &&
                    <tr>
                        <td className='text-center'>ADD</td>
                        <td className='text-center'>{orden_optica && orden_optica.od_add}</td>
                        <td className='text-center'>{orden_optica && orden_optica.os_add}</td>
                    </tr>
                    }
                    {orden_optica && ((orden_optica.od_marca && orden_optica.od_marca.replaceAll(" ", "") != "") || 
                    (orden_optica.os_marca && orden_optica.os_marca.replaceAll(" ", "") != "")) &&
                    <tr>
                        <td className='text-center'>MARCA</td>
                        <td className='text-center'>{orden_optica && orden_optica.od_marca}</td>
                        <td className='text-center'>{orden_optica && orden_optica.os_marca}</td>
                    </tr>
                    }
                    {orden_optica && ((orden_optica.od_otro && orden_optica.od_otro.replaceAll(" ", "") != "") || 
                    (orden_optica.os_otro && orden_optica.os_otro.replaceAll(" ", "") != "")) &&
                    <tr>
                        <td className='text-center'>OTRO</td>
                        <td className='text-center'>{orden_optica && orden_optica.od_otro}</td>
                        <td className='text-center'>{orden_optica && orden_optica.os_otro}</td>
                    </tr>
                    }
                </tbody>
            </table>
            }
        </div>
        </div>
        </PrintContainer>
        {/* Agregado para la orden de óptica... */}
        </React.Fragment>
    )
};

let CrearForm = reduxForm({
    // a unique name for the form
    form: 'OpticsOrder',
    validate: (data) => {
        return validate(data, {
        });
    },
})(Form);

const selector = formValueSelector('OpticsOrder');
CrearForm = connect(state => {
    const od_esf_blan = selector(state, 'od_esf_blan');
    const os_esf_blan = selector(state, 'os_esf_blan');
    const od_cil_blan = selector(state, 'od_cil_blan');
    const os_cil_blan = selector(state, 'os_cil_blan');    

    return {
        dip: selector(state, 'dip'),
        od_esf_blan, 
        os_esf_blan,
        od_cil_blan,
        os_cil_blan,        

        enableRenitialize: true        
    };
})(CrearForm);

export default CrearForm;
