import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import { Field, FieldArray, reduxForm } from 'redux-form';
import { validate, validators } from 'validate-redux-form';
import {renderSearchCreateSelect, renderField, renderTextArea, renderFieldCheck} from "../../Utils/renderField/renderField";
import {api} from "../../../../api/api";
import { Tratamiento } from "../../Utils/Notations/tratamiento";
import {PL_DI} from "../../../../utility/variables";

class CheckResolve extends Component{
    constructor(props) {
        super(props);
    }

    render(){
        const { resolveDiagnosticPlan, diagnostic_id, appointment_id } = this.props;
        console.log("appointment id in check resolve .::: ", appointment_id);

        return(
            <React.Fragment>
            {!this.props.input.value === true ?
                <img
                    src={require("../../../../../assets/img/icons/empty-check-blue.png")}
                    style={{width: 25, cursor:'pointer'}}
                    onClick={() => resolveDiagnosticPlan(diagnostic_id, true, appointment_id)}
                    alt="Resuelto"
                />
            :
                <img
                    src={require("../../../../../assets/img/icons/fill-check-blue.png")}
                    style={{width: 25, cursor:'pointer'}}
                    onClick={() => resolveDiagnosticPlan(diagnostic_id, false, appointment_id)}
                    alt="Resuelto"
                />
            }
            </React.Fragment>
        );
    }
}

const getRefillable = (search) => {
    let opciones = [];
    return api.get('refillable/getRefillable', {search, record_type: PL_DI}).then((data) => {
        console.log("Data: ", data);
        data.results.forEach(item => {
            opciones.push({
                value: item.id,
                label: item.texto
            });
        });
        return opciones;
    }).catch(()=>[]);
}

export const diagnosticRenderRow =({ fields, meta: {error, submitFailed}, resolveDiagnosticPlan ,appointment_id, edit, view}) => (
    <React.Fragment>
        {
            fields.map((field, index) => (
                <tr key={index}>
                    <td>
                        <Field
                            name={`${field}.nombre`}
                            loadOptions={getRefillable}
                            component={renderSearchCreateSelect}
                            type="text"
                            className="no-border"
                        />
                    </td>
                    <td>
                        <Field
                            name={`${field}.td_dp`}
                            component={Tratamiento}
                        />
                    </td>
                    <td className='text-center'>
                        <Field
                            name={`${field}.realiced`}
                            component={CheckResolve}
                            resolveDiagnosticPlan={resolveDiagnosticPlan}
                            diagnostic_id={fields.get(index).id}
                            appointment_id={appointment_id}
                        />

                        {/*!fields.get(index).realiced ?
                            <img
                                src={require("../../../../../assets/img/icons/empty-check-blue.png")}
                                style={{width: 25, cursor:'pointer'}}
                                onClick={() => resolveDiagnosticPlan(fields.get(index).id, true, appointment_id)}
                                alt="Resuelto"
                            />
                        :
                            <img
                                src={require("../../../../../assets/img/icons/fill-check-blue.png")}
                                style={{width: 25, cursor:'pointer'}}
                                onClick={() => resolveDiagnosticPlan(fields.get(index).id, false, appointment_id)}
                                alt="Resuelto"
                            />
                        */}
                    </td>
                </tr>
                )
            )

        }
    </React.Fragment>
)

const Form = (props) => {
    const { handleSubmit, last_consult, this_consult, appointment_id, resolveDiagnosticPlan } = props;
    
    return (
        <form onSubmit={handleSubmit} className="d-flex flex-column">
            <strong style={{fontSize:25}}>RECONSULTA</strong>
            <div style={{border:'solid 1px gray', marginBottom:5}}> </div>

            <div className="d-flex flex-column justify-content-center w-100">
                <table className='table w-100'>
                    <thead style={{background:'gray', fontWeight:'bold', color:'white'}}>
                        <tr>
                            <td className='text-center'>Nombres</td>
                            <td className='text-center'>Plan Tratamiento</td>
                            <td className='text-center'>Acciones</td>
                        </tr>
                    </thead>
                    <tbody>
                        <FieldArray
                            name='dp_dpa'
                            component={diagnosticRenderRow}
                            appointment_id={this_consult.appointment_id}
                            resolveDiagnosticPlan={resolveDiagnosticPlan}
                        />
                    </tbody>
                </table>
            </div>
        </form>
    )
};

const CrearForm = reduxForm({
    form: 'Reconsult',
    validate: (data) => {
        return validate(data, {
        });
    },
})(Form);

export default CrearForm;
