import React, {Component} from 'react';
import Modal from 'react-responsive-modal';
import Form from "./InformationSheetForm";

import ConsultCard from './ConsultCard';
import LoadMask from "../Utils/LoadMask/LoadMask";
import imgD from '../../../../assets/img/icons/lefth-arrow-icon.png';
import imgI from '../../../../assets/img/icons/right-arrow-icon.png';
import {
    ButtonBack,
    ButtonFirst,
    ButtonLast,
    ButtonNext,
    CarouselProvider,
    DotGroup,
    ImageWithZoom,
    Slide,
    Slider
} from 'pure-react-carousel';

export default class ConsultHistory extends Component {
    state = {
        currentIndex: 0,
    };
    componentWillMount = () => {
        const {getConsultHistory, patient_id} = this.props;
        getConsultHistory(patient_id, 1);
    }

    slideNext = () => this.setState({ currentIndex: this.state.currentIndex + 1 });
    slidePrev = () => this.setState({ currentIndex: this.state.currentIndex - 1 });

    render(){
        const {
            openModalScan,
            open_modal_scan,
            openModalUpdateScan,
            open_modal_update_scan,
            saveScan,
            consult_history,
            patient_id,
            cargando_archivo,
            cargando,
            deleteScanAppointment,
            getConsultHistory,
        } = this.props;

        console.log("Consult history: ", consult_history);
        const handleOnDragStart = (e) => e.preventDefault();

        const { state: { currentIndex } } = this;

        const { page, totalPage } = consult_history;

        return(
            <LoadMask 
                loading={cargando_archivo}
            >
                <React.Fragment>
                    <div className='d-flex flex-row justify-content-center w-100 mt-3 mb-2'>
                        <div className="paginacion-histori">
                            { page !== 1 && (<img src={imgD} alt="" onClick={() => getConsultHistory(patient_id, page - 1)} />)}
                            &nbsp;&nbsp;
                            Pagina {page} de {totalPage}&nbsp;&nbsp;&nbsp;
                            { page !== totalPage && (<img src={imgI} alt="" onClick={() => getConsultHistory(patient_id, page + 1)} />)}
                        </div>
                        <Modal open={open_modal_scan} onClose={() => openModalScan(false)}>
                            <Form openModalScan={openModalScan} saveScan={saveScan} patient_id={patient_id} appointment_id={0}/>
                        </Modal>
                        <div className='d-flex flex-row justify-content-end aling-items-center w-75'
                        >
                            <button
                                onClick={()=> openModalScan(true)}
                                className='btn btn-success btn-sm'
                            >Cargar Archivos</button>                                                  
                        </div>
                    </div>
                    <LoadMask loading={cargando} blur_1>
                    { consult_history.results && consult_history.results.length &&
                        <div
                            className="container-div-galeria"
                            style={{
                                paddingLeft : -125,
                                paddingRight : 75,
                                position: 'absolute',
                                minWidth: '100%',
                                width: '100%',
                                maxWidth: '100%',
                            }}
                        >
                            <CarouselProvider
                                visibleSlides={1}
                                //totalSlides={6}
                                totalSlides={consult_history.results.length}
                                step={1}
                                naturalSlideWidth={800}
                                naturalSlideHeight={900}
                                dragEnabled={false}
                                style={{
                                    margin: 'auto',
                                    minWidth: '100%',
                                    width: '100%',
                                    maxWidth: '100%',
                                    position: 'absolute',
                                }}
                                //hasMasterSpinner
                            >
                                <div className='d-flex flex-row justify-content-center mb-2'>
                                    <DotGroup />
                                </div>
                                <Slider
                                    style={{
                                        minWidth: 810,
                                        width: '100%',
                                        position: 'absolute',
                                        margin: 'auto',
                                        height: 900,
                                    }}
                                >
                                    {consult_history.results && consult_history.results.map((consult, i) => (
                                        <Slide index={i} key={i}>
                                            <ConsultCard
                                                correlativo={i+1}
                                                consult={consult}
                                                open_modal_update_scan={open_modal_update_scan}
                                                openModalUpdateScan={openModalUpdateScan}
                                                saveScan={saveScan}
                                                patient_id={patient_id}
                                                //appointment_id={consult.id}

                                                cargando_archivo={cargando_archivo}
                                                deleteScanAppointment={deleteScanAppointment}
                                                me={this.props.me}
                                            />
                                        </Slide>
                                    ))}
                                </Slider>
                            </CarouselProvider>
                        </div>                        
                    }
                    </LoadMask>
                </React.Fragment>
            </LoadMask>
        );
    }
}
/*
                <CarouselProvider
                    visibleSlides={1}
                    //totalSlides={6}
                    totalSlides={consult_history.length}
                    step={1}
                    naturalSlideWidth={200}
                    naturalSlideHeight={150}

                    style={{
                        width: 800,
                        marginLeft: 50,
                        marginRight: 50,
                        height: 750,
                    }}
                    //hasMasterSpinner
                >
                    <div className='d-flex flex-row justify-content-center mb-2'>
                        <DotGroup />
                    </div>
                    <Slider>
                        {consult_history && consult_history.map((consult, i) => (
                            <Slide index={i} key={i}>
                                <ConsultCard
                                    correlativo={i+1}
                                    consult={consult}
                                    open_modal_update_scan={open_modal_update_scan}
                                    openModalUpdateScan={openModalUpdateScan}
                                    saveScan={saveScan}
                                    patient_id={patient_id}
                                    //appointment_id={consult.id}

                                    cargando_archivo={cargando_archivo}
                                    deleteScanAppointment={deleteScanAppointment}
                                    me={this.props.me}
                                />
                            </Slide>
                        ))}
                    </Slider>
                </CarouselProvider>
*/
