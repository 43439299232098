import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import {Field, reduxForm} from 'redux-form';
import {validate} from 'validate-redux-form';
import {renderField} from "../../Utils/renderField";
import {modalTextArea, renderFieldCheck} from "../../Utils/renderField/renderField";
import { Primera } from "../../Utils/Notations/Keratometry/primera";
import { Segunda } from "../../Utils/Notations/Keratometry/segunda";
import { Ejz } from "../../Utils/Notations/lensometry/eje";


import { Esf } from "../../Utils/Notations/lensometry/esf";
import { Cil } from "../../Utils/Notations/lensometry/cil";
import { Dip } from "../../Utils/Notations/autorefractor/dip";

const Form = (props) => {
    const {
        handleSubmit,
        od_esf,
        os_esf,
        od_cil,
        os_cil,
    } = props;

    const [click1, setClick1] = useState(false)
    const [click2, setClick2] = useState(false)
    const view = window.location.href.includes("reporte");
    const ojo_derecho_k1 = props.ojo_derecho_k1
    const ojo_derecho_k2 = props.ojo_derecho_k2
    const ojo_izquierdo_k1 = props.ojo_izquierdo_k1
    const ojo_izquierdo_k2 = props.ojo_izquierdo_k2

    useEffect(() => {
        if(click1){
            if(ojo_derecho_k1 && ojo_derecho_k2){
                let promedio = (parseFloat(ojo_derecho_k1) + parseFloat(ojo_derecho_k2)) / 2
                props.changeKProm('od_k_prom', promedio)
            }else{
                props.changeKProm('od_k_prom', undefined)
            }
        }
    }, [ojo_derecho_k1, ojo_derecho_k2]);

    useEffect(()=>{
        if(click2){
            if (ojo_izquierdo_k1 && ojo_izquierdo_k2) {
                let promedio = (parseFloat(ojo_izquierdo_k1) + parseFloat(ojo_izquierdo_k2)) / 2
                props.changeKProm('os_k_prom', promedio)
            } else {
                props.changeKProm('os_k_prom', 0)
            }
        }
    }, [ojo_izquierdo_k1, ojo_izquierdo_k2])


    return (
        <form onSubmit={handleSubmit} className="d-flex flex-column">
            {!view ? <h3><strong>Queratometría</strong></h3> : null}
            <div className="d-flex flex-column justify-content-center px-0 px-sm-2 px-md-3 px-lg-5">
                <table className="table-input">
                    <tbody>
                    <tr>
                        <th />
                        <th>
                            K1
                        </th>
                        <th>

                        </th>
                        <th>
                            EJE
                        </th>
                        <th>
                            No marca
                        </th>
                        <th>
                            Irregular
                        </th>
                        <th>
                            K prom
                        </th>
                    </tr>
                    <tr>
                        <td>OD</td>
                        <td>
                            {/*<Field name="od_k1" component={renderField} className="form-control"/>*/}
                            <Field name="od_k1" setClick={setClick1} component={!view ? Primera : renderField}  disabled={view} title="ESCALA P" className="form-control"/>
                        </td>
                        <td><strong>x</strong></td>
                        <td>
                            {/*<Field name="od_eje_k1" component={renderField} className="form-control"/>*/}
                            <Field name="od_eje_k1" component={!view ? Ejz : renderField}  disabled={view} title="OD K1 (EJE)" className="form-control"/>
                        </td>
                        <td>
                            <Field name="od_no_marca_k1" component={renderFieldCheck}  disabled={view} type="text" className="form-control m-0"/>
                        </td>
                        <td>
                            <Field name="od_irregular_k1" component={renderFieldCheck}  disabled={view} type="text" className="form-control m-0"/>
                        </td>
                        <td>
                            <Field name="od_k_prom" component={renderField}  disabled={view} className="form-control"/>
                        </td>
                    </tr>
                    <tr>
                        <th />
                        <th>
                            K2
                        </th>
                        <th></th>
                        <th>
                            EJE
                        </th>
                        <th />
                        <th />
                        <th />
                    </tr>
                    <tr>
                        <td />
                        <td>
                            {/*<Field name="od_k2" component={renderField} className="form-control"/>*/}
                            <Field name="od_k2" setClick={setClick1} component={!view ? Primera : renderField}  disabled={view} title="ESCALA P" className="form-control"/>
                        </td>
                        <td><strong>x</strong></td>
                        <td>
                            {/*<Field name="od_eje_k2" component={renderField} className="form-control"/>*/}
                            <Field name="od_eje_k2" component={!view ? Ejz : renderField}  disabled={view} title="OD K2 (EJE)" className="form-control"/>
                        </td>
                        <td>
                            <Field name="od_no_marca_k2" component={renderFieldCheck}  disabled={view} type="text" className="form-control m-0"/>
                        </td>
                        <td>
                            <Field name="od_irregular_k2" component={renderFieldCheck}  disabled={view} type="text" className="form-control m-0"/>
                        </td>
                        <td />
                    </tr>

                    <tr>
                        <th />
                        <th>
                            K1
                        </th>
                        <th></th>
                        <th>
                            EJE
                        </th>
                        <th>
                            No marca
                        </th>
                        <th>
                            Irregular
                        </th>
                        <th>
                            K prom
                        </th>
                    </tr>
                    <tr>
                        <td>OS</td>
                        <td>
                            {/*<Field name="os_k1" component={renderField} className="form-control"/>*/}
                            <Field name="os_k1" setClick={setClick2} component={!view ? Primera : renderField}  disabled={view} title="ESCALA P" className="form-control"/>
                        </td>
                        <td><strong>x</strong></td>
                        <td>
                            {/*<Field name="os_eje_k1" component={renderField} className="form-control"/>*/}
                            <Field name="os_eje_k1" component={!view ? Ejz : renderField}  disabled={view} title="OS K1 (EJE)" className="form-control"/>
                        </td>
                        <td>
                            <Field name="os_no_marca_k1" component={renderFieldCheck}  disabled={view} type="text" className="form-control m-0"/>
                        </td>
                        <td>
                            <Field name="os_irregular_k1" component={renderFieldCheck}  disabled={view} type="text" className="form-control m-0"/>
                        </td>
                        <td>
                            <Field name="os_k_prom" component={renderField}  disabled={view} className="form-control"/>
                        </td>
                    </tr>
                    <tr>
                        <th />
                        <th>
                            K2
                        </th>
                        <th></th>
                        <th>
                            EJE
                        </th>
                        <th />
                        <th />
                        <th />
                    </tr>
                    <tr>
                        <td />
                        <td>
                            {/*<Field name="os_k2" component={renderField} className="form-control"/>*/}
                            <Field name="os_k2" setClick={setClick2} component={!view ? Primera : renderField} disabled={view} title="ESCALA P" className="form-control"/>
                        </td>
                        <td><strong>x</strong></td>
                        <td>
                            {/*<Field name="os_eje_k2" component={renderField} className="form-control"/>*/}
                            <Field name="os_eje_k2" component={!view ? Ejz : renderField} disabled={view} title="OS K2 (EJE)" className="form-control"/>
                        </td>
                        <td>
                            <Field name="os_no_marca_k2" component={renderFieldCheck} disabled={view} type="text" className="form-control m-0"/>
                        </td>
                        <td>
                            <Field name="os_irregular_k2" component={renderFieldCheck} disabled={view} type="text" className="form-control m-0"/>
                        </td>
                        <td />
                    </tr>

                    </tbody>
                </table>

                {!view ? <h3 style={{ marginLeft: '-48px', marginTop: '18px' }}><strong>Autorefractómetro</strong></h3> : null}                
                <div className="d-flex flex-column justify-content-center px-0 px-sm-2 px-md-3 px-lg-5">
                <table className="table-input">
                    <tbody>
                    <tr>
                        <th />
                        <th>
                            ESF
                        </th>
                        <th />
                        <th>
                            CIL
                        </th>
                        <th />
                        <th>
                            EJE
                        </th>
                        <th>
                            No marca
                        </th>
                        <th>
                            DIP
                        </th>
                    </tr>
                    <tr>
                        <td>OD</td>
                        <td className={od_esf >= 0 ? 'color-verde' : 'color-rojo'}>
                            <Field name="od_esf" component={!view ? Esf : renderField} disabled={view} title={'OD (ESF)'} className="form-control"/>
                        </td>
                        <td><strong>-</strong></td>
                        <td className={od_cil >= 0 ? 'color-verde' : 'color-rojo'}>
                            <Field name="od_cil" component={!view ? Cil : renderField} disabled={view} title={'OD (CIL)'} className="form-control"/>
                        </td>
                        <td><strong>x</strong></td>
                        <td className='font-weight-bold'>
                            <Field name="od_eje" component={!view ? Ejz : renderField} disabled={view} title={'OD (EJE)'} className="form-control"/>
                        </td>
                        <td>
                            <Field name="od_no_marca" component={renderFieldCheck} disabled={view} type="text" className="form-control m-0"/>
                        </td>
                        <td />
                    </tr>
                    <tr>
                        <td>OS</td>
                        <td className={os_esf >= 0 ? 'color-verde' : 'color-rojo'}>
                            <Field name="os_esf" component={!view ? Esf : renderField} disabled={view} title={'OS (ESF)'} className="form-control"/>
                        </td>
                        <td><strong>-</strong></td>
                        <td className={os_cil >= 0 ? 'color-verde' : 'color-rojo'}>
                            <Field name="os_cil" component={!view ? Cil : renderField} disabled={view} title={'OS (CIL)'} className="form-control"/>
                        </td>
                        <td><strong>x</strong></td>
                        <td className='font-weight-bold'>
                            <Field name="os_eje" component={!view ? Ejz : renderField} disabled={view} title={'OS (EJE)'} className="form-control"/>
                        </td>
                        <td>
                            <Field name="os_no_marca" component={renderFieldCheck} disabled={view} type="text" className="form-control m-0"/>
                        </td>
                        <td className='font-weight-bold'>
                            <Field name="dip" component={!view ? Dip : renderField} disabled={view} title={" DIP "} className="form-control"/>
                        </td>
                    </tr>
                    </tbody>
                </table>               
            </div>
                
                <div className='d-flex flex-row justify-content-center'>
                    <div className="mb-md-4 d-flex justify-content-between flex-column flex-sm-row align-items-stretch align-items-sm-center mt-4 w-50">
                        <div className="m-2 d-flex flex-column w-100">
                            <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                <label htmlFor="notes">Notas</label>
                            </div>
                            <div className='d-flex flex-row'>
                                <label className="radio-inline mr-3 d-flex align-items-center">
                                    OD&nbsp;
                                    <Field
                                        name="type"
                                        component={renderField}
                                        type="radio"
                                        value={"1"}
                                        disabled={view}
                                    />
                                </label>
                                <label className="radio-inline mr-3 d-flex align-items-center">
                                    OS&nbsp;
                                    <Field
                                        name="type"
                                        component={renderField}
                                        type="radio"
                                        value={"2"}
                                        disabled={view}
                                    />
                                </label>
                                <label className="radio-inline mr-3 d-flex align-items-center">
                                    OU&nbsp;
                                    <Field
                                        name="type"
                                        component={renderField}
                                        type="radio"
                                        value={"3"}
                                        disabled={view}
                                    />
                                </label>
                            </div>
                            <div className="d-flex flex-row w-100">
                                <div className="d-flex flex-row w-75">
                                <Field
                                    name="notes"
                                    component={modalTextArea}
                                    className="annotations"
                                    disabled={view}
                                />
                                </div>
                                <div className='d-flex flex-row w-25 ml-3'>
                                    <button
                                        type="button"
                                        className='btn btn-danger'
                                        onClick={()=>{props.change('notes', 'DIFERIDO');}}
                                    >DIFERIDO</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    )
};

const CrearForm = reduxForm({
    // a unique name for the form
    form: 'Keratometry',
    validate: (data) => {
        return validate(data, {
        });
    },
})(Form);

export default CrearForm;
