import React, { Component } from "react";

export default class Recipe extends Component {
  componentWillUnmount() {
      this.props.resetStore();
  }

  componentWillMount() {
      if (this.props.match.params.id) {
          this.props.getDiagnosticPlan(this.props.match.params.id);
      }      
  }
  
  render(){
    const handlePrint = () => {
      const originalContent = document.body.innerHTML;
      const printContent = document.querySelector('.print-area').innerHTML;
      
      document.body.innerHTML = `
        <div style="width:800px;margin:0 auto;background:white;color:black;">
          ${printContent}
        </div>
      `;
      
      window.print();
      document.body.innerHTML = originalContent;
      window.close();      
    };

    return(
      <React.Fragment>
        <style>
          {`
            @media print {
              @page {
                size: A4;
                margin: 0;
              }
              body {
                -webkit-print-color-adjust: exact !important;
                print-color-adjust: exact !important;
                background: white !important;
              }
              .no-print {
                display: none !important;
              }
              .print-area {
                all: initial;
                width: 100% !important;
                position: static !important;
                overflow: visible !important;
              }
              .print-content {
                width: 800px;
                margin: 0 auto;
                padding: 20px;
                background: white !important;
                color: black !important;
              }
            }
          `}
        </style>       
        <div
            className="dont-print"
            style={{
                display: "flex",
                justifyContent: "right",
                margin: "20px 20px 20px 0px",
            }}
        >
            <button
                className="btn btn-primary ml-3 mr-3"
                style={{
                    maxWidth: "100px",
                }}
                //onClick={() => print()}
                onClick={handlePrint}
            >
                IMPRIMIR
            </button>
        </div>
        <div
          className="print-area"
          style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "black"
          }}
        >
          <div
            className="print-content"
            style={{
                width: "800px",
                height: "100%",
                backgroundColor: "white",                  
            }}
          >
          <div
            style={{ display: 'flex', justifyContent: 'flex-end'}}
          >
            <span
              style={{
                background: 'gray',
                fontWeight: 'bold',
                color: 'white',
                padding: '12px',
                borderBottomLeftRadius: '12px'
              }}
            >{this.props.match.params.patient && this.props.match.params.patient}</span>
          </div>
          {this.props.receta && this.props.receta.dx && this.props.receta.dx.length > 0 &&
                <React.Fragment>
                  <div style={{color: 'black'}}>
                    <p style={{ fontWeight: 700, marginBottom: '5px'}}>Diagnóstico</p>
                    <ul>
                        {this.props.receta.dx.map((diagnostico, key) => (
                            <li key={key}>
                                <span>{diagnostico.nombre.label}</span>
                            </li>
                        ))}
                    </ul>
                  </div>
                    <br />
                </React.Fragment>
            }

            {this.props.receta && this.props.receta.txn && this.props.receta.txn.length == 1 &&
            <React.Fragment>
                    <p style={{ fontWeight: 700, marginBottom: '5px'}}>RECETA</p>
                    <React.Fragment>
                        {this.props.receta.txn[0].td_dp.map((treatment, key) => (
                            <div key={key} className='mt-1' style={{color: 'black'}}>                            
                                <p style={{ marginBottom: 0, textDecoration: "underline" }}>Medicamento</p>
                                <p style={{ marginTop: 0, marginBottom: 0 }}><strong>{treatment.commercial_name}</strong> {treatment.quantity} {treatment.presentation} (<span>Principio activo: <strong>{treatment.active_principle}</strong></span>)</p>
                                
                                <p style={{ marginTop: 0 }}>Administrar {treatment.dose} cada {treatment.frecuency} por {treatment.time_duration}</p>
                            </div>
                        ))}                                                   
                    </React.Fragment>                            
                </React.Fragment>
            }          
            <div className='mt-2'>{this.props.receta ? this.props.receta.notes : null}</div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}