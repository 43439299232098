import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form';
import { validate, validators } from 'validate-redux-form';
import {renderSearchCreateSelect, renderField, renderSelectCustom} from "../../Utils/renderField";
import {api} from "../../../../api/api";
import {modalTextArea} from "../../Utils/renderField/renderField";

import { Esf } from "../../Utils/Notations/lensometry/esf";
import { Cil } from "../../Utils/Notations/lensometry/cil";
import { Ejz } from "../../Utils/Notations/lensometry/eje";
import { Add } from "../../Utils/Notations/lensometry/add";

import {Tonometry} from "../../Utils/Notations/tonometry/tonometry"; 

const getLenstypes = (search) => {
    return api.get("record", {search, record_type: 25})
        .then(results => results.results.map(item => {return {label: item.name, value: item.id}}))
        .catch(() => []);
};

let Form = (props) => {
    const {handleSubmit} = props;
    const view = window.location.href.includes("reporte");

    return (
        <form onSubmit={handleSubmit} className="d-flex flex-column">
            {!view ? <h3><strong>TONOMETRÍA</strong></h3> : null}
            <div className="d-flex flex-column justify-content-center px-0 px-sm-2 px-md-3 px-lg-5">
                <div className='d-flex flex-row justify-content-center mt-5'>
                    <table className="table-input w-25 mt-3">
                        <tbody>
                        <tr>
                            <td>OD</td>
                            <td>
                                {/*<Field name="od_mmhg" component={Esf} title={"OD (ESF)"} className="form-control"/>*/}
                                <Field
                                    name="od_mmhg"
                                    //component={renderField}
                                    component={!view ? Tonometry : renderField}
                                    disabled={view}
                                    title='OD (MMHG)'
                                    //type='input'
                                    className="form-control"
                                    onChange={()=>{props.change('od_tono_digital', null)}}/>
                            </td>
                            <th>
                                mmhg
                            </th>
                        </tr>
                        <tr>
                            <th></th>
                            <th>
                                0-70
                            </th>
                        </tr>
                        <tr>
                            <td>OS</td>
                            <td>
                                {/*<Field name="os_mmhg" component={Esf} title={"OS (ESF)"} className="form-control"/>*/}
                                <Field
                                    name="os_mmhg"
                                    //component={renderField}
                                    component={!view ? Tonometry : renderField}
                                    disabled={view}
                                    title='OD (MMHG)'
                                    //type='input'
                                    className="form-control"
                                    onChange={()=>{props.change('os_tono_digital', null)}}
                                />
                            </td>
                            <th>mmhg</th>
                        </tr>
                        <tr>
                            <th></th>
                            <th>
                                0-70
                            </th>
                        </tr>
                        </tbody>
                    </table>
                    <table className='table other-table-input table-sm ml-5 w-25'>
                        <tbody>
                            <tr>
                                <th colSpan={3}>Tono digital</th>
                            </tr>
                            <tr>
                                <td>
                                    <label className='radio-inline d-flex align-items-center'>
                                        Bajo&nbsp;
                                        <Field
                                            name="od_tono_digital"
                                            component={renderField}
                                            type="radio"
                                            value={"1"}
                                            onChange={()=>{props.change('od_mmhg', '')}}
                                            disabled={view}
                                        />
                                    </label>
                                </td>
                                <td>
                                    <label className='radio-inline d-flex align-items-center'>
                                        Normal&nbsp;
                                        <Field
                                            name="od_tono_digital"
                                            component={renderField}
                                            type="radio"
                                            value={"5"}
                                            onChange={()=>{props.change('od_mmhg', '')}}
                                            disabled={view}
                                        />
                                    </label>
                                </td>
                                <td>
                                    <label className='radio-inline d-flex align-items-center'>
                                        Alto&nbsp;
                                        <Field
                                            name="od_tono_digital"
                                            component={renderField}
                                            type="radio"
                                            value={"15"}
                                            onChange={()=>{props.change('od_mmhg', '')}}
                                            disabled={view}
                                        />
                                    </label>
                                </td>
                            </tr>
                            <tr>
                                <th colSpan={3}></th>
                            </tr>
                            <tr>
                                <th colSpan={3}>Tono digital</th>
                            </tr>
                            <tr>
                                <td>
                                    <label className='radio-inline d-flex align-items-center'>
                                        Bajo&nbsp;
                                        <Field
                                            name="os_tono_digital"
                                            component={renderField}
                                            type="radio"
                                            value={"1"}
                                            onChange={()=>{props.change('os_mmhg', '')}}
                                            disabled={view}
                                        />
                                    </label>
                                </td>
                                <td>
                                    <label className='radio-inline d-flex align-items-center'>
                                        Normal&nbsp;
                                        <Field
                                            name="os_tono_digital"
                                            component={renderField}
                                            type="radio"
                                            value={"5"}
                                            onChange={()=>{props.change('os_mmhg', '')}}
                                            disabled={view}
                                        />
                                    </label>
                                </td>
                                <td>
                                    <label className='radio-inline d-flex align-items-center'>
                                        Alto&nbsp;
                                        <Field
                                            name="os_tono_digital"
                                            component={renderField}
                                            type="radio"
                                            value={"15"}
                                            onChange={()=>{props.change('os_mmhg', '')}}
                                            disabled={view}
                                        />
                                    </label>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div className='d-flex flex-row justify-content-center'>
                    <div className="mb-md-4 d-flex justify-content-between flex-column flex-sm-row align-items-stretch align-items-sm-center mt-4 w-50">
                        <div className="m-2 d-flex flex-column w-100">
                            <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                <label htmlFor="notes">Notas</label>
                            </div>
                            <div className='d-flex flex-row'>
                                <label className="radio-inline mr-3 d-flex align-items-center">
                                    OD&nbsp;
                                    <Field
                                        name="type"
                                        component={renderField}
                                        type="radio"
                                        value={"1"}
                                        disabled={view}
                                    />
                                </label>
                                <label className="radio-inline mr-3 d-flex align-items-center">
                                    OS&nbsp;
                                    <Field
                                        name="type"
                                        component={renderField}
                                        type="radio"
                                        value={"2"}
                                        disabled={view}
                                    />
                                </label>
                                <label className="radio-inline mr-3 d-flex align-items-center">
                                    OU&nbsp;
                                    <Field
                                        name="type"
                                        component={renderField}
                                        type="radio"
                                        value={"3"}
                                        disabled={view}
                                    />
                                </label>
                            </div>
                            <div className="d-flex flex-row w-100">
                                <div className='d-flex flex-row w-75'>
                                    <Field
                                        name="notes"
                                        component={modalTextArea}
                                        className="annotations"
                                        disabled={view}
                                    />
                                </div>
                                <div className='d-flex flex-row w-25 ml-3'>
                                    <button
                                        type="button"
                                        className='btn btn-danger'
                                        onClick={()=>{props.change('notes', 'DIFERIDO');}}
                                    >DIFERIDO</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    )
};

let TonometryForm = reduxForm({
    // a unique name for the form
    form: 'Tonometry',
    validate: (data) => {
        return validate(data, {
        });
    },
})(Form);

export default TonometryForm;
