import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import {departamentos, municipios} from "../../../../utility/variables";
import './patient';
import ConsultCard from '../../MedicalHistory/ConsultCard';


export default class Patient extends Component {
    state = {
        tab: 'data',
    }

    changeTab = (tab) => {
        this.setState({tab});
    }

    calcularEdad = (fechaNacimiento) => {
        const fechaNac = new Date(fechaNacimiento);
        const hoy = new Date();
    
        let edad = hoy.getFullYear() - fechaNac.getFullYear();
        const mes = hoy.getMonth() - fechaNac.getMonth();
        const dia = hoy.getDate() - fechaNac.getDate();
    
        // Restar un año si aún no ha cumplido en este año
        if (mes < 0 || (mes === 0 && dia < 0)) {
            edad--;
        }
    
        return edad;
    }

    render() {
        const {patient, last_record, last_dx_tx, consult} = this.props;

        const left_tab = this.state.tab === 'data' ? 'patient-section-tab' : '';
        const right_tab = this.state.tab === 'history' ? 'patient-section-tab' : '';
        const edad = patient.birthdate ? ' ' + this.calcularEdad(patient.birthdate) + ' años' : '---'
        
        return (
            <div 
                //className="d-flex flex-column"
                className='d-flex flex-column' style={{ width: '100%' }}
            >
                <div 
                    //className='d-flex flex-row'
                    className='d-flex flex-row'
                >
                    <div className="text-primary font-weight-bold my-3" onClick={()=>this.changeTab('data')}>
                        <button className={'patient-tab-left ' + left_tab}>DATOS DEL PACIENTE</button>
                    </div>
                    <div className="text-primary font-weight-bold my-3" onClick={()=>this.changeTab('history')}>
                        <button className={'patient-tab-right '  + right_tab}>HISTORIAL CLÍNICO</button>
                    </div>
                </div>
                <div className='d-flex' style={{ width: '100%', justifyContent: "space-between" }}>
                {this.state.tab === 'data' ?
                    <React.Fragment>
                        <div className='d-flex flex-column'>
                            <div className="pb-1">
                                <strong className='mt-3'>Nombre:</strong>
                                {patient.first_name} {patient.last_name}
                            </div>                            
                            <div className="pb-1">
                                <strong className='mt-3'>Edad:</strong>
                                {edad}
                            </div>
                        </div>
                        <center className='mt-2'>
                            <a 
                                href={`/#/medical-history/${patient.id}`}
                                target="_blank"
                                className="btn btn-success mb-3 align-self-start px-1 py-0"
                            >
                                Ver historial clínico
                            </a>                            
                        </center>

                        {/*
                        <strong className='mt-3'>Género:</strong>
                        <div className="border-bottom pb-1">{patient.gender === 1 ? "Masculino" : "Femenino"}</div>
                        <strong className='mt-3'>Profesión:</strong>
                        <div className="border-bottom pb-1">{patient.profession ? patient.profession : "---"}</div>
                        <strong className='mt-3'>Nacionalidad:</strong>
                        <div className="border-bottom pb-1">{patient.nationality ? patient.nationality : "---"}</div>
                        <strong className='mt-3'>Departamento:</strong>
                        <div className="border-bottom pb-1">{patient.departamento ? _.find(departamentos, {value: patient.departamento}).label : "---"}</div>
                        <strong className='mt-3'>Municipio:</strong>
                        <div className="border-bottom pb-1">{patient.municipio ? _.find(municipios, {value: patient.municipio}).label : "---"}</div>
                        <strong className='mt-3'>Dirección:</strong>
                        <div className="border-bottom pb-1">{patient.address ? patient.address : "---"}</div>
                        <strong className='mt-3'>DPI:</strong>
                        <div className="border-bottom pb-1">{patient.dpi ? patient.dpi : "---"}</div>
                        <strong className='mt-3'>Correo:</strong>
                        <div className="border-bottom pb-1">{patient.email ? patient.email : "---"}</div>
                        <strong className='mt-3'>Teléfono:</strong>
                        <div className="border-bottom pb-1">{patient.phone ? patient.phone : "---"}</div>
                        <strong className='mt-3'>Referente:</strong>
                        <div className="border-bottom pb-1">{patient.referred_by ? patient.referred_by.name : ""}</div>
                        */}
                    </React.Fragment>
                :
                    <React.Fragment>
                        {consult ?
                            <ConsultCard
                                consult={consult}
                                anotherClass='into-consult'
                            />
                        :
                            <span>No tiene historial de consulta previa</span>
                        }

                        {/*(last_dx_tx !== null && last_dx_tx !== undefined) ?
                        <div
                            style={{
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'column'
                            }}
                        >
                            <div
                                style={{
                                    width: '100%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    marginBottom: '8px'
                                }}
                            >
                                <div
                                    style={{
                                        width: '100%',                                    
                                        display: 'flex',
                                        justifyContent: 'flex-start',
                                        gap: '32px',
                                        margin: 'auto 32px',
                                        marginBottom: '8px'
                                    }}                                
                                >
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            gap: '4px',
                                            minWidth: '150px'
                                        }}
                                    >
                                        <label style={{ margin: 0 }}>MD</label>
                                        {last_dx_tx.records['1'] &&
                                            <div style={{ display:'flex', flexDirection: 'column'}}>
                                            {last_dx_tx.records['1'].map(md => (
                                                <span>{md.name} - {md.treatment}</span>
                                            ))}
                                            <br />
                                            {last_dx_tx.notes.md_note && 
                                                <small style={{ background: 'yellow', padding: '4px'}}>{last_dx_tx.notes.md_note}</small>
                                            }
                                            </div>
                                        }
                                    </div>
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            gap: '4px',
                                            minWidth: '150px'
                                        }}
                                    >
                                        <label style={{ margin: 0 }}>QX</label>
                                        {last_dx_tx.records['2'] &&
                                            <div style={{ display:'flex', flexDirection: 'column'}}>
                                            {last_dx_tx.records['2'].map(md => (
                                                <span>{md.name}</span>
                                            ))}
                                            <br />
                                            {last_dx_tx.notes.qx_note && 
                                                <small style={{ background: 'yellow', padding: '4px'}}>{last_dx_tx.notes.qx_note}</small>
                                            }                                           
                                            </div>
                                        }
                                    </div>  
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            gap: '4px',
                                            minWidth: '150px'
                                        }}
                                    >
                                        <label style={{ margin: 0 }}>ALG</label>
                                        {last_dx_tx.records['3'] &&
                                            <div style={{ display:'flex', flexDirection: 'column'}}>
                                            {last_dx_tx.records['3'].map(md => (
                                                <span>{md.name}</span>
                                            ))}
                                            <br />
                                            {last_dx_tx.notes.alg_note && 
                                                <small style={{ background: 'yellow', padding: '4px'}}>{last_dx_tx.notes.alg_note}</small>
                                            }                                            
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div
                                    style={{
                                        width: '100%',                                    
                                        display: 'flex',
                                        justifyContent: 'flex-start',
                                        gap: '32px',
                                        margin: 'auto 32px'
                                    }}
                                >
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            gap: '4px',
                                            minWidth: '150px'
                                        }}
                                    >
                                        <label style={{ margin: 0 }}>TRX</label>
                                        {last_dx_tx.records['4'] &&
                                            <div style={{ display:'flex', flexDirection: 'column'}}>
                                            {last_dx_tx.records['4'].map(md => (
                                                <span>{md.name}</span>
                                            ))}
                                            <br />
                                            {last_dx_tx.notes.trx_note && 
                                                <small style={{ background: 'yellow', padding: '4px'}}>{last_dx_tx.notes.trx_note}</small>
                                            }                                            
                                            </div>
                                        }
                                    </div>
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            gap: '4px',
                                            minWidth: '150px'
                                        }}
                                    >
                                        <label style={{ margin: 0 }}>FAM</label>
                                        {last_dx_tx.records['5'] &&
                                            <div style={{ display:'flex', flexDirection: 'column'}}>
                                            {last_dx_tx.records['5'].map(md => (
                                                <span>{md.name} - {md.fam}</span>
                                            ))}
                                            <br />
                                            {last_dx_tx.notes.fam_note && 
                                                <small style={{ background: 'yellow', padding: '4px'}}>{last_dx_tx.notes.fam_note}</small>
                                            }                                            
                                            </div>
                                        }
                                    </div>                                                                        
                                </div>
                            </div>
                            <div
                                style={{
                                    width: '100%',
                                    borderTop: 'solid 1px gray',
                                    display: 'flex',
                                    justifyContent: 'space-around',
                                    gap: '8px'
                                }}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: '4px'
                                    }}
                                >
                                    <strong>Diagnósticos: </strong>
                                    {last_dx_tx.diagnostics.map(d => (
                                        <span>{d.nombre}</span>
                                    ))}
                                </div>

                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: '4px'
                                    }}                                
                                >
                                    <strong>Tratamientos: </strong>
                                    {last_dx_tx.treatments.map(t => (
                                        <span>{t.treatment_commercial_name}</span>
                                    ))}
                                </div>
                            </div> 
                        </div>
                        :
                            <span>No tiene historial de consulta previa</span>
                        }
                        {/*last_record !== null ?
                        <React.Fragment>
                            <strong className='text-primary pt-3'>MD</strong>
                            {last_record.md.map((md) => (
                                <React.Fragment>
                                <strong>Diagnostico</strong>
                                <div className='border-bottom pb-1'>{md.diagnostico.label}</div>
                                <strong>Tratamiento</strong>
                                <div className='border-bottom pb-1'>{md.tratamiento.label}</div>
                                </React.Fragment>
                            ))}

                            <strong className='text-primary pt-3'>QX</strong>
                            {last_record.qx.map((qx) => (
                                <React.Fragment>
                                    <strong>Diagnostico</strong>
                                    <div className='border-bottom pb-1'>{qx.cirugia.label}</div>
                                </React.Fragment>
                            ))}
                            <strong>Notas</strong>
                            <div className='border-bottom pb-1'>{last_record.qx_note}</div>

                            <strong className='text-primary pt-3'>ALG</strong>
                            {last_record.alg.map((alg) => (
                                <React.Fragment>
                                    <strong>Diagnostico</strong>
                                    <div className='border-bottom pb-1'>{alg.alergia.label}</div>
                                </React.Fragment>
                            ))}

                            <strong>Notas</strong>
                            <div className='border-bottom pb-1'>{last_record.alg_note}</div>

                            <strong className='text-primary pt-3'>TRX</strong>
                            {last_record.trx.map((trx) => (
                                <React.Fragment>
                                    <strong>Diagnostico</strong>
                                    <div className='border-bottom pb-1'>{trx.trauma.label}</div>
                                </React.Fragment>
                            ))}

                            <strong>Notas</strong>
                            <div className='border-bottom pb-1'>{last_record.trx_note}</div>

                            <strong className='text-primary pt-3'>FAM</strong>
                            {last_record.fam.map((fam) => (
                                <React.Fragment>
                                    <strong>Diagnostico</strong>
                                    <div className='border-bottom pb-1'>{fam.diagnostico.label}</div>
                                    <strong>Familiar</strong>
                                    <div className='border-bottom pb-1'>{fam.tratamiento.label}</div>
                                </React.Fragment>
                            ))}

                            <strong>Notas</strong>
                            <div className='border-bottom pb-1'>{last_record.fam_note}</div>
                        </React.Fragment>
                        :
                                <span>No tiene historial previo en el sistema</span>
                        */}
                    </React.Fragment>
                }
                </div>
            </div>
        )
    }
}
