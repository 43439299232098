import { handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { initialize as initializeForm, change } from 'redux-form';
import { api, updateExpiracion } from "api";
import Swal from 'sweetalert2';
import moment, { lang } from "moment";

const LOADER = 'CONSULT_LOADER';
const SET_RECETA = 'SET_RECETA';

export const getDiagnosticPlan = (appointment_id) => (dispatch) =>{
  api.get(`consult/getDiagnosticPlan`, {appointment_id, marcar_inicio: true}).then((response) => {        
      let txn = []
      const dp_dpa_tx = response.dp_dpa.find(plan => plan.nombre == "PIVOTE")
      if(dp_dpa_tx){
          const newTdDp = dp_dpa_tx.td_dp.map(el => {
              return { 
                  ...el,
                  commercial_name: el.treatment.commercial_name,
                  active_principle: el.treatment.active_principle
              }
          })
          txn.push({ 'td_dp': newTdDp })
      }

      const dx = response.dp_dpa.filter(plan => plan.nombre !== "PIVOTE").map(f => { return { nombre: {value: f.id, label: f.nombre }}})
      
      const formData = { dx, txn, notes: response.notes }
      
      dispatch({type: SET_RECETA, receta: formData });      
  }).catch((error)=>{
      console.log("Error: ", error);
  });
}

export const actions = {  
  getDiagnosticPlan
};

export const reducers = {
  [LOADER]: (state, { loader }) => {
      return {
          ...state,
          loader,
      };
  },
  [SET_RECETA]: (state, { receta }) => {
      return {
          ...state,
          receta,
      };
  },   
};

export const initialState = {
  loader: false,  
  receta: null,  
};

export default handleActions(reducers, initialState);
