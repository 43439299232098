import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { validate, validators } from 'validate-redux-form';
import {renderSearchCreateSelect, renderField, renderSelectCustom} from "../../Utils/renderField";
import {api} from "../../../../api/api";
import {modalTextArea, render, renderRadioInput} from "../../Utils/renderField/renderField";
import { connect } from 'react-redux'
import CanvasDraw from "react-canvas-draw";
import { Canvas } from "../../Utils/renderField/canvas";

import '../Lamp/lamp_styles';
import './indirect_eye';

import { NervioOptico } from "../../Utils/Notations/Lamp/nervioOptico";

const getLenstypes = (search) => {
    return api.get("record", {search, record_type: 25})
        .then(results => results.results.map(item => {return {label: item.name, value: item.id}}))
        .catch(() => []);
};

//const Form = (props) => {
class Form extends Component{
    render(){
    const {
        handleSubmit,
        od_vitreo,
        os_vitreo,
        od_nervio_optico,
        os_nervio_optico,
        od_mascula,
        os_mascula,
        od_retina_periferica_select,
        os_retina_periferica_select,
    } = this.props;

    const view = window.location.href.includes("reporte");

    return (
        <form onSubmit={handleSubmit} className="d-flex flex-column">
            {!view ? <h4 className='cl-titulo'><strong>FONDO DE OJO INDIRECTO</strong></h4> : null}

            <div className="d-flex flex-column justify-content-center px-0 px-sm-2 px-md-3 px-lg-5 cl-content">



                <div className='d-flex flex-row justify-content-around w-100 mt-1'>
                    <div className='d-flex flex-column justify-content-start w-50 mt-2'>
                        <span className='cl-subtitulo'>
                            <span>OD</span>
                        </span>

                        <div className='d-flex flex-row justify-content-start cl-labels-list mt-4'>
                            <label className="radio-inline mr-3 d-flex align-items-center justify-content-end">
                                <span style={{fontSize:12}}>Vitreo</span>
                            </label>
                            <label className="radio-inline mr-3 d-flex align-items-center">
                                <Field
                                    id="od_vitreo1"
                                    name="od_vitreo"
                                    component={renderRadioInput}
                                    type="radio"
                                    value={"True"}
                                    disabled={view}
                                />
                                &nbsp;<span style={{fontSize:12}}>Ok</span>
                            </label>
                            <label className="radio-inline mr-3 d-flex align-items-center">
                                <Field
                                    id="od_vitreo2"
                                    name="od_vitreo"
                                    component={renderRadioInput}
                                    type="radio"
                                    value={"False"}
                                    disabled={view}
                                />
                                &nbsp;<span style={{fontSize:12}}>Diferido</span>
                            </label>
                            {/*
                            <label className="radio-inline mr-3 d-flex align-items-center">
                                <Field
                                    id="od_vitreo3"
                                    name="od_vitreo"
                                    component={renderRadioInput}
                                    type="radio"
                                    value={"None"}
                                    disabled={view}
                                />
                                &nbsp;<span style={{fontSize:12}}>Otro</span>
                            </label>
                            {od_vitreo && od_vitreo === 'None' &&
                                <label className="cl-mini-input">
                                    <Field name="od_vitreo_otro" component={renderField} disabled={view} type='text'/>
                                </label>
                            }
                             */}
                            <label className="radio-inline mr-3 d-flex align-items-center">
                                <Field
                                    id="od_vitreo3"
                                    name="od_vitreo"
                                    component={renderRadioInput}
                                    type="radio"
                                    value={"None"}
                                    disabled={view}
                                />
                                &nbsp;<span style={{fontSize:12}}>Otro</span>
                            </label>
                        </div>
                                                                        
                        {od_vitreo && od_vitreo === 'None' &&
                            <label className="d-flex flex-row justify-content-center" style={{ flexDirection: 'row'}}>
                                <Field name="od_vitreo_otro" component={modalTextArea} disabled={view} rows={2} cols={20} type='text'/>
                            </label>
                        } 

                        <div className='d-flex flex-row justify-content-start cl-labels-list'>
                            <label className="radio-inline mr-3 d-flex align-items-center justify-content-end">
                                <span style={{fontSize:12}}>Nervio óptico</span>
                            </label>
                            <label className="radio-inline mr-3 d-flex align-items-center">
                                <Field
                                    id="od_nervio_optico1"
                                    name="od_nervio_optico"
                                    component={renderRadioInput}
                                    type="radio"
                                    value={"True"}
                                    disabled={view}
                                />
                                &nbsp;<span style={{fontSize:12}}>Ok</span>
                            </label>
                            <label className="radio-inline mr-3 d-flex align-items-center">
                                <Field
                                    id="od_nervio_optico2"
                                    name="od_nervio_optico"
                                    component={renderRadioInput}
                                    type="radio"
                                    value={"False"}
                                    disabled={view}
                                />
                                &nbsp;<span style={{fontSize:12}}>Diferido</span>
                            </label>
                            {/*
                            <label className="radio-inline mr-3 d-flex align-items-center">
                                <Field
                                    id="od_nervio_optico3"
                                    name="od_nervio_optico"
                                    component={renderRadioInput}
                                    type="radio"
                                    value={"None"}
                                    disabled={view}
                                />
                                &nbsp;<span style={{fontSize:12}}>Otro</span>
                            </label>
                            {od_nervio_optico && od_nervio_optico === 'None' &&
                                <label className="cl-mini-input">
                                    <Field name="od_nervio_optico_otro" component={renderField} disabled={view} type='text'/>
                                </label>
                            }
                        */}

                            <label className="radio-inline mr-3 d-flex align-items-center">
                                <Field
                                    id="od_nervio_optico3"
                                    name="od_nervio_optico"
                                    component={renderRadioInput}
                                    type="radio"
                                    value={"None"}
                                    disabled={view}
                                />
                                &nbsp;<span style={{fontSize:12}}>Otro</span>
                            </label>                        
                        </div>                                                                        
                        {od_nervio_optico && od_nervio_optico === 'None' &&
                                <label className="d-flex flex-row justify-content-center" style={{ flexDirection: 'row'}}>
                                    <Field name="od_nervio_optico_otro" component={modalTextArea} disabled={view} rows={2} cols={20} type='text'/>
                                </label>
                            }                        
                        <div className='d-flex flex-row justify-content-start cl-labels-list '>
                            <label className="radio-inline mr-3 d-flex align-items-center">
                                <span style={{fontSize:12}}>Mácula</span>
                            </label>
                            <label className="radio-inline mr-3 d-flex align-items-center">
                                <Field
                                    id="od_mascul1"
                                    name="od_mascula"
                                    component={renderRadioInput}
                                    type="radio"
                                    value={"True"}
                                    disabled={view}
                                />
                                &nbsp;<span style={{fontSize:12}}>Ok</span>
                            </label>
                            <label className="radio-inline mr-3 d-flex align-items-center">
                                <Field
                                    id="od_mascul2"
                                    name="od_mascula"
                                    component={renderRadioInput}
                                    type="radio"
                                    value={"False"}
                                    disabled={view}
                                />
                                &nbsp;<span style={{fontSize:12}}>Diferido</span>
                            </label>
                            {/*
                            <label className="radio-inline mr-3 d-flex align-items-center">
                                <Field
                                    id="od_mascula3"
                                    name="od_mascula"
                                    component={renderRadioInput}
                                    type="radio"
                                    value={"None"}
                                    disabled={view}
                                />
                                &nbsp;<span style={{fontSize:12}}>Otro</span>
                            </label>
                            {od_mascula && od_mascula === 'None' &&
                                <label className="cl-mini-input">
                                    <Field name="od_mascula_otro" component={renderField} disabled={view} type='text'/>
                                </label>
                            }
                        */}
                            <label className="radio-inline mr-3 d-flex align-items-center">
                                <Field
                                    id="od_mascula3"
                                    name="od_mascula"
                                    component={renderRadioInput}
                                    type="radio"
                                    value={"None"}
                                    disabled={view}
                                />
                                &nbsp;<span style={{fontSize:12}}>Otro</span>
                            </label>                        
                        </div>                                                                            
                        {od_mascula && od_mascula === 'None' &&
                                <label className="d-flex flex-row justify-content-center" style={{ flexDirection: 'row'}}>
                                    <Field name="od_mascula_otro"  component={modalTextArea} disabled={view} rows={2} cols={20} type='text'/>
                                </label>
                            }                                          
                        <div className='d-flex flex-row justify-content-start cl-labels-list '>
                            <label className="radio-inline mr-3 d-flex align-items-center">
                                <span style={{fontSize:12}}>Retina Periférica</span>
                            </label>
                            <label className="radio-inline mr-3 d-flex align-items-center">
                                <Field
                                    id="od_retina_periferica_select_1"
                                    name="od_retina_periferica_select"
                                    component={renderRadioInput}
                                    type="radio"
                                    value={"True"}
                                    disabled={view}
                                />
                                &nbsp;<span style={{fontSize:12}}>Ok</span>
                            </label>
                            <label className="radio-inline mr-3 d-flex align-items-center">
                                <Field
                                    id="od_retina_periferica_select_2"
                                    name="od_retina_periferica_select"
                                    component={renderRadioInput}
                                    type="radio"
                                    value={"False"}
                                    disabled={view}
                                />
                                &nbsp;<span style={{fontSize:12}}>Diferido</span>
                            </label>
                            {/*
                            <label className="radio-inline mr-3 d-flex align-items-center">
                                <Field
                                    id="od_retina_periferica_select_3"
                                    name="od_retina_periferica_select"
                                    component={renderRadioInput}
                                    type="radio"
                                    value={"None"}
                                    disabled={view}
                                />
                                &nbsp;<span style={{fontSize:12}}>Otro</span>
                            </label>
                            {od_retina_periferica_select && od_retina_periferica_select === 'None' &&
                                <label className="cl-mini-input">
                                    <Field name="od_retina_periferica_otro_select" component={renderField} disabled={view} type='text'/>
                                </label>
                            }
                            */}                           

                            <label className="radio-inline mr-3 d-flex align-items-center">
                                <Field
                                    id="od_retina_periferica_select_3"
                                    name="od_retina_periferica_select"
                                    component={renderRadioInput}
                                    type="radio"
                                    value={"None"}
                                    disabled={view}
                                />
                                &nbsp;<span style={{fontSize:12}}>Otro</span>
                            </label>
                        </div>
                                                                            
                        {od_retina_periferica_select && od_retina_periferica_select === 'None' &&
                                <label className="d-flex flex-row justify-content-center" style={{ flexDirection: 'row'}}>
                                    <Field name="od_retina_periferica_otro_select" component={modalTextArea} disabled={view} rows={2} cols={20} type='text'/>
                                </label>
                            }                                           
                    </div>

                    <div className='d-flex flex-column justify-content-start w-50 mt-2'>
                        <span className='cl-subtitulo'>
                            <span>OS</span>
                        </span>
                        <div className='d-flex flex-row justify-content-start cl-labels-list mt-4'>
                            <label className="radio-inline mr-3 d-flex align-items-center">
                                <span style={{fontSize:12}}>Vitreo</span>
                            </label>
                            <label className="radio-inline mr-3 d-flex align-items-center">
                                <Field
                                    id="os_vitre1"
                                    name="os_vitreo"
                                    component={renderRadioInput}
                                    type="radio"
                                    value={"True"}
                                    disabled={view}
                                />
                                &nbsp;<span style={{fontSize:12}}>Ok</span>
                            </label>
                            <label className="radio-inline mr-3 d-flex align-items-center">
                                <Field
                                    id="os_vitre2"
                                    name="os_vitreo"
                                    component={renderRadioInput}
                                    type="radio"
                                    value={"False"}
                                    disabled={view}
                                />
                                &nbsp;<span style={{fontSize:12}}>Diferido</span>
                            </label>
                            {/*
                            <label className="radio-inline mr-3 d-flex align-items-center">
                                <Field
                                    id="os_vitre3"
                                    name="os_vitreo"
                                    component={renderRadioInput}
                                    type="radio"
                                    value={"None"}
                                    disabled={view}
                                />
                                &nbsp;<span style={{fontSize:12}}>Otro</span>
                            </label>
                            {os_vitreo && os_vitreo === 'None' &&
                                <label className="cl-mini-input">
                                    <Field name="os_vitreo_otro" component={renderField} disabled={view} type='text'/>
                                </label>
                            }
                            */}
                            <label className="radio-inline mr-3 d-flex align-items-center">
                                <Field
                                    id="os_vitre3"
                                    name="os_vitreo"
                                    component={renderRadioInput}
                                    type="radio"
                                    value={"None"}
                                    disabled={view}
                                />
                                &nbsp;<span style={{fontSize:12}}>Otro</span>
                            </label>                                                          
                        </div>
                                                                        
                        {os_vitreo && os_vitreo === 'None' &&
                                <label className="d-flex flex-row justify-content-center" style={{ flexDirection: 'row'}}>
                                    <Field name="os_vitreo_otro" component={modalTextArea} disabled={view} rows={2} cols={20} type='text'/>
                                </label>
                            } 

                        <div className='d-flex flex-row justify-content-start cl-labels-list '>
                            <label className="radio-inline mr-3 d-flex align-items-center">
                                <span style={{fontSize:12}}>Nervio óptico</span>
                            </label>
                            <label className="radio-inline mr-3 d-flex align-items-center">
                                <Field
                                    id="os_nervio_optico_1"
                                    name="os_nervio_optico"
                                    component={renderRadioInput}
                                    type="radio"
                                    value={"True"}
                                    disabled={view}
                                />
                                &nbsp;<span style={{fontSize:12}}>Ok</span>
                            </label>
                            <label className="radio-inline mr-3 d-flex align-items-center">
                                <Field
                                    id="os_nervio_optico_2"
                                    name="os_nervio_optico"
                                    component={renderRadioInput}
                                    type="radio"
                                    value={"False"}
                                    disabled={view}
                                />
                                &nbsp;<span style={{fontSize:12}}>Diferido</span>
                            </label>
                            {/*
                            <label className="radio-inline mr-3 d-flex align-items-center">
                                <Field
                                    id="os_nervio_optico_3"
                                    name="os_nervio_optico"
                                    component={renderRadioInput}
                                    type="radio"
                                    value={"None"}
                                    disabled={view}
                                />
                                &nbsp;<span style={{fontSize:12}}>Otro</span>
                            </label>
                            {os_nervio_optico && os_nervio_optico === 'None' &&
                                <label className="cl-mini-input">
                                    <Field name="os_nervio_optico_otro" component={renderField} disabled={view} type='text'/>
                                </label>
                            }
                            */}
                            <label className="radio-inline mr-3 d-flex align-items-center">
                                <Field
                                    id="os_nervio_optico_3"
                                    name="os_nervio_optico"
                                    component={renderRadioInput}
                                    type="radio"
                                    value={"None"}
                                    disabled={view}
                                />
                                &nbsp;<span style={{fontSize:12}}>Otro</span>
                            </label>                            
                        </div>
                                                                            
                        {os_nervio_optico && os_nervio_optico === 'None' &&
                                <label className="d-flex flex-row justify-content-center" style={{ flexDirection: 'row'}}>
                                    <Field name="os_nervio_optico_otro" component={modalTextArea} disabled={view} rows={2} cols={20} type='text'/>
                                </label>
                            }

                        <div className='d-flex flex-row justify-content-start cl-labels-list '>
                            <label className="radio-inline mr-3 d-flex align-items-center">
                                <span style={{fontSize:12}}>Mácula</span>
                            </label>
                            <label className="radio-inline mr-3 d-flex align-items-center">
                                <Field
                                    id={'os_mascula_1'}
                                    name="os_mascula"
                                    component={renderRadioInput}
                                    type="radio"
                                    value={"True"}
                                    disabled={view}
                                />
                                &nbsp;<span style={{fontSize:12}}>Ok</span>
                            </label>
                            <label className="radio-inline mr-3 d-flex align-items-center">
                                <Field
                                    id={'os_mascula_2'}
                                    name="os_mascula"
                                    component={renderRadioInput}
                                    type="radio"
                                    value={"False"}
                                    disabled={view}
                                />
                                &nbsp;<span style={{fontSize:12}}>Diferido</span>
                            </label>
                            {/*
                            <label className="radio-inline mr-3 d-flex align-items-center">
                                <Field
                                    id={'os_mascula_3'}
                                    name="os_mascula"
                                    component={renderRadioInput}
                                    type="radio"
                                    value={"None"}
                                    disabled={view}
                                />
                                &nbsp;<span style={{fontSize:12}}>Otro</span>
                            </label>
                            {os_mascula && os_mascula === 'None' &&
                                <label className="cl-mini-input">
                                    <Field name="os_mascula_otro" component={renderField} disabled={view} type='text'/>
                                </label>
                            }
                            */}

                            <label className="radio-inline mr-3 d-flex align-items-center">
                                <Field
                                    id={'os_mascula_3'}
                                    name="os_mascula"
                                    component={renderRadioInput}
                                    type="radio"
                                    value={"None"}
                                    disabled={view}
                                />
                                &nbsp;<span style={{fontSize:12}}>Otro</span>
                            </label>
                        </div>                                                                        
                        {os_mascula && os_mascula === 'None' &&
                                <label className="d-flex flex-row justify-content-center" style={{ flexDirection: 'row'}}>
                                    <Field name="os_mascula_otro" component={modalTextArea} disabled={view} rows={2} cols={20} type='text'/>
                                </label>
                            } 

                        <div className='d-flex flex-row justify-content-start cl-labels-list '>
                            <label className="radio-inline mr-3 d-flex align-items-center">
                                <span style={{fontSize:12}}>Retina Periférica</span>
                            </label>
                            <label className="radio-inline mr-3 d-flex align-items-center">
                                <Field
                                    id={'os_retina_periferica_select_1'}
                                    name="os_retina_periferica_select"
                                    component={renderRadioInput}
                                    type="radio"
                                    value={"True"}
                                    disabled={view}
                                />
                                &nbsp;<span style={{fontSize:12}}>Ok</span>
                            </label>
                            <label className="radio-inline mr-3 d-flex align-items-center">
                                <Field
                                    id={'os_retina_periferica_select_2'}
                                    name="os_retina_periferica_select"
                                    component={renderRadioInput}
                                    type="radio"
                                    value={"False"}
                                    disabled={view}
                                />
                                &nbsp;<span style={{fontSize:12}}>Diferido</span>
                            </label>
                            {/*
                            <label className="radio-inline mr-3 d-flex align-items-center">
                                <Field
                                    id={'os_retina_periferica_select_3'}
                                    name="os_retina_periferica_select"
                                    component={renderRadioInput}
                                    type="radio"
                                    value={"None"}
                                    disabled={view}
                                />
                                &nbsp;<span style={{fontSize:12}}>Otro</span>
                            </label>
                            {os_retina_periferica_select && os_retina_periferica_select === 'None' &&
                                <label className="cl-mini-input">
                                    <Field name="os_retina_periferica_otro_select" component={renderField} disabled={view} type='text'/>
                                </label>
                            }
                            */}

                            <label className="radio-inline mr-3 d-flex align-items-center">
                                <Field
                                    id={'os_retina_periferica_select_3'}
                                    name="os_retina_periferica_select"
                                    component={renderRadioInput}
                                    type="radio"
                                    value={"None"}
                                    disabled={view}
                                />
                                &nbsp;<span style={{fontSize:12}}>Otro</span>
                            </label>                            
                        </div>                        
                        {os_retina_periferica_select && os_retina_periferica_select === 'None' &&
                                <label className="d-flex flex-row justify-content-center" style={{ flexDirection: 'row'}}>
                                    <Field name="os_retina_periferica_otro_select" component={modalTextArea} disabled={view} rows={2} cols={20} type='text'/>
                                </label>
                            }              
                    </div>
                </div>

                <div className='d-flex flex-row justify-content-center w-100 mt-3'>
                    <div className='d-flex flex-column justify-content-center align-items-center w-50 mt-2'>
                        <div className='d-flex flex-column'>
                            <Field
                                name="od_retina_periferica"
                                component={Canvas}
                                view={view}
                                imgSrc={require('../../../../../assets/img/icons/ojo-izquierdo-ramas-azul.png')}
                             />
                        </div>
                    </div>
                    <div className='d-flex flex-column justify-content-center align-items-center w-50 mt-2'>
                        <div className='d-flex flex-column'>
                            <Field
                                name="os_retina_periferica"
                                component={Canvas}
                                view={view}
                                imgSrc={require('../../../../../assets/img/icons/ojo-derecho-ramas-azul.png')}
                            />
                        </div>
                    </div>
                </div>

                <div className='d-flex flex-row justify-content-center'>
                    <div className="mb-md-4 d-flex justify-content-between flex-column flex-sm-row align-items-stretch align-items-sm-center mt-4 w-50">
                        <div className="m-2 d-flex flex-column w-100">
                            <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                <label htmlFor="notes">Notas</label>
                            </div>
                            <div className='d-flex flex-row'>
                                <label className="radio-inline mr-3 d-flex align-items-center">
                                    OD&nbsp;
                                    <Field
                                        id="type_1"
                                        name="type"
                                        component={renderRadioInput}
                                        type="radio"
                                        value={"1"}
                                        disabled={view}
                                    />
                                </label>
                                <label className="radio-inline mr-3 d-flex align-items-center">
                                    OS&nbsp;
                                    <Field
                                        id="type_2"
                                        name="type"
                                        component={renderRadioInput}
                                        type="radio"
                                        value={"2"}
                                        disabled={view}
                                    />
                                </label>
                                <label className="radio-inline mr-3 d-flex align-items-center">
                                    OU&nbsp;
                                    <Field
                                        id="type_3"
                                        name="type"
                                        component={renderRadioInput}
                                        type="radio"
                                        value={"3"}
                                        disabled={view}
                                    />
                                </label>
                            </div>
                            <div className="d-flex flex-row w-100">
                                <div className="d-flex flex-row w-75">
                                <Field
                                    name="notes"
                                    component={modalTextArea}
                                    className="annotations"
                                    disabled={view}
                                />
                                </div>
                                <div className='d-flex flex-row w-25 ml-3'>
                                    <button
                                        type="button"
                                        className='btn btn-danger'
                                        onClick={()=>{this.props.change('notes', 'DIFERIDO');}}
                                    >DIFERIDO</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    )
    }
};

let CrearForm = reduxForm({
    // a unique name for the form
    form: 'IndirectEye',
    validate: (data) => {
        return validate(data, {
        });
    },
})(Form);

const selector = formValueSelector('IndirectEye');

CrearForm = connect(state => {
    const od_vitreo = selector(state, "od_vitreo");
    const os_vitreo = selector(state, "os_vitreo");
    const od_nervio_optico = selector(state, "od_nervio_optico");
    const os_nervio_optico = selector(state, "os_nervio_optico");
    const od_mascula = selector(state, "od_mascula");
    const os_mascula = selector(state, "os_mascula");
    const od_retina_periferica_select = selector(state, "od_retina_periferica_select");
    const os_retina_periferica_select = selector(state, "os_retina_periferica_select");

    return {
        od_vitreo,
        os_vitreo,
        od_nervio_optico,
        os_nervio_optico,
        od_mascula,
        os_mascula,
        od_retina_periferica_select,
        os_retina_periferica_select,
    };

})(CrearForm);

export default CrearForm;
