import React from 'react';
import {
    Route,
    Switch,
    Redirect,
}

from 'react-router-dom';
import Home from './common/components/Home';
import Login from './common/components/Login/LoginContainer';

import Privado from './common/components/Privado/Privado';

// Users
import Users from './common/components/Users/UsersContainer';
import UsersCreate from './common/components/Users/UsersContainerCrear';

// Interpreter
import Interpreter from './common/components/Interpreter/InterpreterContainer';
import InterpreterCreate from './common/components/Interpreter/InterpreterContainerCrear';

//Roles
import Roles from './common/components/Roles/RolesContainer';
import RolesCreate from './common/components/Roles/RolesContainerCrear';

// Bank Accounts
import BankAccounts from './common/components/BankAccounts/BankAccountsContainer';
import BankAccountsCrear from './common/components/BankAccounts/BankAccountsContainerCrear';

// POS
import Pos from './common/components/Pos/PosContainer';
import PosCreate from './common/components/Pos/PosContainerCreate';

// Banks
import Banks from './common/components/Banks/BanksContainer';
import BankTransaction from './common/components/Banks/BankTransactionsContainer';
import Pending from './common/components/Banks/PendingsContainer';

// Services
import Services from './common/components/Services/ServicesContainer';
import ServicesCrear from './common/components/Services/ServicesContainerCrear';

// Igss Procedures
import IgssProcedures from './common/components/igssProcedures/IgssProceduresContainer';
import IgssProceduresCrear from './common/components/igssProcedures/IgssProceduresContainerCrear';

// Igss Forms
import IgssForms from './common/components/igss-forms/IgssFormsContainer'
import IgssFormsCrear from './common/components/igss-forms/IgssFormsContainerCrear'
import IgssOldFormContainer from './common/components/igss-forms/igssOldFormContainer.js';

// Cash Boxes
import CashBoxes from './common/components/CashBoxes/CashBoxesContainer';
import CashBoxesCrear from './common/components/CashBoxes/CashBoxesContainerCrear';

// Referents
import Referent from './common/components/Referent/ReferentContainer';
import ReferentCrear from './common/components/Referent/ReferentContainerCrear';

// Subsidiaries
import Subsidiary from './common/components/Subsidiary/SubsidiaryContainer';
import SubsidiaryCrear from './common/components/Subsidiary/SubsidiaryContainerCrear';

// Referent Categories
import ReferentCategory from './common/components/ReferentCategory/ReferentCategoryContainer';
import ReferentCategoryCrear from './common/components/ReferentCategory/ReferentCategoryContainerCrear';

// Institution
import Institution from './common/components/Institution/InstitutionContainer';
import InstitutionCrear from './common/components/Institution/InstitutionContainerCrear';

// Services Categories
import ServiceCategory from './common/components/ServiceCategory/ServiceCategoryContainer';
import ServiceCategoryCrear from './common/components/ServiceCategory/ServiceCategoryContainerCrear';

// Patients
import Patient from './common/components/Patients/PatientContainer';
import PatientCreate from './common/components/Patients/PatientContainerCreate';

// Oppening Closing Box
import OppeningClosingBox from './common/components/OppeningClosingBox/OppeningClosingBoxContainer';
import OpenBox from './common/components/OppeningClosingBox/OpenBoxContainer';
import CloseBox from './common/components/OppeningClosingBox/closeBoxContainer';

import OpeningBox from './common/components/OppeningClosingBox/openingBoxContainer';
import ViewBillContainer from './common/components/OppeningClosingBox/ViewBillContainer';

import Doctor from './common/components/Doctors/DoctorContainer';
import DoctorCreate from './common/components/Doctors/DoctorContainerCrear';

// Schedule & Appointments
import Schedule from './common/components/Schedule/ScheduleContainer';
import Appointments from './common/components/Appointments/AppointmentsContainer';
import AppointmentsCreate from './common/components/Appointments/AppointmentsContainerCreate';
import AppointmentsWeekCreate from './common/components/Appointments/AppointmentsWeekContainerCreate';
import ScheduleLock from './common/components/Schedule/ScheduleLockContainer';
import ScheduleLockWeek from './common/components/Schedule/ScheduleLockWeekContainer';

import AppointmentsToday from './common/components/Appointments/AppointmentsContainerTodayCreate';

// Sales
import Sales from './common/components/sales/SalesContainer';
import SalesService from './common/components/sales/ServicesContainer';
import SalesPayment from './common/components/sales/PaymentContainer';
import SalesDeposit from './common/components/sales/deposit/depositContainer';

//Configuration
import Configuration from './common/components/configuration/configurationContainer';

//Dues
import Dues from './common/components/dues/DuesContainer';
import DuesCrear from './common/components/dues/DuesCreateContainer';

//Financial Entities
import FinancialEntity from './common/components/FinancialEntity/FinancialEntityContainer';
import FinancialEntityCreate from './common/components/FinancialEntity/EntityCrearContainer'

//Coupon
import Coupon from './common/components/Coupons/CouponContainter';
import CouponCreate from './common/components/Coupons/CouponCrearContainer';
import CouponView from "./common/components/Coupons/CouponViewContainer";

import ProtectedRoute from './ProtectedRoute';

import '../assets/fonts/fonts.css';

import SalesContainer from './common/components/sales/SalesContainer';
import ViewFinishSale from './common/components/sales/viewFinishSaleContainer';

//Bodega
import Whearhouse from './common/components/whearhouse/WhearhouseContainer';
import WhearhouseCreate from './common/components/whearhouse/WhearhouseContainerCrear';

//Administración de bodega
import WhearhouseAdmin from './common/components/whearhouseAdmin/WhearhouseContainer';
import WhearhouseInventory from './common/components/whearhouse/administration/mainContainer';
import IngresoContainer from './common/components/whearhouse/administration/ingresoContainer';
import ReajusteContainer from './common/components/whearhouse/reajuste.js/ReajusteContainer';
import BajaContainer from './common/components/whearhouse/baja/BajaContainer';

//Administración de Proveedores
import Provider from './common/components/Provider/ProviderContainter';
import ProviderCreate from './common/components/Provider/ProviderContainerCrear';

import MovementContainer from './common/components/Movements/MovementContainer';
import ViewBillMovementContainer from "./common/components/Movements/ViewBillContainer";

//Payments History
import PaymentsHistory from './common/components/Payments/paymentContainer';

//Cuentas por cobrar
import CuentasPorCobrar from './common/components/Receivable/ReceivableContainer';

//Ordenes de compra
import Ordenes from './common/components/purchase_order/OrdersContainer';
import NuevaOrden from './common/components/purchase_order/order/OrderContainer';

import OrdenesAdm from './common/components/purchase_order/OrderAdmContainer';
import AuthorizationOrder from './common/components/purchase_order/administration/OrderContainer';

import EntryOrder from './common/components/purchase_order/EntryContainer';
import Entry from "./common/components/purchase_order/entry/OrderContainer";

//Listado de cuentas por pagar
import ToPay from "./common/components/ToPay/ToPayContainer";

//Detalle de cuenta por pagar
import ViewEntryOrder from "./common/components/Reports/ToPay/ViewEntryContainer";

//Historial de pagos realizados
import EntryHistoryPayments from "./common/components/Reports/ToPay/HistoryPaymentContainer";

// Small Boxes
import SmallBox from './common/components/Smallbox/SmallBoxContainer';
import SmallBoxCrear from './common/components/Smallbox/SmallBoxCrearContainer';

// Spending Types
import SpendingType from './common/components/SpendingTypes/SpendingTypeContainer';
import SpendingTypeCrear from './common/components/SpendingTypes/SpendingTypeCrearContainer';

// Expense
import Expense from './common/components/Expenses/ExpensesContainer';
import ExpenseCrear from './common/components/Expenses/ExpensesCrearContainer';

// Small Boxes Admin
import SmallBoxAdm from './common/components/SmallBoxAdm/SmallBoxContainer';
import SmallBoxAdmMain from './common/components/SmallBoxAdm/mainContainer';

// Payment Retention
import PaymentRetention from './common/components/RetentionPayments/RetentionPaymentContainer';

// Surgery Appointments
import ScheduleSurgery from './common/components/scheduleSurgery/ScheduleContainer';
import Surgery from './common/components/surgery/SurgeryContainer';
import SurgeryCreate from './common/components/surgery/SurgeryContainerCreate';

//Bloqueo
import SurgeryScheduleLock from './common/components/scheduleSurgery/ScheduleLockContainer';
import PerformSurgery from './common/components/surgery/performSurgeryContainer';

//Detalle Cirugía
import SurgeryDetail from './common/components/surgery/surgery_detail/SurgeryDetailContainer';
import PDF from './common/components/surgery/surgery_detail/PDF/index';

// Reports
import Payback from './common/components/payback/PaybackContainer';
import PaybackPaid from './common/components/payback/PaybackPaidContainer';

// Arqueos
import Arqueo from './common/components/arqueos/ArqueoContainer.js';
import DetalleArqueo from  './common/components/arqueos/DetalleArqueoContainer.js';

//New Arqueo for dashboard
import NewDasboard from  './common/components/arqueosNew/DetalleArqueoContainer.js';

import Tasks from "./common/components/tasks/TasksContainer.js";

// cirugías de hoy
import TodaysSurgery from './common/components/todaySurgery/TodaysSurgeryContainter';
// surgery report
import SurgeryReport from './common/components/todaySurgery/SurgeryReportContainer';
// Profile
import Profile from './common/components/Profile/ProfileContainer';
// Consult
import Lensometry from "./common/components/Consult/Lensometry/Lensometry";
import {
    ConsultList,
    AutorefractorList, AutorefractorExam,
    IndirectEyeList, IndirectEyeExam,
    KeratometryList, KeratometryExam,
    LampList, LampExam,
    LensometryList, LensometryExam,
    MuscleList, MuscleExam,
    RefractionList, RefractionExam,
    TonometryList, TonometryExam,
    VisualAcuityList, VisualAcuityExam,
    RecordList, RecordExam, EducationalPlanList,
    EducationalPlanExam, DiagnosticPlanList,
    DiagnosticPlanExam, ReconsultList,
    ReconsultExam, OpticsOrderList, OpticsOrderExam
} from "./common/components/Consult";

import DiagnosticOrder from './common/components/DiagnosticOrder/DiagnosticOrderContainer';
import DiagnosticContainerCreate from './common/components/DiagnosticOrder/DiagnosticContainerCreate';

import ExamOrder from './common/components/DiagnosticOrder/ExamOrderContainer';

import DiagnosticQueue from './common/components/DiagnosticOrder/DiagnosticQueue/DiagnosticQueueContainer';
import DiagnosticCompleted from './common/components/DiagnosticOrder/DiagnosticCompleted/DiagnosticCompletedContainer';
import ResumeDiagnostic from './common/components/DiagnosticOrder/DiagnosticResume/DiagnoticResumeContainer';
import DiagnosticTranscription from './common/components/DiagnosticOrder/DiagnosticTranscription/DiagnosticTranscriptionContainer';

// surgery fee
import Fee from './common/components/surgery/fee/FeeContainer';
import FeePaid from './common/components/surgery/fee/FeePaidContainer';

//Planes
import PlanesExamen from './common/components/Consult/Plan/ExamenContainer';
import PlanesProcedimiento from './common/components/Consult/Plan/ProcedimientoContainer';
import PlanesPaquete from './common/components/Consult/Plan/PaqueteContainer';
import PlanCrear from './common/components/Consult/Plan/PlanCrearContainer';


// Record
import {
    RecordsList,
    RecordCreate,
    RecordEdit,
    Show
} from './common/components/Record';

import MedicalHistory from './common/components/MedicalHistory/MedicalHistoryContainer';

//REPORTES
import ReporteIngreso from './common/components/Reports/Bodega/IngresoBodegaContainer';
import ReporteBaja from './common/components/Reports/Bodega/BajaBodegaContainer';
import ReporteAjuste from './common/components/Reports/Bodega/AjusteBodegaContainer';

//REPORTES DETALLES
import ReporteDetallesIngreso from './common/components/Reports/Bodega/DetalleIngresoBodegaContainer';
import ReporteDetallesBajas from './common/components/Reports/Bodega/DetalleBajasBodegaContainer';
import ReporteDetallesAjustes from './common/components/Reports/Bodega/DetalleAjusteBodegaContainer';

//REPORTE DE CONSULTAS
import ReporteConsultas from './common/components/Reports/Consulta/ConsultaContainer';
import ReporteDetalleConsultas from './common/components/Reports/Consulta/DetalleConsultaContainer';
import TiemposConsulta from "./common/components/Reports/TiemposConsulta";

//REPORTE DE PRODUCTOS EN CONSIGNACIÓN
import ReporteConsignacion from "./common/components/Reports/ProductosConsignment/ProductosConsignmentContainer";
import ReporteEntradaProducto from "./common/components/Reports/ProductosConsignment/entryMovementReportContainer";

import ReporteCuentasAbiertas from "./common/components/Reports/openAccounts/openAccountsContainer";
import ReporteCuentasAbiertasIgss from "./common/components/Reports/igss/openAccountsContainer.js";
import ReporteConsolidado from "./common/components/Reports/consolidado/ConsolidadoContainer";
import ReporteConsolidadoGastos from "./common/components/Reports/consolidado-gastos/ConsolidadoContainer";

import ReporteGastos from "./common/components/Reports/gastos/GastosContainer";

// BITACORA
import Bitacora from "./common/components/Bitacora";
import { DetalleBitacora } from "./common/components/Bitacora";

// REPORTE DE PROMEDIO DE PROCEDIMIENTOS
import ProcedimientosPromedioContainer from './common/components/Reports/ProcedimientosPromedio/ProcedimientosPromedioContainer';

// REPORTE DE PAGOS HECHOS DE FORMA GRUPAL (CUENTAS POR PAGAR)
import PagosGrupales from "./common/components/Reports/PagosGrupales/PagosGrupalesContainer";

import Treatments from "./common/components/Treatment/TreatmentsContainer";
import TreatmentsCreate from "./common/components/Treatment/TreatmentsContainerCrear"

import RecipePrint from "./common/components/Consult/PDF/RecipeContainer.js";
import ConsultSheetPrint from "./common/components/Consult/PDF/ConsultSheetContainer.js";

require('../../node_modules/font-awesome/css/font-awesome.css');
require('../../node_modules/bootstrap/dist/css/bootstrap.css');

require('../../node_modules/pure-react-carousel/dist/react-carousel.es.css');

require('../style/index.css');

// Componente para setear el scroll hasta la parte superior de cada pagina
import ScrollToTop from './ScrollToTop'

module.exports = (
    <div>
        <ScrollToTop />

        <div className="container__content">
            <Switch>

                <Route exact path="/login" component={Login}/>

                {/*<ProtectedRoute exact path="/lensometry" component={Lensometry}/>*/}
                <ProtectedRoute exact path="/query-queue" component={ConsultList}/>
                <ProtectedRoute exact path="/autorefractor" component={AutorefractorList}/>
                <ProtectedRoute exact path="/autorefractor/:id" component={AutorefractorExam}/>
                <ProtectedRoute exact path="/indirect_eye" component={IndirectEyeList}/>
                <ProtectedRoute exact path="/indirect_eye/:id" component={IndirectEyeExam}/>
                <ProtectedRoute exact path="/keratometry" component={KeratometryList}/>
                <ProtectedRoute exact path="/keratometry/:id" component={KeratometryExam}/>
                <ProtectedRoute exact path="/lamp" component={LampList}/>
                <ProtectedRoute exact path="/lamp/:id" component={LampExam}/>
                <ProtectedRoute exact path="/lensometry" component={LensometryList}/>

                <ProtectedRoute exact path="/lensometry/:id" component={LensometryExam}/>
                <ProtectedRoute exact path="/muscle" component={MuscleList}/>
                <ProtectedRoute exact path="/muscle/:id" component={MuscleExam}/>
                <ProtectedRoute exact path="/refraction" component={RefractionList}/>
                <ProtectedRoute exact path="/refraction/:id" component={RefractionExam}/>
                <ProtectedRoute exact path="/tonometry" component={TonometryList}/>
                <ProtectedRoute exact path="/tonometry/:id" component={TonometryExam}/>
                <ProtectedRoute exact path="/visual_acuity" component={VisualAcuityList}/>
                <ProtectedRoute exact path="/visual_acuity/:id" component={VisualAcuityExam}/>

                <ProtectedRoute exact path="/record" component={RecordList}/>
                <ProtectedRoute exact path="/record/:id" component={RecordExam}/>

                <ProtectedRoute exact path="/reconsult" component={ReconsultList}/>
                <ProtectedRoute exact path="/reconsult/:id" component={ReconsultExam}/>

                <ProtectedRoute exact path="/educational-plan" component={EducationalPlanList}/>
                <ProtectedRoute exact path="/educational-plan/:id" component={EducationalPlanExam}/>

                <ProtectedRoute exact path="/diagnostic-plan" component={DiagnosticPlanList}/>
                <ProtectedRoute exact path="/diagnostic-plan/:id" component={DiagnosticPlanExam}/>

                <ProtectedRoute exact path="/optics-order" component={OpticsOrderList}/>
                <ProtectedRoute exact path="/optics-order/:id" component={OpticsOrderExam}/>

                <ProtectedRoute exact path="/igss-procedures/create" component={IgssProceduresCrear}/>
                <ProtectedRoute exact path="/igss-procedures/:id/edit" component={IgssProceduresCrear}/>
                <ProtectedRoute exact path="/igss-procedures/:id/view" component={IgssProceduresCrear}/>
                <ProtectedRoute exact path="/igss-procedures" component={IgssProcedures}/>
                
                <ProtectedRoute exact path="/igss-forms/old-create" component={IgssOldFormContainer}/>
                <ProtectedRoute exact path="/igss-forms/create" component={IgssFormsCrear}/>
                <ProtectedRoute exact path="/igss-forms/:id/edit" component={IgssFormsCrear}/>
                <ProtectedRoute exact path="/igss-forms/:id/view" component={IgssFormsCrear}/>
                <ProtectedRoute exact path="/igss-forms" component={IgssForms}/>

                <ProtectedRoute exact path="/products" component={Services}/>
                <ProtectedRoute exact path="/products/create" component={ServicesCrear}/>
                <ProtectedRoute exact path="/products/:id/edit" component={ServicesCrear}/>
                <ProtectedRoute exact path="/products/:id/view" component={ServicesCrear}/>

                <ProtectedRoute exact path="/services_conf" component={Services}/>
                <ProtectedRoute exact path="/services_conf/create" component={ServicesCrear}/>
                <ProtectedRoute exact path="/services_conf/:id/edit" component={ServicesCrear}/>
                <ProtectedRoute exact path="/services_conf/:id/view" component={ServicesCrear}/>

                <ProtectedRoute exact path="/users" component={Users} />
                <ProtectedRoute exact path="/users/create" component={UsersCreate}/>
                <ProtectedRoute exact path="/users/:id/edit" component={UsersCreate}/>
                <ProtectedRoute exact path="/users/:id/view" component={UsersCreate}/>

                {/* Interpreter */}
                <ProtectedRoute exact path="/interpreter" component={Interpreter} />
                <ProtectedRoute exact path="/interpreter/create" component={InterpreterCreate}/>
                <ProtectedRoute exact path="/interpreter/:id/edit" component={InterpreterCreate}/>
                <ProtectedRoute exact path="/interpreter/:id/view" component={InterpreterCreate}/>

                <ProtectedRoute exact path="/roles/:id/view" component={RolesCreate}/>
                <ProtectedRoute exact path="/roles/:id/edit" component={RolesCreate}/>
                <ProtectedRoute exact path="/roles/create" component={RolesCreate} />
                <ProtectedRoute excat path="/roles" component={Roles} />

                <ProtectedRoute exact path="/pos" component={Pos}/>
                <ProtectedRoute exact path="/pos/create" component={PosCreate}/>
                <ProtectedRoute exact path="/pos/:id/edit" component={PosCreate}/>
                <ProtectedRoute exact path="/pos/:id/view" component={PosCreate}/>

                <ProtectedRoute exact path="/bank-accounts" component={BankAccounts}/>
                <ProtectedRoute exact path="/bank-accounts/create" component={BankAccountsCrear}/>
                <ProtectedRoute exact path="/bank-accounts/:id/edit" component={BankAccountsCrear}/>
                <ProtectedRoute exact path="/bank-accounts/:id/view" component={BankAccountsCrear}/>

                <ProtectedRoute exact path="/banks" component={Banks}/>
                <ProtectedRoute exact path="/banks/:id" component={BankTransaction}/>
                <ProtectedRoute exact path="/pending" component={Pending}/>

                <ProtectedRoute exact path="/cash-boxes" component={CashBoxes}/>
                <ProtectedRoute exact path="/cash-boxes/create" component={CashBoxesCrear}/>
                <ProtectedRoute exact path="/cash-boxes/:id/edit" component={CashBoxesCrear}/>
                <ProtectedRoute exact path="/cash-boxes/:id/view" component={CashBoxesCrear}/>

                <ProtectedRoute exact path="/referents" component={Referent}/>
                <ProtectedRoute exact path="/referents/create" component={ReferentCrear}/>
                <ProtectedRoute exact path="/referents/:id/edit" component={ReferentCrear}/>
                <ProtectedRoute exact path="/referents/:id/view" component={ReferentCrear}/>

                <ProtectedRoute exact path="/subsidiaries" component={Subsidiary}/>
                <ProtectedRoute exact path="/subsidiaries/create" component={SubsidiaryCrear}/>
                <ProtectedRoute exact path="/subsidiaries/:id/edit" component={SubsidiaryCrear}/>
                <ProtectedRoute exact path="/subsidiaries/:id/view" component={SubsidiaryCrear}/>

                <ProtectedRoute exact path="/referent-categories" component={ReferentCategory}/>
                <ProtectedRoute exact path="/referent-categories/create" component={ReferentCategoryCrear}/>
                <ProtectedRoute exact path="/referent-categories/:id/edit" component={ReferentCategoryCrear}/>
                <ProtectedRoute exact path="/referent-categories/:id/view" component={ReferentCategoryCrear}/>

                <ProtectedRoute exact path="/institutions" component={Institution}/>
                <ProtectedRoute exact path="/institutions/create" component={InstitutionCrear}/>
                <ProtectedRoute exact path="/institutions/:id/edit" component={InstitutionCrear}/>
                <ProtectedRoute exact path="/institutions/:id/view" component={InstitutionCrear}/>

                <ProtectedRoute exact path="/service-categories" component={ServiceCategory}/>
                <ProtectedRoute exact path="/service-categories/create" component={ServiceCategoryCrear}/>
                <ProtectedRoute exact path="/service-categories/:id/edit" component={ServiceCategoryCrear}/>
                <ProtectedRoute exact path="/service-categories/:id/view" component={ServiceCategoryCrear}/>

                <ProtectedRoute exact path="/patients" component={Patient}/>
                <ProtectedRoute exact path="/patients/create" component={PatientCreate}/>
                <ProtectedRoute exact path="/patients/:id/edit" component={PatientCreate}/>
                <ProtectedRoute exact path="/patients/:id/view" component={PatientCreate}/>

                <ProtectedRoute exact path="/appointments" component={Appointments}/>
                <ProtectedRoute exact path="/appointments/create" component={AppointmentsCreate}/>
                <ProtectedRoute exact path="/appointments/lock-schedule" component={ScheduleLock}/>
                <ProtectedRoute exact path="/appointments/lock-schedule-week" component={ScheduleLockWeek}/>
                <ProtectedRoute exact path="/appointments/:id" component={AppointmentsCreate}/>
                <ProtectedRoute exact path="/today-appointment" component={AppointmentsToday} />


                <ProtectedRoute exact path="/schedule" component={Schedule}/>
                <ProtectedRoute exact path="/schedule/:id" component={AppointmentsWeekCreate}/>

                <ProtectedRoute exact path="/oppening-closing/box" component={OppeningClosingBox}/>
                <ProtectedRoute exact path="/oppening-closing/open-box" component={OpenBox}/>
                <ProtectedRoute exact path="/oppening-closing/close-box" component={CloseBox}/>
                <ProtectedRoute exact path="/oppening-closing/opening-box/:id" component={OpeningBox} />

                <ProtectedRoute exact path="/doctors/:id/view" component={DoctorCreate}/>
                <ProtectedRoute exact path="/doctors/:id/edit" component={DoctorCreate}/>
                <ProtectedRoute exact path="/doctors" component={Doctor} />
                <ProtectedRoute exact path="/doctors/create" component={DoctorCreate} />

                <ProtectedRoute exact path="/oppening-closing" component={Sales} />
                <ProtectedRoute exact path="/oppening-closing/sales/" component={SalesService} />
                <ProtectedRoute exact path="/oppening-closing/payment/" component={SalesPayment} />
                <ProtectedRoute exact path="/oppening-closing/deposit/" component={SalesDeposit} />
                <ProtectedRoute exact path="/oppening-closing/view-sale/" component={ViewFinishSale} />
                <ProtectedRoute exact path="/oppening-closing/view-bill/:id" component={ViewBillContainer} />
                <ProtectedRoute exact path="/oppening-closing/print-bill/:id" component={ViewBillContainer} />
                <ProtectedRoute exact path="/oppening-closing/cancel-bill/:id" component={ViewBillContainer} />

                <ProtectedRoute exact path="/configuration/" component={Configuration} />

                <ProtectedRoute exact path="/dues" component={Dues} />
                <ProtectedRoute exact path="/dues/create" component={DuesCrear} />
                <ProtectedRoute exact path="/dues/:id/edit" component={DuesCrear} />

                <ProtectedRoute exact path="/financial_entity/:id/view" component={FinancialEntityCreate}/>
                <ProtectedRoute exact path="/financial_entity/:id/edit" component={FinancialEntityCreate}/>
                <ProtectedRoute exact path="/financial_entity/create" component={FinancialEntityCreate} />
                <ProtectedRoute excat path="/financial_entity" component={FinancialEntity} />

                <ProtectedRoute exact path="/coupons/:id/view" component={CouponView}/>
                {/* <ProtectedRoute exact path="/coupons/:id/view" component={CouponCreate}/>*/}
                <ProtectedRoute exact path="/coupons/:id/edit" component={CouponCreate}/>
                <ProtectedRoute exact path="/coupons/create" component={CouponCreate} />
                <ProtectedRoute exact path="/coupons" component={Coupon} />

                <ProtectedRoute exact path="/warehouse/:id/view" component={WhearhouseCreate}/>
                <ProtectedRoute exact path="/warehouse/:id/edit" component={WhearhouseCreate}/>
                <ProtectedRoute exact path="/warehouse/create" component={WhearhouseCreate} />
                <ProtectedRoute excat path="/warehouse" component={Whearhouse} />

                <ProtectedRoute excat path="/administracion-bodega" component={WhearhouseAdmin} />
                <ProtectedRoute excat path="/administrar-bodega/:id/admin" component={WhearhouseInventory} />
                <ProtectedRoute excat path="/administrar-bodega/:id/ingreso" component={IngresoContainer} />
                <ProtectedRoute excat path="/administrar-bodega/:id/reajuste" component={ReajusteContainer} />
                <ProtectedRoute excat path="/administrar-bodega/:id/baja" component={BajaContainer} />

                <ProtectedRoute exact path="/providers/:id/view" component={ProviderCreate}/>
                <ProtectedRoute exact path="/providers/:id/edit" component={ProviderCreate}/>
                <ProtectedRoute exact path="/providers/create" component={ProviderCreate} />
                <ProtectedRoute excat path="/providers" component={Provider} />

                <ProtectedRoute exact path="/report-movements/view-bill/:id/:referency" component={ViewBillMovementContainer} />
                <ProtectedRoute exact path="/report-movements/print-bill/:id/:referency" component={ViewBillMovementContainer} />
                <ProtectedRoute exact path="/report-movements/cancel-bill/:id/:referency" component={ViewBillMovementContainer} />
                <ProtectedRoute excat path="/report-movements" component={MovementContainer} />

                <ProtectedRoute excat path="/payments-history/:id/:referency" component={PaymentsHistory} />

                <ProtectedRoute excat path="/receivable" component={CuentasPorCobrar} />

                <ProtectedRoute exact path="/orders-adm/:id/authorization" component={AuthorizationOrder} />
                <ProtectedRoute excat path="/ordenes" component={Ordenes} />
                <ProtectedRoute excat path="/orders-adm" component={OrdenesAdm} />



                <ProtectedRoute exact path="/new-purchase/:id/view" component={NuevaOrden}/>
                <ProtectedRoute exact path="/new-purchase/:id/edit" component={NuevaOrden}/>
                <ProtectedRoute excat path="/new-purchase-order" component={NuevaOrden} />

                <ProtectedRoute excat path="/pending-orders/:id/entry" component={Entry} />
                <ProtectedRoute excat path="/pending-orders" component={EntryOrder} />

                <ProtectedRoute excat path="/to-pay" component={ToPay} />

                <ProtectedRoute excat path="/view-entry-order/:id/view" component={ViewEntryOrder} />
                <ProtectedRoute excat path="/view-entry-order/:id/payment-history" component={EntryHistoryPayments} />


                <ProtectedRoute exact path="/small-box/:id/edit" component={SmallBoxCrear}/>
                <ProtectedRoute exact path="/small-box/:id/view" component={SmallBoxCrear}/>
                <ProtectedRoute exact path="/small-box/create" component={SmallBoxCrear}/>
                <ProtectedRoute exact path="/small-box" component={SmallBox}/>

                <ProtectedRoute exact path="/spending-types/:id/edit" component={SpendingTypeCrear}/>
                <ProtectedRoute exact path="/spending-types/:id/view" component={SpendingTypeCrear}/>
                <ProtectedRoute exact path="/spending-types/create" component={SpendingTypeCrear}/>
                <ProtectedRoute exact path="/spending-types" component={SpendingType}/>

                <ProtectedRoute exact path="/income-expense/:id/view" component={ExpenseCrear}/>
                <ProtectedRoute exact path="/income-expense/create" component={ExpenseCrear}/>
                <ProtectedRoute exact path="/income-expense" component={Expense}/>

                <ProtectedRoute exact path="/adm-smbx/:id/admin" component={SmallBoxAdmMain}/>
                <ProtectedRoute exact path="/adm-smbx" component={SmallBoxAdm}/>
                <ProtectedRoute exact path="/retention-payment" component={PaymentRetention}/>

                <ProtectedRoute exact path="/surgeries" component={Surgery}/>
                <ProtectedRoute exact path="/surgeries/create" component={SurgeryCreate}/>

                <ProtectedRoute exact path="/surgeries/lock-schedule" component={SurgeryScheduleLock}/>
                {/*
                <ProtectedRoute exact path="/surgeries/lock-schedule-week" component={ScheduleLockWeek}/>
                */}
                <ProtectedRoute exact path="/surgeries-schedule/:id" component={SurgeryCreate}/>
                <ProtectedRoute exact path="/surgeries-schedule" component={ScheduleSurgery}/>

                <ProtectedRoute exact path="/perform-surgery/:id" component={PerformSurgery}/>

                {/* Reportes */}
                <ProtectedRoute exact path="/payback" component={PaybackPaid}/>
                <ProtectedRoute exact path="/payback-paid" component={Payback}/>

                <ProtectedRoute exact path="/arqueos" component={Arqueo}/>
                <ProtectedRoute exact path="/arching-detail/:id" component={DetalleArqueo}/>

                <ProtectedRoute exact path="/new-dashboard" component={NewDasboard}/>

                {/* Surgeries */}
                <ProtectedRoute exact path="/cirugias-de-hoy" component={TodaysSurgery}/>
                <ProtectedRoute exact path="/reporte-cirugias" component={SurgeryReport}/>

                {/* Orden de diagnostico */}
                <ProtectedRoute exact path="/diagnostic-order" component={DiagnosticOrder}/>
                <ProtectedRoute exact path="/diagnostic-order/create" component={DiagnosticContainerCreate}/>
                <ProtectedRoute exact path="/examorder/:id" component={ExamOrder}/>
                <ProtectedRoute exact path="/cola-diagnosticos" component={DiagnosticQueue}/>
                <ProtectedRoute exact path="/examenes-completados" component={DiagnosticCompleted}/>
                <ProtectedRoute exact path="/resumen-procedimientos/:id" component={ResumeDiagnostic}/>
                <ProtectedRoute exact path="/transcription/:id" component={DiagnosticTranscription}/>

                {/* Planes */}
                <ProtectedRoute exact path="/plan-examen" component={PlanesExamen}/>
                <ProtectedRoute exact path="/plan-procedimiento" component={PlanesProcedimiento}/>
                <ProtectedRoute exact path="/plan-paquete" component={PlanesPaquete}/>

                <ProtectedRoute exact path="/plan-examen/create" component={PlanCrear}/>
                <ProtectedRoute exact path="/plan-procedimiento/create" component={PlanCrear}/>
                <ProtectedRoute exact path="/plan-paquete/create" component={PlanCrear}/>

                <ProtectedRoute exact path="/plan-examen/:id/edit" component={PlanCrear}/>
                <ProtectedRoute exact path="/plan-procedimiento/:id/edit" component={PlanCrear}/>
                <ProtectedRoute exact path="/plan-paquete/:id/edit" component={PlanCrear}/>

                <ProtectedRoute exact path="/plan-examen/:id/view" component={PlanCrear}/>
                <ProtectedRoute exact path="/plan-procedimiento/:id/view" component={PlanCrear}/>
                <ProtectedRoute exact path="/plan-paquete/:id/view" component={PlanCrear}/>

                {/* */}
                <ProtectedRoute exact path="/detalle-cirugia/:id" component={SurgeryDetail}/>
                <Route exact path="/pdf/:id" component={PDF}/>

                <Route exact path="/consult-print/recipe/:id/:patient" component={RecipePrint} />
                <Route exact path="/consult-print/consult-sheet/:id" component={ConsultSheetPrint} />

                <ProtectedRoute exact path="/fee-paid" component={FeePaid}/>
                <ProtectedRoute exact path="/fee" component={Fee}/>

                {/* Pofile */}
                <ProtectedRoute path="/user-profile" component={Profile} />
                {/* Record */}
                <ProtectedRoute exact path="/records/:id" component={RecordsList} />
                <ProtectedRoute exact path="/records/:id/create" component={RecordCreate} />
                <ProtectedRoute exact path="/records_edit/:id/edit" component={RecordEdit}/>
                <ProtectedRoute exact path="/records_edit/:id/view" component={Show}/>

                <ProtectedRoute path='/medical-history/:id' component={MedicalHistory} />

                {/* Reportes Detalles*/}
                <ProtectedRoute exact path='/reporte-ingresos/:id' component={ReporteDetallesIngreso} />
                <ProtectedRoute path='/reporte-bajas/:id' component={ReporteDetallesBajas} />
                <ProtectedRoute path='/reporte-ajustes/:id' component={ReporteDetallesAjustes} />
                {/* Reportes */}
                <ProtectedRoute path='/reporte-ingresos' component={ReporteIngreso} />
                <ProtectedRoute path='/reporte-bajas' component={ReporteBaja} />
                <ProtectedRoute path='/reporte-ajustes' component={ReporteAjuste} />

                {/* Reporte de consultas */}
                <ProtectedRoute path='/reporte-consultas/:id' component={ReporteDetalleConsultas} />
                <ProtectedRoute path='/reporte-consultas' component={ReporteConsultas} />
                <ProtectedRoute path='/reporte-tiempos-consultas' component={TiemposConsulta} />

                {/* Reporte de productos en consignación */}
                <ProtectedRoute path='/reporte-consignacion/' component={ReporteConsignacion} />
                <ProtectedRoute path='/reporte-entrada-producto/:id' component={ReporteEntradaProducto} />

                <ProtectedRoute path='/reporte-cuentas-abiertas' component={ReporteCuentasAbiertas} />
                <ProtectedRoute path='/report-open-accounts-igss' component={ReporteCuentasAbiertasIgss} />
                
                <ProtectedRoute path='/reporte-consolidado' component={ReporteConsolidado} />

                <ProtectedRoute path='/reporte-consolidado-gastos' component={ReporteConsolidadoGastos} />

                {/* Bitacora */}
                <ProtectedRoute exact path='/bitacora' component={Bitacora} />
                <ProtectedRoute exact path='/bitacora/:id/view' component={DetalleBitacora} />

                <ProtectedRoute path='/reporte-gastos' component={ReporteGastos} />

                <ProtectedRoute path='/reporte-pagos-grupales' component={PagosGrupales} />


                {/*  */}
                <ProtectedRoute path='/promedio-procedimientos' component={ProcedimientosPromedioContainer} />
                {/*<ProtectedRoute path='/reporte-consultas' component={ReporteConsultas} />*/}

                <ProtectedRoute path='/tasks' component={Tasks} />
                <ProtectedRoute exact path='/treatments' component={Treatments} />
                <ProtectedRoute exact path='/treatments/:id/edit' component={TreatmentsCreate} />
                <ProtectedRoute exact path='/treatments/:id/view' component={TreatmentsCreate} />
                <ProtectedRoute exact path='/treatments/create' component={TreatmentsCreate} />

                <ProtectedRoute exact path="/plan-examen/:id/view" component={PlanCrear}/>

                <ProtectedRoute path="/home" component={Home} />
                <ProtectedRoute path="*" component={Login} />

            </Switch>
        </div>
    </div>
);
