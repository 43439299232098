import React from 'react';
import Modal from 'react-responsive-modal';
import Form from "./InformationSheetForm";
import CanvasDraw from "react-canvas-draw";
import municipios from "../../../utility/variables";
import moment from "moment";
import { PrintContainer, actions as printActions } from "Utils/Print";

const MD = 1;
const QX = 2;
const ALG = 3;
const TRX = 4;
const FAM = 5;

export default class ConsultCard extends React.Component {
    state = {
        ojoIzquierdoImg: null,
        ojoDerechoImg: null,
        ojoIzquierdoCircularImg: null,
        ojoDerechoCircularImg: null,
    }

    componentDidMount = () => {
        const {consult} = this.props;
        //console.log("CONSULT IN CARD --------------> ", consult);
        if(consult.lamp !== null){
            this.ojoIzquierdo.imgSrc = require('../../../../assets/img/icons/ojo-izquierdo-gris.png');

            if(this.props.consult.lamp.left_eye !== null){
                this.ojoIzquierdo.loadSaveData(this.props.consult.lamp.left_eye, false);
            }

            if(this.props.consult.lamp.right_eye !== null){
                this.ojoDerecho.loadSaveData(this.props.consult.lamp.right_eye, false);
            }

            if(this.props.consult.lamp.left_eye_circle !== null){
                this.ojoCircularIzquierdo.loadSaveData(this.props.consult.lamp.left_eye_circle, false);
            }

            if(this.props.consult.lamp.right_eye_circle !== null){
                this.ojoCircularDerecho.loadSaveData(this.props.consult.lamp.right_eye_circle, false);
            }
        }
    }

    dibujar = () => {
        this.ojoIzquierdo.loadSaveData(this.props.consult.lamp.left_eye, false);
        this.ojoDerecho.loadSaveData(this.props.consult.lamp.right_eye, false);

        this.ojoCircularIzquierdo.loadSaveData(this.props.consult.lamp.left_eye_circle, false);
        this.ojoCircularDerecho.loadSaveData(this.props.consult.lamp.right_eye_circle, false);
    }

    obtenerMunicipio = (codigo) => {
        municipios.forEach(item=>{
            if(item.value === codigo){
                return item.codigo;
            }
        });

        return "";
    }

    calculateAge = (birthday) => {
        var birthday_arr = birthday.split("-");
        var birthday_date = new Date(birthday_arr[0], birthday_arr[1] - 1, birthday_arr[2]);
        var ageDifMs = Date.now() - birthday_date.getTime();
        var ageDate = new Date(ageDifMs);
        return Math.abs(ageDate.getUTCFullYear() - 1970);
    }

    generateImage = (DrawerRef) => {
        const canvasElements = DrawerRef.canvasContainer.querySelectorAll('canvas');

        // Crear un canvas temporal para combinar todo
        const tempCanvas = document.createElement('canvas');
        tempCanvas.width = 125; // Mismo ancho que los canvases originales
        tempCanvas.height = 125; // Mismo alto que los canvases originales
        const tempContext = tempCanvas.getContext('2d');
  
        // Combinar el contenido de todos los canvases en el canvas temporal
        canvasElements.forEach((canvas) => {
          tempContext.drawImage(canvas, 0, 0);
        });
  
        // Obtener la imagen combinada en formato data URL
        const dataUrl = tempCanvas.toDataURL();
        return dataUrl;        
    }

    replaceCanvas = (draw) => {
        if(draw == 'ojo-izquierdo'){
            if(this.ojoIzquierdo){
                const ojoIzImg = this.generateImage(this.ojoIzquierdo);                
                this.setState({ojoIzquierdoImg: ojoIzImg});
            }
        }else if(draw == 'ojo-derecho'){
            if(this.ojoDerecho){
                const ojoDerImg = this.generateImage(this.ojoDerecho);
                this.setState({ojoDerechoImg: ojoDerImg});
            }
        }else if(draw == 'ojo-circular-izquierdo'){
            if(this.ojoCircularIzquierdo){
                const ojoCirIzImg = this.generateImage(this.ojoCircularIzquierdo);
                this.setState({ojoIzquierdoCircularImg: ojoCirIzImg});
            }
        }else if(draw == 'ojo-circular-derecho'){
            if(this.ojoCircularDerecho){
                const ojoCirDerImg = this.generateImage(this.ojoCircularDerecho);
                this.setState({ojoDerechoCircularImg: ojoCirDerImg});
            }
        }
    }

    render() {
        const { consult } = this.props;
        const {
            open_modal_update_scan,
            openModalUpdateScan,
            saveScan,
            patient_id,
            deleteScanAppointment,
            anotherClass
        } = this.props;

        const age = consult ? this.calculateAge(consult.patient.birthdate) : 0;


        console.log("consult detail in card: ", consult);
        //console.log(`consult detail-${this.props.correlativo}: `, consult)

        if(consult.lamp !== null){
            /* OD */
            let od_via_lagrimal;
            if(consult.lamp.od_via_lagrimal === null){
                od_via_lagrimal = consult.lamp.od_via_lagrimal_otro === null ? 'Sin ingresar' : consult.lamp.od_via_lagrimal_otro;
            }else if(consult.lamp.od_via_lagrimal === true){
                od_via_lagrimal = 'Ok';
            }else if(consult.lamp.od_via_lagrimal === false){
                od_via_lagrimal = 'Diferido';
            }

            consult.lamp.od_via_lagrimal = od_via_lagrimal;

            let od_parpado;
            if(consult.lamp.od_parpado === null){
                od_parpado = consult.lamp.od_parpado_otro === null ? '' : consult.lamp.od_parpado_otro;
            }else if(consult.lamp.od_parpado === true){
                od_parpado = 'Ok';
            }else if(consult.lamp.od_parpado === false){
                od_parpado = 'Diferido';
            }

            consult.lamp.od_parpado = od_parpado;

            let od_conjuntiva;
            if(consult.lamp.od_conjuntiva === null){
                od_conjuntiva = consult.lamp.od_conjuntiva_otro === null ? '' : consult.lamp.od_conjuntiva_otro;
            }else if(consult.lamp.od_conjuntiva === true){
                od_conjuntiva = 'Ok';
            }else if(consult.lamp.od_conjuntiva === false){
                od_conjuntiva = 'Diferido';
            }

            consult.lamp.od_conjuntiva = od_conjuntiva;

            let od_esclera;
            if(consult.lamp.od_esclera === null){
                od_esclera = consult.lamp.od_esclera_otro === null ? '' : consult.lamp.od_esclera_otro;
            }else if(consult.lamp.od_esclera === true){
                od_esclera = 'Ok';
            }else if(consult.lamp.od_esclera === false){
                od_esclera = 'Diferido';
            }

            consult.lamp.od_esclera = od_esclera;

            let od_cornea;
            if(consult.lamp.od_cornea === null){
                od_cornea = consult.lamp.od_cornea_otro === null ? '' : consult.lamp.od_cornea_otro;
            }else if(consult.lamp.od_cornea === true){
                od_cornea = 'Ok';
            }else if(consult.lamp.od_cornea === false){
                od_cornea = 'Diferido';
            }

            consult.lamp.od_cornea = od_cornea;

            let od_camara_anterior;
            if(consult.lamp.od_camara_anterior === null){
                od_camara_anterior = consult.lamp.od_camara_anterior_otro === null ? '' : consult.lamp.od_camara_anterior_otro;
            }else if(consult.lamp.od_camara_anterior === true){
                od_camara_anterior = 'Ok';
            }else if(consult.lamp.od_camara_anterior === false){
                od_camara_anterior = 'Diferido';
            }

            consult.lamp.od_camara_anterior = od_camara_anterior;

            let od_iris;
            if(consult.lamp.od_iris === null){
                od_iris = consult.lamp.od_iris_otro === null ? '' : consult.lamp.od_iris_otro;
            }else if(consult.lamp.od_iris === true){
                od_iris = 'Ok';
            }else if(consult.lamp.od_iris === false){
                od_iris = 'Diferido';
            }

            consult.lamp.od_iris = od_iris;

            let od_pupila;
            if(consult.lamp.od_pupila === null){
                od_pupila = consult.lamp.od_pupila_otro === null ? '' : consult.lamp.od_pupila_otro;
            }else if(consult.lamp.od_pupila === true){
                od_pupila = 'Ok';
            }else if(consult.lamp.od_pupila === false){
                od_pupila = 'Diferido';
            }

            consult.lamp.od_pupila = od_pupila;

            let od_cristalino;
            if(consult.lamp.od_cristalino === null){
                od_cristalino = consult.lamp.od_cristalino_otro === null ? '' : consult.lamp.od_cristalino_otro;
            }else if(consult.lamp.od_cristalino === true){
                od_cristalino = 'Ok';
            }else if(consult.lamp.od_cristalino === false){
                od_cristalino = 'Diferido';
            }

            consult.lamp.od_cristalino = od_cristalino;

            let od_vitreo;
            if(consult.lamp.od_vitreo){
                od_vitreo = 'Diferido';
            }else{
                od_vitreo = consult.lamp.od_vitreo_otro === null ? '' : consult.lamp.od_vitreo_otro;
            }

            consult.lamp.od_vitreo = od_vitreo;

            let od_nervio_optico;
            if(consult.lamp.od_nervio_optico){
                od_nervio_optico = 'Diferido';
            }else{
                od_nervio_optico = consult.lamp.od_nervio_optico_otro === null ? '' : consult.lamp.od_nervio_optico_otro;
            }

            consult.lamp.od_nervio_optico = od_nervio_optico;

            let od_mascula;
            if(consult.lamp.od_mascula){
                od_mascula = 'Diferido';
            }else{
                od_mascula = consult.lamp.od_mascula_otro === null ? '' : consult.lamp.od_mascula_otro;
            }

            consult.lamp.od_mascula = od_mascula;


            /* OS */
            let os_via_lagrimal;
            if(consult.lamp.os_via_lagrimal === null){
                os_via_lagrimal = consult.lamp.os_via_lagrimal_otro === null ? '' : consult.lamp.os_via_lagrimal_otro;
            }else if(consult.lamp.os_via_lagrimal === true){
                os_via_lagrimal = 'Ok';
            }else if(consult.lamp.os_via_lagrimal === false){
                os_via_lagrimal = 'Diferido';
            }

            consult.lamp.os_via_lagrimal = os_via_lagrimal;

            let os_parpado;
            if(consult.lamp.os_parpado === null){
                os_parpado = consult.lamp.os_parpado_otro === null ? '' : consult.lamp.os_parpado_otro;
            }else if(consult.lamp.os_parpado === true){
                os_parpado = 'Ok';
            }else if(consult.lamp.os_parpado === false){
                os_parpado = 'Diferido';
            }

            consult.lamp.os_parpado = os_parpado;

            let os_conjuntiva;
            if(consult.lamp.os_conjuntiva === null){
                os_conjuntiva = consult.lamp.os_conjuntiva_otro === null ? '' : consult.lamp.os_conjuntiva_otro;
            }else if(consult.lamp.os_conjuntiva === true){
                os_conjuntiva = 'Ok';
            }else if(consult.lamp.os_conjuntiva === false){
                os_conjuntiva = 'Diferido';
            }

            consult.lamp.os_conjuntiva = os_conjuntiva;

            let os_esclera;
            if(consult.lamp.os_esclera === null){
                os_esclera = consult.lamp.os_esclera_otro === null ? '' : consult.lamp.os_esclera_otro;
            }else if(consult.lamp.os_esclera === true){
                os_esclera = 'Ok';
            }else if(consult.lamp.os_esclera === false){
                os_esclera = 'Diferido';
            }

            consult.lamp.os_esclera = os_esclera;

            let os_cornea;
            if(consult.lamp.os_cornea === null){
                os_cornea = consult.lamp.os_cornea_otro === null ? '' : consult.lamp.os_cornea_otro;
            }else if(consult.lamp.os_cornea === true){
                os_cornea = 'Ok';
            }else if(consult.lamp.os_cornea === false){
                os_cornea = 'Diferido';
            }

            consult.lamp.os_cornea = os_cornea;

            let os_camara_anterior;
            if(consult.lamp.os_camara_anterior === null){
                os_camara_anterior = consult.lamp.os_camara_anterior_otro === null ? '' : consult.lamp.os_camara_anterior_otro;
            }else if(consult.lamp.os_camara_anterior === true){
                os_camara_anterior = 'Ok';
            }else if(consult.lamp.os_camara_anterior === false){
                os_camara_anterior = 'Diferido';
            }

            consult.lamp.os_camara_anterior = os_camara_anterior;

            let os_iris;
            if(consult.lamp.os_iris === null){
                os_iris = consult.lamp.os_iris_otro === null ? '' : consult.lamp.os_iris_otro;
            }else if(consult.lamp.os_iris === true){
                os_iris = 'Ok';
            }else if(consult.lamp.os_iris === false){
                os_iris = 'Diferido';
            }

            consult.lamp.os_iris = os_iris;

            let os_pupila;
            if(consult.lamp.os_pupila === null){
                os_pupila = consult.lamp.os_pupila_otro === null ? '' : consult.lamp.os_pupila_otro;
            }else if(consult.lamp.os_pupila === true){
                os_pupila = 'Ok';
            }else if(consult.lamp.os_pupila === false){
                os_pupila = 'Diferido';
            }

            consult.lamp.os_pupila = os_pupila;

            let os_cristalino;
            if(consult.lamp.os_cristalino === null){
                os_cristalino = consult.lamp.os_cristalino_otro === null ? '' : consult.lamp.os_cristalino_otro;
            }else if(consult.lamp.os_cristalino === true){
                os_cristalino = 'Ok';
            }else if(consult.lamp.os_cristalino === false){
                os_cristalino = 'Diferido';
            }

            consult.lamp.os_cristalino = os_cristalino;

            let os_vitreo;
            if(consult.lamp.os_vitreo === true){
                os_vitreo = 'Diferido';
            }else{
                os_vitreo = consult.lamp.os_vitreo_otro === null ? '' : consult.lamp.os_vitreo_otro;
            }

            consult.lamp.os_vitreo = os_vitreo;

            let os_nervio_optico;
            if(consult.lamp.os_nervio_optico === true){
                os_nervio_optico = 'Diferido';
            }else{
                os_nervio_optico = consult.lamp.os_nervio_optico_otro === null ? '' : consult.lamp.os_nervio_optico_otro;
            }

            consult.lamp.os_nervio_optico = os_nervio_optico;

            let os_mascula;
            if(consult.lamp.os_mascula === true){
                os_mascula = 'Diferido';
            }else{
                os_mascula = consult.lamp.os_mascula_otro === null ? '' : consult.lamp.os_mascula_otro;
            }

            consult.lamp.os_mascula = os_mascula;
        }
        
        return (
            <div
                className='d-flex flex-column justify-content-center align-items-center'
            >                
                <div className={`contenedor-hoja`}>
                {consult.scan !== null && consult.scan !== '' ?
                    <React.Fragment>
                    {this.props.me.role_name === "superuser" &&
                        <div className='d-flex flex-row justify-content-around aling-items-center w-100 mt-2 mb-2'>
                            <button
                                onClick={()=> deleteScanAppointment(consult.id, patient_id)}
                                className='btn btn-danger btn-sm'
                            >Eliminar Archivo</button>
                            <label className="p-2" >Archivo subido: {moment(consult.date).format("DD-MM-YYYY")}</label>
                        </div>
                    }
                    {consult.scan.includes(".pdf") ?
                        <React.Fragment key={consult.id}>
                            <iframe
                                src={`../../../static/pdfjs-2/web/viewer.html?file=${consult.scan}`}
                                width="70%"
                                height="800"
                            />
                        </React.Fragment>
                    :
                        <img
                            style={{ maxHeight: 750, maxWidth: "98%", margin: "auto", }}
                            src={consult.scan}
                        />
                    }
                    </React.Fragment>
                :                    
                    <div className={`d-flex flex-column consult-card ${anotherClass || ''}`}>
                        <PrintContainer name={`consulta-${consult.id}`}>
                        <div className='d-flex flex-row justify-content-between'>                        
                            <div className='d-flex flex-column w-40 justify-content-center'>
                                <div className='d-flex flex-row justify-content-center pt-2'>
                                    <img
                                        src={require("../../../../assets/img/logo_prolaser_gris.png")}
                                        alt="Prolaser_logo"
                                        style={{width:250, height: 50}}
                                    />
                                </div>
                                <div className='d-flex flex-row justify-content-center mt-2'>
                                    <h4 className='text-primary font-weight-bold'>Consulta {this.props.correlativo}</h4>
                                </div>
                            </div>
                            <div className='d-flex flex-column w-60 consult-card-patient-info'>
                                <div className='d-flex flex-row w-100 mb-2'>
                                    <div className='d-flex flex-row w-50'>
                                        <div className='d-flex flex-row mr-2'>Fecha</div>
                                        <div className='d-flex flex-row w-75 consult-card-patient-underlined'>{moment(consult.date).format("DD/MM/YYYY")}</div>
                                    </div>
                                    {/*
                                    <div className='d-flex flex-row w-50'>
                                        <div className='d-flex flex-row mr-2'>No.</div>
                                        <div className='d-flex flex-row w-75 consult-card-patient-underlined'>{consult.id}</div>
                                    </div>
                                    */}
                                    <div className='d-flex flex-row w-50'>
                                        <div className='d-flex flex-row mr-2'>Id Pac.</div>
                                        <div className='d-flex flex-row w-75 consult-card-patient-underlined'>{consult.patient.id}</div>
                                    </div>
                                </div>
                                <div className='d-flex flex-row w-100 mb-2'>
                                    <div className='d-flex flex-row mr-2'>Nombre</div>
                                    <div className='d-flex flex-row w-75 consult-card-patient-underlined'>{consult.patient.first_name} {consult.patient.last_name}</div>
                                </div>
                                <div className='d-flex flex-row w-100 mb-2'>
                                    <div className='d-flex flex-row w-50'>
                                        <div className='d-flex flex-row mr-2'>Edad</div>
                                        <div className='d-flex flex-row w-75 consult-card-patient-underlined'>{age} años</div>
                                    </div>
                                    <div className='d-flex flex-row w-50'>
                                        <div className='d-flex flex-row mr-2'>Nac</div>
                                        <div className='d-flex flex-row w-75 consult-card-patient-underlined'>{consult.patient.nationality}</div>
                                    </div>
                                </div>
                                {/*
                                <div className='d-flex flex-row w-100 mb-2'>
                                    <div className='d-flex flex-row mr-2'>Tels</div>
                                    <div className='d-flex flex-row w-75 consult-card-patient-underlined'>{consult.patient.phone} {consult.patient.cellphone ? ' / ' + consult.patient.cellphone : ''}</div>
                                </div>
                                */}
                                <div className='d-flex flex-row w-100 mb-2'>
                                    <div className='d-flex flex-row w-50'>
                                        <div className='d-flex flex-row mr-2'>Tels</div>
                                        <div className='d-flex flex-row w-75 consult-card-patient-underlined'>{consult.patient.phone} {consult.patient.cellphone ? ' / ' + consult.patient.cellphone : ''}</div>
                                    </div>
                                    {/*
                                    <div className='d-flex flex-row w-50'>
                                        <div className='d-flex flex-row mr-2'>Id Pac.</div>
                                        <div className='d-flex flex-row w-75 consult-card-patient-underlined'>{consult.patient.id}</div>
                                    </div>
                                    */}
                                    <div className='d-flex flex-row w-50'>
                                        <div className='d-flex flex-row mr-2'>No.</div>
                                        <div className='d-flex flex-row w-75 consult-card-patient-underlined'>{consult.id}</div>
                                    </div>                                    
                                </div>
                            </div>
                        </div>
                        <div style={{
                            padding: "18px",
                            marginBottom: "10px"
                        }}>
                            <div className="row">
                                <div className="col-12 col-md-4 d-flex flex-column">
                                    <div className="font-weight-bold">Email</div>
                                    <div className="consult-card-underlined">
                                        {consult.patient.email ? consult.patient.email : "- - -"}
                                    </div>
                                </div>
                                <div className="col-12 col-md-4 d-flex flex-column">
                                    <div className="font-weight-bold">Quien refiere</div>
                                    <div className="consult-card-underlined">
                                        {consult.patient.referred_by ? consult.patient.referred_by.name : "- - -" }
                                    </div>
                                </div>
                                <div className="col-12 col-md-4 d-flex flex-column">
                                    <div className="font-weight-bold">Profesión</div>
                                    <div className="consult-card-underlined">
                                        { consult.patient.profession ? consult.patient.profession : "- - -" }
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className='d-flex flex-row mt-4 justify-content-between pl-3 pr-3 mb-3'>
                            <div className='d-flex flex-row w-25'>
                                <div className='font-weight-bold mr-2'>E-mail</div>
                                <div className='consult-card-underlined w-75'>{consult.patient.email}</div>
                            </div>
                            <div className='d-flex flex-row w-25'>
                                <div className='font-weight-bold mr-2'>Quien refiere</div>
                                <div className='consult-card-underlined w-50'>{consult.patient.referred_by ? consult.patient.referred_by.name : ''}</div>
                            </div>
                            <div className='d-flex flex-row w-25'>
                                <div className='font-weight-bold mr-2'>Profesión</div>
                                <div className='consult-card-underlined w-75'>{consult.patient.profession}</div>
                            </div>
                        </div> */}

                        <div className='d-flex flex-row mb-3'>
                            <div className='d-flex flex-row pl-3 pr-3 font-weight-bold w-10'>MC</div>
                            <div className='d-flex flex-row consult-card-underlined w-80 flex-wrap'>
                                {consult.record_appointment_appointment && consult.record_appointment_appointment.consultation_reason}
                            </div>
                        </div>

                        <div className='d-flex flex-row mb-3'>
                            <div className='d-flex flex-row pl-3 pr-3 font-weight-bold w-10'>HEA</div>
                            <div className='d-flex flex-row consult-card-underlined w-80 flex-wrap'>
                            {consult.record_appointment_appointment && consult.record_appointment_appointment.disease_history}
                            </div>
                        </div>

                        <div className='d-flex flex-row mb-3'>
                            <div className='d-flex flex-row pl-3 pr-3 font-weight-bold w-10'>A(x)</div>

                            <div className='d-flex flex-column w-40'>
                                <div className='d-flex flex-row mb-2 w-100'>
                                    <div className='font-weight-bold mr-2'>M(x)</div>
                                    <div className='d-flex flex-row w-100'> {/* M(x) */}
                                        {consult.record_appointment_appointment &&
                                            <React.Fragment>
                                            {consult.record_appointment_appointment.rar_ra.map((item, i) => (
                                                <React.Fragment>
                                                {item.record.record_type === MD &&
                                                    <React.Fragment>
                                                    <div key={i} className='consult-card-underlined w-50 mr-5'>{item.record.name}</div>
                                                    <div className='consult-card-underlined w-50 w-25'>{item.record.md_treatment}</div>
                                                    </React.Fragment>
                                                }
                                                </React.Fragment>
                                            ))}
                                            </React.Fragment>
                                        }
                                    </div>
                                </div>

                                <div className='d-flex flex-row mb-2 w-100'>
                                    <div className='d-flex flex-row font-weight-bold mr-2'>Q(x)</div>
                                    <div className='d-flex flex-column w-100'> {/* M(x) */}
                                        {consult.record_appointment_appointment &&
                                            <React.Fragment>
                                            {consult.record_appointment_appointment.rar_ra.map((item, i) => (
                                                <React.Fragment>
                                                {item.record.record_type === QX &&
                                                    <div key={i} className='d-flex flex-row consult-card-underlined w-100'>{item.record.name}</div>
                                                }
                                                </React.Fragment>
                                            ))}
                                            </React.Fragment>
                                        }
                                    </div>
                                </div>

                                <div className='d-flex flex-row w-100'>
                                    <div className='d-flex flex-row font-weight-bold mr-2 mb-2'>T(x)</div>
                                    <div className='d-flex flex-column w-100'> {/* M(x) */}
                                        {consult.record_appointment_appointment &&
                                            <React.Fragment>
                                            {consult.record_appointment_appointment.rar_ra.map((item, i) => (
                                                <React.Fragment>
                                                {item.record.record_type === TRX &&
                                                    <div key={i} className='d-flex flex-row consult-card-underlined w-100'>{item.record.name}</div>
                                                }
                                                </React.Fragment>
                                            ))}
                                            </React.Fragment>
                                        }
                                    </div>
                                </div>
                            </div>






                            <div className='d-flex flex-column ml-5 w-40'>
                                <div className='d-flex flex-row mb-2 w-100'>
                                    <div className='font-weight-bold mr-2'>ALG(x)</div>
                                    <div className='d-flex flex-row w-100'> {/* M(x) */}
                                        {consult.record_appointment_appointment &&
                                            <React.Fragment>
                                            {consult.record_appointment_appointment.rar_ra.map((item, i) => (
                                                <React.Fragment>
                                                {item.record.record_type === ALG &&
                                                    <div key={i} className='d-flex flex-row consult-card-underlined mr-5 w-100'>{item.record.name}</div>
                                                }
                                                </React.Fragment>
                                            ))}
                                            </React.Fragment>
                                        }
                                    </div>
                                </div>

                                <div className='d-flex flex-row mb-2 w-100'>
                                    <div className='d-flex flex-row font-weight-bold mr-2'>Q(x)</div>
                                    <div className='d-flex flex-row w-100'> {/* M(x) */}
                                        {consult.record_appointment_appointment &&
                                            <React.Fragment>
                                            {consult.record_appointment_appointment.rar_ra.map((item, i) => (
                                                <React.Fragment>
                                                {item.record.record_type === FAM &&
                                                    <React.Fragment>
                                                        <div key={i} className='d-flex flex-row consult-card-underlined w-50 mr-2'>{item.record.name}</div>
                                                        <div className='d-flex flex-row consult-card-underlined w-50'>Algo más</div>
                                                    </React.Fragment>
                                                }
                                                </React.Fragment>
                                            ))}
                                            </React.Fragment>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='d-flex flex-row w-100'>
                            <div className="ml-4 mr-3 w-50">
                                <table className="table table-detail-lhs">
                                    <tr>
                                        <td className="title-table" style={{ fontSize: "14px"}}>
                                            <strong>
                                                LHs
                                            </strong>
                                        </td>
                                        <td className="subtitle-table" style={{ fontSize: "14px"}}>
                                            <strong>
                                                OD
                                            </strong>
                                        </td>
                                        <td className="subtitle-table" style={{ fontSize: "14px"}}>
                                            <strong>
                                                OS
                                            </strong>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="title-table">Vías Lágrimales</td>
                                        <td className="subtitle-table">
                                            {consult.lamp === null ? '' : consult.lamp.od_via_lagrimal}
                                        </td>
                                        <td className="subtitle-table">
                                            {consult.lamp === null ? '' : consult.lamp.os_via_lagrimal}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="title-table">Párpados y Pestañas</td>
                                        <td className="subtitle-table">
                                            {consult.lamp === null ? '' : consult.lamp.od_parpado}
                                        </td>
                                        <td className="subtitle-table">
                                            {consult.lamp === null ? '' : consult.lamp.os_parpado}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="title-table">Conjuntiva</td>
                                        <td className="subtitle-table">
                                            {consult.lamp === null ? '' : consult.lamp.od_conjuntiva}
                                        </td>
                                        <td className="subtitle-table">
                                            {consult.lamp === null ? '' : consult.lamp.os_conjuntiva}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="title-table">Esclera y Córnea</td>
                                        <td className="subtitle-table">
                                            {consult.lamp === null ? '' : consult.lamp.od_esclera}
                                        </td>
                                        <td className="subtitle-table">
                                            {consult.lamp === null ? '' : consult.lamp.os_esclera}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="title-table">Córnea</td>
                                        <td className="subtitle-table">
                                            {consult.lamp === null ? '' : consult.lamp.od_cornea}
                                        </td>
                                        <td className="subtitle-table">
                                            {consult.lamp === null ? '' : consult.lamp.os_cornea}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="title-table">Cámara Anterior</td>
                                        <td className="subtitle-table">
                                            {consult.lamp === null ? '' : consult.lamp.od_camara_anterior}
                                        </td>
                                        <td className="subtitle-table">
                                            {consult.lamp === null ? '' : consult.lamp.os_camara_anterior}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="title-table">Iris</td>
                                        <td className="subtitle-table">
                                            {consult.lamp === null ? '' : consult.lamp.od_iris}
                                        </td>
                                        <td className="subtitle-table">
                                            {consult.lamp === null ? '' : consult.lamp.os_iris}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="title-table">Pupila</td>
                                        <td className="subtitle-table">
                                            {consult.lamp === null ? '' : consult.lamp.od_pupila}
                                        </td>
                                        <td className="subtitle-table">
                                            {consult.lamp === null ? '' : consult.lamp.os_pupila}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="title-table">Cristalino</td>
                                        <td className="subtitle-table">
                                            {consult.lamp === null ? '' : consult.lamp.od_cristalino}
                                        </td>
                                        <td className="subtitle-table">
                                            {consult.lamp === null ? '' : consult.lamp.os_cristalino}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="title-table">Gonloscopia</td>
                                        <td className="subtitle-table">
                                            <label className='gonloscopia_x'>X</label>
                                        </td>
                                        <td className="subtitle-table">
                                            <label className='gonloscopia_x'>X</label>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="title-table">Vitreo</td>
                                        <td className="subtitle-table">
                                            {consult.lamp === null ? '' : consult.lamp.od_vitreo}
                                        </td>
                                        <td className="subtitle-table">
                                            {consult.lamp === null ? '' : consult.lamp.os_vitreo}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="title-table">Nervio Óptico</td>
                                        <td className="subtitle-table">
                                            {consult.lamp === null ? '' : consult.lamp.od_nervio_optico}
                                        </td>
                                        <td className="subtitle-table">
                                            {consult.lamp === null ? '' : consult.lamp.os_nervio_optico}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="title-table">Mácula</td>
                                        <td className="subtitle-table">
                                            {consult.lamp === null ? '' : consult.lamp.od_mascula}
                                        </td>
                                        <td className="subtitle-table">
                                            {consult.lamp === null ? '' : consult.lamp.os_mascula}
                                        </td>
                                    </tr>
                                </table>
                            </div>
                            <div className="w-50 ml-3 mr-4">
                                <div className="mb-4">
                                    <span className="row-title">Lensometría</span>
                                    <table className="table-detail-lhs_1">
                                        <tr>
                                            <td className="title">
                                                <strong>
                                                    OD
                                                </strong>
                                            </td>
                                            <td className="description">
                                                {consult.lensometry !== null ? consult.lensometry.od_esf : ''}
                                            </td>
                                            <td className="separador">-</td>
                                            <td className="description">
                                                {consult.lensometry !== null ? consult.lensometry.od_cil : ''}
                                            </td>
                                            <td className="separador">x</td>
                                            <td className="description">
                                                {consult.lensometry !== null ? consult.lensometry.od_eje : ''}
                                            </td>
                                            <td className="separador">+</td>
                                            <td className="description">
                                                {consult.lensometry !== null ? consult.lensometry.od_add : ''}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="title">
                                                <strong>
                                                    OS
                                                </strong>
                                            </td>
                                            <td className="description">
                                                {consult.lensometry !== null ? consult.lensometry.os_esf : ''}
                                            </td>
                                            <td className="separador">-</td>
                                            <td className="description">
                                                {consult.lensometry !== null ? consult.lensometry.os_cil : ''}
                                            </td>
                                            <td className="separador">x</td>
                                            <td className="description">
                                                {consult.lensometry !== null ? consult.lensometry.os_eje : ''}
                                            </td>
                                            <td className="separador">+</td>
                                            <td className="description">
                                                {consult.lensometry !== null ? consult.lensometry.os_add : ''}
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                                <div className="mb-4">
                                    <span className="row-title">Ref. Objetiva</span>
                                    <table className="table-detail-lhs_1">
                                        <tr>
                                            <td className="title">
                                                <strong>
                                                    OD
                                                </strong>
                                            </td>
                                            <td className="description">
                                                {consult.refraction !== null ? consult.refraction.od_esf_objetiva : ''}
                                            </td>
                                            <td className="separador">-</td>
                                            <td className="description">
                                                {consult.refraction !== null ? consult.refraction.od_cil_objetiva : ''}
                                            </td>
                                            <td className="separador">x</td>
                                            <td className="description">
                                                {consult.refraction !== null ? consult.refraction.od_eje_objetiva : ''}
                                            </td>
                                            <td className="separador">+</td>
                                            <td className="description">
                                                {consult.refraction !== null ? consult.refraction.od_av_objetiva : ''}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="title">
                                                <strong>
                                                    OS
                                                </strong>
                                            </td>
                                            <td className="description">
                                                {consult.refraction !== null ? consult.refraction.os_esf_objetiva : ''}
                                            </td>
                                            <td className="separador">-</td>
                                            <td className="description">
                                                {consult.refraction !== null ? consult.refraction.os_cil_objetiva : ''}
                                            </td>
                                            <td className="separador">x</td>
                                            <td className="description">
                                                {consult.refraction !== null ? consult.refraction.os_eje_objetiva : ''}
                                            </td>
                                            <td className="separador">+</td>
                                            <td className="description">
                                                {consult.refraction !== null ? consult.refraction.os_av_objetiva : ''}
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                                <div className="mb-4">
                                    <span className="row-title">Ref. Subjetiva</span>
                                    <table className="table-detail-lhs_1">
                                        <tr>
                                            <td className="title">
                                                <strong>
                                                    OD
                                                </strong>
                                            </td>
                                            <td className="description">
                                                {consult.refraction !== null ? consult.refraction.od_esf_subjetiva : ''}
                                            </td>
                                            <td className="separador">-</td>
                                            <td className="description">
                                                {consult.refraction !== null ? consult.refraction.od_cil_subjetiva : ''}
                                            </td>
                                            <td className="separador">x</td>
                                            <td className="description">
                                                {consult.refraction !== null ? consult.refraction.od_eje_subjetiva : ''}
                                            </td>
                                            {/* TODO: REVISAR CON DISENIO */}
                                            {/* <td className="separador">+</td> */}
                                            <td className="">
                                                {consult.refraction !== null ? consult.refraction.add_subjetiva : ''} 
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="title">
                                                <strong>
                                                    OS
                                                </strong>
                                            </td>
                                            <td className="description">
                                                {consult.refraction !== null ? consult.refraction.os_esf_subjetiva : ''}
                                            </td>
                                            <td className="separador">-</td>
                                            <td className="description">
                                                {consult.refraction !== null ? consult.refraction.os_cil_subjetiva : ''}
                                            </td>
                                            <td className="separador">x</td>
                                            <td className="description">
                                                {consult.refraction !== null ? consult.refraction.os_eje_subjetiva : ''}
                                            </td>
                                            {/* <td className="separador">+</td> */}
                                            <td className="">
                                                {consult.refraction !== null ? consult.refraction.os_av_objetiva : ''}
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                                <div className="mb-4">
                                    <span className="row-title">Ref. Final</span>
                                    <table className="table-detail-lhs_1">
                                        <tr>
                                            <td className="title">
                                                <strong>
                                                    OD
                                                </strong>
                                            </td>
                                            <td className="description">
                                                {consult.refraction !== null ? consult.refraction.od_esf_final : ''}
                                            </td>
                                            <td className="separador">-</td>
                                            <td className="description">
                                                {consult.refraction !== null ? consult.refraction.od_cil_final : ''}
                                            </td>
                                            <td className="separador">x</td>
                                            <td className="description">
                                                {consult.refraction !== null ? consult.refraction.od_eje_final : ''}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="title">
                                                <strong>
                                                    OS
                                                </strong>
                                            </td>
                                            <td className="description">
                                                {consult.refraction !== null ? consult.refraction.os_esf_final : ''}
                                            </td>
                                            <td className="separador">-</td>
                                            <td className="description">
                                                {consult.refraction !== null ? consult.refraction.os_cil_final : ''}
                                            </td>
                                            <td className="separador">x</td>
                                            <td className="description">
                                                {consult.refraction !== null ? consult.refraction.os_eje_final : ''}
                                            </td>
                                            <td className="">
                                                {consult.refraction !== null ? consult.refraction.os_av_objetiva : ''}
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                                <div className="mb-4">
                                    <span className="row-title">Ref. Cicloplégica</span>
                                    <table className="table-detail-lhs_1">
                                        <tr>
                                            <td className="title">
                                                <strong>
                                                    OD
                                                </strong>
                                            </td>
                                            <td className="description">
                                                {consult.refraction !== null ? consult.refraction.od_esf_ciclo : ''}
                                            </td>
                                            <td className="separador">-</td>
                                            <td className="description">
                                                {consult.refraction !== null ? consult.refraction.od_cil_ciclo : ''}
                                            </td>
                                            <td className="separador">x</td>
                                            <td className="description">
                                                {consult.refraction !== null ? consult.refraction.od_eje_ciclo : ''}
                                            </td>
                                            <td className="">
                                                {consult.refraction !== null ? consult.refraction.add_final : ''} 
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="title">
                                                <strong>
                                                    OS
                                                </strong>
                                            </td>
                                            <td className="description">
                                                {consult.refraction !== null ? consult.refraction.os_esf_ciclo : ''}
                                            </td>
                                            <td className="separador">-</td>
                                            <td className="description">
                                                {consult.refraction !== null ? consult.refraction.os_cil_ciclo : ''}
                                            </td>
                                            <td className="separador">x</td>
                                            <td className="description">
                                                {consult.refraction !== null ? consult.refraction.os_eje_ciclo : ''}
                                            </td>
                                            <td className="">
                                                {consult.refraction !== null ? consult.refraction.os_av_objetiva : ''}
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                            {/* <div className="mr-4"></div>
                            <div className='d-flex flex-row w-50'>
                                <div className='d-flex flex-column w-30'>
                                    <div className='d-flex flex-row align-items-center consult-card-underlined-transparent pb-2 consult-card-height'>Vías Lágrimales</div>
                                    <div className='d-flex flex-row align-items-center consult-card-underlined-transparent pb-2 consult-card-height'>Párpados y Pestañas</div>
                                    <div className='d-flex flex-row align-items-center consult-card-underlined-transparent pb-2 consult-card-height'>Conjuntiva</div>
                                    <div className='d-flex flex-row align-items-center consult-card-underlined-transparent pb-2 consult-card-height'>Esclera y Córnea</div>
                                    <div className='d-flex flex-row align-items-center consult-card-underlined-transparent pb-2 consult-card-height'>Córnea</div>
                                    <div className='d-flex flex-row align-items-center consult-card-underlined-transparent pb-2 consult-card-height'>Cámara Anterior</div>
                                    <div className='d-flex flex-row align-items-center consult-card-underlined-transparent pb-2 consult-card-height'>Iris</div>
                                    <div className='d-flex flex-row align-items-center consult-card-underlined-transparent pb-2 consult-card-height'>Pupila</div>
                                    <div className='d-flex flex-row align-items-center consult-card-underlined-transparent pb-2 consult-card-height'>Cristalino</div>

                                    <div className='d-flex flex-row align-items-center consult-card-underlined-transparent pb-2 consult-card-height'>Gonloscopia</div>

                                    <div className='d-flex flex-row align-items-center consult-card-underlined-transparent pb-2 consult-card-height'>Vitreo</div>
                                    <div className='d-flex flex-row align-items-center consult-card-underlined-transparent pb-2 consult-card-height'>Nervio Óptico</div>
                                    <div className='d-flex flex-row align-items-center consult-card-underlined-transparent pb-2 consult-card-height'>Mácula</div>
                                </div>
                                <div className='d-flex flex-column w-30 ml2'>
                                    <div className='d-flex flex-row align-items-center justify-content-center consult-card-underlined pb-2 consult-card-height'>{consult.lamp === null ? '' : consult.lamp.od_via_lagrimal}</div>
                                    <div className='d-flex flex-row align-items-center justify-content-center consult-card-underlined pb-2 consult-card-height'>{consult.lamp === null ? '' : consult.lamp.od_parpado}</div>
                                    <div className='d-flex flex-row align-items-center justify-content-center consult-card-underlined pb-2 consult-card-height'>{consult.lamp === null ? '' : consult.lamp.od_conjuntiva}</div>
                                    <div className='d-flex flex-row align-items-center justify-content-center consult-card-underlined pb-2 consult-card-height'>{consult.lamp === null ? '' : consult.lamp.od_esclera}</div>
                                    <div className='d-flex flex-row align-items-center justify-content-center consult-card-underlined pb-2 consult-card-height'>{consult.lamp === null ? '' : consult.lamp.od_cornea}</div>
                                    <div className='d-flex flex-row align-items-center justify-content-center consult-card-underlined pb-2 consult-card-height'>{consult.lamp === null ? '' : consult.lamp.od_camara_anterior}</div>
                                    <div className='d-flex flex-row align-items-center justify-content-center consult-card-underlined pb-2 consult-card-height'>{consult.lamp === null ? '' : consult.lamp.od_iris}</div>
                                    <div className='d-flex flex-row align-items-center justify-content-center consult-card-underlined pb-2 consult-card-height'>{consult.lamp === null ? '' : consult.lamp.od_pupila}</div>
                                    <div className='d-flex flex-row align-items-center justify-content-center consult-card-underlined pb-2 consult-card-height'>{consult.lamp === null ? '' : consult.lamp.od_cristalino}</div>

                                    <div className='d-flex flex-row align-items-center consult-card-underlined pb-1 consult-card-height mt-1'><label className='gonloscopia_x'>X</label></div>

                                    <div className='d-flex flex-row align-items-center consult-card-underlined pb-2 consult-card-height'>{consult.lamp === null ? '' : consult.lamp.od_vitreo}</div>
                                    <div className='d-flex flex-row align-items-center consult-card-underlined pb-2 consult-card-height'>{consult.lamp === null ? '' : consult.lamp.od_nervio_optico}</div>
                                    <div className='d-flex flex-row align-items-center consult-card-underlined pb-2 consult-card-height'>{consult.lamp === null ? '' : consult.lamp.od_mascula}</div>
                                </div>

                                <div className='d-flex flex-column w-30 ml-2'>
                                    <div className='d-flex flex-row align-items-center consult-card-underlined pb-2 consult-card-height'>{consult.lamp === null ? '' : consult.lamp.os_via_lagrimal}</div>
                                    <div className='d-flex flex-row align-items-center consult-card-underlined pb-2 consult-card-height'>{consult.lamp === null ? '' : consult.lamp.os_parpado}</div>
                                    <div className='d-flex flex-row align-items-center consult-card-underlined pb-2 consult-card-height'>{consult.lamp === null ? '' : consult.lamp.os_conjuntiva}</div>
                                    <div className='d-flex flex-row align-items-center consult-card-underlined pb-2 consult-card-height'>{consult.lamp === null ? '' : consult.lamp.os_esclera}</div>
                                    <div className='d-flex flex-row align-items-center consult-card-underlined pb-2 consult-card-height'>{consult.lamp === null ? '' : consult.lamp.os_cornea}</div>
                                    <div className='d-flex flex-row align-items-center consult-card-underlined pb-2 consult-card-height'>{consult.lamp === null ? '' : consult.lamp.os_camara_anterior}</div>
                                    <div className='d-flex flex-row align-items-center consult-card-underlined pb-2 consult-card-height'>{consult.lamp === null ? '' : consult.lamp.os_iris}</div>
                                    <div className='d-flex flex-row align-items-center consult-card-underlined pb-2 consult-card-height'>{consult.lamp === null ? '' : consult.lamp.os_pupila}</div>
                                    <div className='d-flex flex-row align-items-center consult-card-underlined pb-2 consult-card-height'>{consult.lamp === null ? '' : consult.lamp.os_cristalino}</div>

                                    <div className='d-flex flex-row align-items-center consult-card-underlined pb-1 consult-card-height mt-1'><label className='gonloscopia_x'>X</label></div>

                                    <div className='d-flex flex-row align-items-center consult-card-underlined pb-2 consult-card-height'>{consult.lamp === null ? '' : consult.lamp.os_vitreo}</div>
                                    <div className='d-flex flex-row align-items-center consult-card-underlined pb-2 consult-card-height'>{consult.lamp === null ? '' : consult.lamp.os_nervio_optico}</div>
                                    <div className='d-flex flex-row align-items-center consult-card-underlined pb-2 consult-card-height'>{consult.lamp === null ? '' : consult.lamp.os_mascula}</div>
                                </div>
                            </div> */}

                            {/* <div className='d-flex flex-column w-50 mr-5'>
                                <div className='d-flex flex-row w-100 justify-content-center font-weight-bold'>Lensometría</div>
                                <div className='d-flex flex-row'>
                                    <div className='d-flex flex-row font-weight-bold w-10'>OD</div>
                                    <div className='d-flex flex-row w-15 justify-content-center consult-card-underlined'>{consult.lensometry !== null ? consult.lensometry.od_esf : ''}</div>
                                    <div className='d-flex flex-row w-10 justify-content-center'>-</div>
                                    <div className='d-flex flex-row w-15 justify-content-center consult-card-underlined'>{consult.lensometry !== null ? consult.lensometry.od_cil : ''}</div>
                                    <div className='d-flex flex-row w-10 justify-content-center'>x</div>
                                    <div className='d-flex flex-row w-15 justify-content-center consult-card-underlined'>{consult.lensometry !== null ? consult.lensometry.od_eje : ''}</div>
                                    <div className='d-flex flex-row w-10 justify-content-center'>+</div>
                                    <div className='d-flex flex-row w-15 justify-content-center consult-card-underlined'>{consult.lensometry !== null ? consult.lensometry.od_add : ''}</div>
                                </div>

                                <div className='d-flex flex-row mb-3'>
                                    <div className='d-flex flex-row font-weight-bold  w-10'>OS</div>
                                    <div className='d-flex flex-row w-15 justify-content-center consult-card-underlined'>{consult.lensometry !== null ? consult.lensometry.os_esf : ''}</div>
                                    <div className='d-flex flex-row w-10 justify-content-center'>-</div>
                                    <div className='d-flex flex-row w-15 justify-content-center consult-card-underlined'>{consult.lensometry !== null ? consult.lensometry.os_cil : ''}</div>
                                    <div className='d-flex flex-row w-10 justify-content-center'>x</div>
                                    <div className='d-flex flex-row w-15 justify-content-center consult-card-underlined'>{consult.lensometry !== null ? consult.lensometry.os_eje : ''}</div>
                                    <div className='d-flex flex-row w-10 justify-content-center'>+</div>
                                    <div className='d-flex flex-row w-15 justify-content-center consult-card-underlined'>{consult.lensometry !== null ? consult.lensometry.os_add : ''}</div>
                                </div>

                                <div className='d-flex flex-row w-100 justify-content-center font-weight-bold'>Ref. Objetiva</div>
                                <div className='d-flex flex-row'>
                                    <div className='d-flex flex-row font-weight-bold w-10'>OD</div>
                                    <div className='d-flex flex-row w-15 justify-content-center consult-card-underlined'>{consult.refraction !== null ? consult.refraction.od_esf_objetiva : ''}</div>
                                    <div className='d-flex flex-row w-10 justify-content-center'>-</div>
                                    <div className='d-flex flex-row w-15 justify-content-center consult-card-underlined'>{consult.refraction !== null ? consult.refraction.od_cil_objetiva : ''}</div>
                                    <div className='d-flex flex-row w-10 justify-content-center'>x</div>
                                    <div className='d-flex flex-row w-15 justify-content-center consult-card-underlined'>{consult.refraction !== null ? consult.refraction.od_eje_objetiva : ''}</div>
                                    <div className='d-flex flex-row w-10 justify-content-center'>+</div>
                                    <div className='d-flex flex-row w-15 justify-content-center consult-card-underlined'>{consult.refraction !== null ? consult.refraction.od_av_objetiva : ''}</div>
                                </div>

                                <div className='d-flex flex-row mb-3'>
                                    <div className='d-flex flex-row font-weight-bold  w-10'>OS</div>
                                    <div className='d-flex flex-row w-15 justify-content-center consult-card-underlined'>{consult.refraction !== null ? consult.refraction.os_esf_objetiva : ''}</div>
                                    <div className='d-flex flex-row w-10 justify-content-center'>-</div>
                                    <div className='d-flex flex-row w-15 justify-content-center consult-card-underlined'>{consult.refraction !== null ? consult.refraction.os_cil_objetiva : ''}</div>
                                    <div className='d-flex flex-row w-10 justify-content-center'>x</div>
                                    <div className='d-flex flex-row w-15 justify-content-center consult-card-underlined'>{consult.refraction !== null ? consult.refraction.os_eje_objetiva : ''}</div>
                                    <div className='d-flex flex-row w-10 justify-content-center'>+</div>
                                    <div className='d-flex flex-row w-15 justify-content-center consult-card-underlined'>{consult.refraction !== null ? consult.refraction.os_av_objetiva : ''}</div>
                                </div>

                                <div className='d-flex flex-row w-100 justify-content-center font-weight-bold'>Ref. Subjetiva</div>
                                <div className='d-flex flex-row'>
                                    <div className='d-flex flex-row font-weight-bold w-10'>OD</div>
                                    <div className='d-flex flex-row w-15 justify-content-center consult-card-underlined'>{consult.refraction !== null ? consult.refraction.od_esf_subjetiva : ''}</div>
                                    <div className='d-flex flex-row w-10 justify-content-center'>-</div>
                                    <div className='d-flex flex-row w-15 justify-content-center consult-card-underlined'>{consult.refraction !== null ? consult.refraction.od_cil_subjetiva : ''}</div>
                                    <div className='d-flex flex-row w-10 justify-content-center'>x</div>
                                    <div className='d-flex flex-row w-15 justify-content-center consult-card-underlined'>{consult.refraction !== null ? consult.refraction.od_eje_subjetiva : ''}</div>
                                </div>
                                <div className='d-flex flex-row justify-content-end w-75'> {consult.refraction !== null ? consult.refraction.add_subjetiva : ''} </div>
                                <div className='d-flex flex-row mb-3'>
                                    <div className='d-flex flex-row font-weight-bold w-10'>OS</div>
                                    <div className='d-flex flex-row w-15 justify-content-center consult-card-underlined'>{consult.refraction !== null ? consult.refraction.os_esf_subjetiva : ''}</div>
                                    <div className='d-flex flex-row w-10 justify-content-center'>-</div>
                                    <div className='d-flex flex-row w-15 justify-content-center consult-card-underlined'>{consult.refraction !== null ? consult.refraction.os_cil_subjetiva : ''}</div>
                                    <div className='d-flex flex-row w-10 justify-content-center'>x</div>
                                    <div className='d-flex flex-row w-15 justify-content-center consult-card-underlined'>{consult.refraction !== null ? consult.refraction.os_eje_subjetiva : ''}</div>
                                </div>

                                <div className='d-flex flex-row w-100 justify-content-center font-weight-bold'>Ref. Final</div>
                                <div className='d-flex flex-row'>
                                    <div className='d-flex flex-row font-weight-bold w-10'>OD</div>
                                    <div className='d-flex flex-row w-15 justify-content-center consult-card-underlined'>{consult.refraction !== null ? consult.refraction.od_esf_final : ''}</div>
                                    <div className='d-flex flex-row w-10 justify-content-center'>-</div>
                                    <div className='d-flex flex-row w-15 justify-content-center consult-card-underlined'>{consult.refraction !== null ? consult.refraction.od_cil_final : ''}</div>
                                    <div className='d-flex flex-row w-10 justify-content-center'>x</div>
                                    <div className='d-flex flex-row w-15 justify-content-center consult-card-underlined'>{consult.refraction !== null ? consult.refraction.od_eje_final : ''}</div>
                                </div>
                                <div className='d-flex flex-row justify-content-end w-75'> {consult.refraction !== null ? consult.refraction.add_final : ''} </div>
                                <div className='d-flex flex-rowalign-items-center mb-3'>
                                    <div className='d-flex flex-row font-weight-bold w-10'>OS</div>
                                    <div className='d-flex flex-row w-15 justify-content-center consult-card-underlined'>{consult.refraction !== null ? consult.refraction.os_esf_final : ''}</div>
                                    <div className='d-flex flex-row w-10 justify-content-center'>-</div>
                                    <div className='d-flex flex-row w-15 justify-content-center consult-card-underlined'>{consult.refraction !== null ? consult.refraction.os_cil_final : ''}</div>
                                    <div className='d-flex flex-row w-10 justify-content-center'>x</div>
                                    <div className='d-flex flex-row w-15 justify-content-center consult-card-underlined'>{consult.refraction !== null ? consult.refraction.os_eje_final : ''}</div>
                                </div>

                                <div className='d-flex flex-row w-100 justify-content-center font-weight-bold'>Ref. Cicloplégica</div>
                                <div className='d-flex flex-row'>
                                    <div className='d-flex flex-row font-weight-bold w-10'>OD</div>
                                    <div className='d-flex flex-row w-15 justify-content-center consult-card-underlined'>{consult.refraction !== null ? consult.refraction.od_esf_ciclo : ''}</div>
                                    <div className='d-flex flex-row w-10 justify-content-center'>-</div>
                                    <div className='d-flex flex-row w-15 justify-content-center consult-card-underlined'>{consult.refraction !== null ? consult.refraction.od_cil_ciclo : ''}</div>
                                    <div className='d-flex flex-row w-10 justify-content-center'>x</div>
                                    <div className='d-flex flex-row w-15 justify-content-center consult-card-underlined'>{consult.refraction !== null ? consult.refraction.od_eje_ciclo : ''}</div>
                                </div>

                                <div className='d-flex flex-row'>
                                    <div className='d-flex flex-row font-weight-bold w-10 justify-content-start'>OS</div>
                                    <div className='d-flex flex-row w-15 justify-content-center  consult-card-underlined'>{consult.refraction !== null ? consult.refraction.os_esf_ciclo : ''}</div>
                                    <div className='d-flex flex-row w-10 justify-content-center'>-</div>
                                    <div className='d-flex flex-row w-15 justify-content-center consult-card-underlined'>{consult.refraction !== null ? consult.refraction.os_cil_ciclo : ''}</div>
                                    <div className='d-flex flex-row w-10 justify-content-center'>x</div>
                                    <div className='d-flex flex-row w-15 justify-content-center consult-card-underlined'>{consult.refraction !== null ? consult.refraction.os_eje_ciclo : ''}</div>
                                </div>
                            </div> */}
                        </div>
                        <div style={{
                            padding: "18px",
                            marginBottom: "10px"
                        }}>
                            <div className="row">
                                <div className="col-12 col-md-4 d-flex flex-column">
                                    <div className="font-weight-bold">Impresión Clínica</div>
                                    <div className="consult-card-underlined">
                                        {consult.educational_plan && consult.educational_plan.historial_dx ? consult.educational_plan.historial_dx :  "- - -"}
                                    </div>
                                </div>                                                            
                            </div><br /><br />
                            <div className="row">                                
                                <div className="col-12 col-md-4 d-flex flex-column">
                                    <div className="font-weight-bold">Conducta</div>
                                    <div className="consult-card-underlined">
                                        {consult.diagnostic_plan && consult.diagnostic_plan.notes ? consult.diagnostic_plan.notes :  "- - -"}
                                    </div>
                                </div>                                       
                            </div>                            
                        </div>
                        <div className='d-flex flex-row w-100 mt-5 justify-content-center canva-group'>
                            <div className='d-flex flex-row w-50 justify-content-center' style={{ gap: '15px'}}>
                                <div className='d-flex flex-column'>
                                    <div className='d-flex flex-row justify-content-center font-weight-bold'>OD</div>
                                    <CanvasDraw
                                        ref={canvasDraw => (this.ojoIzquierdo = canvasDraw)}
                                        gridColor="rgba(150,150,150,0.17)"                                        
                                        canvasWidth={125}
                                        canvasHeight={125}
                                        disabled={true}
                                        //imgSrc={imgSrc}
                                        imgSrc={require('../../../../assets/img/icons/ojo-izquierdo-gris.png')}
                                        brushRadius={3}
                                        lazyRadius={3}                                        
                                        style={{position: 'relative'}}
                                        onChange={() => this.replaceCanvas('ojo-izquierdo')}
                                        className='dont-print'
                                    />
                                    {this.state.ojoIzquierdoImg !== null &&                                        
                                        <div style={{ backgroundImage: require('../../../../assets/img/icons/ojo-izquierdo-gris.png'), visibility: 'hidden'}} className='show-only-on-print'>
                                            <img src={this.state.ojoIzquierdoImg} width={125} height={125} />
                                        </div>                                    
                                    }
                                </div>
                                <div className='d-flex flex-column'>
                                    <div className='d-flex flex-row justify-content-center font-weight-bold'>OS</div>
                                    <CanvasDraw
                                        gridColor="rgba(150,150,150,0.17)"
                                        canvasWidth={125}
                                        canvasHeight={125}
                                        disabled={true}
                                        //imgSrc={imgSrc}
                                        imgSrc={require('../../../../assets/img/icons/ojo-derecho-gris.png')}
                                        brushRadius={3}
                                        lazyRadius={3}
                                        ref={canvasDraw => (this.ojoDerecho = canvasDraw)}
                                        style={{position: 'relative', marginLeft:20}}
                                        onChange={() => this.replaceCanvas('ojo-derecho')}
                                        className='dont-print'
                                    />
                                    {this.state.ojoDerechoImg !== null &&                                        
                                        <div style={{ backgroundImage: require('../../../../assets/img/icons/ojo-derecho-gris.png'), visibility: 'hidden' }} className='show-only-on-print'>
                                            <img src={this.state.ojoDerechoImg} width={125} height={125} />
                                        </div>                                    
                                    }                                    
                                </div>
                            </div>
                            <div className='d-flex flex-row w-50 justify-content-center' style={{ gap: '15px'}}>
                                <div className='d-flex flex-column'>
                                    <div className='d-flex flex-row justify-content-center font-weight-bold'>OD</div>
                                    <CanvasDraw
                                        gridColor="rgba(150,150,150,0.17)"
                                        canvasWidth={125}
                                        canvasHeight={125}
                                        disabled={true}
                                        //imgSrc={imgSrc}
                                        imgSrc={require('../../../../assets/img/icons/ojo-circular-izquierdo-gris.png')}
                                        brushRadius={3}
                                        lazyRadius={3}
                                        ref={canvasDraw => (this.ojoCircularIzquierdo = canvasDraw)}
                                        style={{position: 'relative'}}
                                        onChange={() => this.replaceCanvas('ojo-circular-izquierdo')}
                                        className='dont-print'
                                    />
                                    {this.state.ojoIzquierdoCircularImg !== null &&                                        
                                        <div style={{ backgroundImage: require('../../../../assets/img/icons/ojo-circular-izquierdo-gris.png'), visibility: 'hidden'}} className='show-only-on-print'>
                                            <img src={this.state.ojoIzquierdoCircularImg} width={125} height={125} />
                                        </div>                                    
                                    }                                    
                                </div>
                                <div className='d-flex flex-column'>
                                    <div className='d-flex flex-row justify-content-center font-weight-bold'>OS</div>
                                    <CanvasDraw
                                        gridColor="rgba(150,150,150,0.17)"
                                        canvasWidth={125}
                                        canvasHeight={125}
                                        disabled={true}
                                        //imgSrc={imgSrc}
                                        imgSrc={require('../../../../assets/img/icons/ojo-circular-derecho-gris.png')}
                                        brushRadius={3}
                                        lazyRadius={3}
                                        ref={canvasDraw => (this.ojoCircularDerecho = canvasDraw)}
                                        style={{position: 'relative', marginLeft:20}}
                                        onChange={() => this.replaceCanvas('ojo-circular-derecho')}
                                        className='dont-print'
                                    />
                                    {this.state.ojoDerechoCircularImg !== null &&                                        
                                        <div style={{ backgroundImage: require('../../../../assets/img/icons/ojo-circular-derecho-gris.png'), visibility: 'hidden'}} className='show-only-on-print'>
                                            <img src={this.state.ojoDerechoCircularImg} width={125} height={125} />
                                        </div>                                    
                                    } 
                                </div>
                            </div>
                        </div>                       
                        <div className="d-flex flex-row justify-content-end">
                        <button
                            type="button"
                            className='btn btn-sm btn-primary m-2'
                            onClick={(e) => {
                                e.preventDefault();                                
                                setTimeout(()=>{
                                    printActions.print(`consulta-${consult.id}`);
                                }, 300)                                
                            }}
                        >Imprimir</button>
                    </div>
                    </PrintContainer>                        
                    </div>
                }
                </div>
            </div>
        );
    }
}
