import React from 'react';
import { Link } from 'react-router-dom';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { validate, validators } from 'validate-redux-form';
import {renderSearchCreateSelect, renderField, renderSelectCustom} from "../../Utils/renderField";
import {api} from "../../../../api/api";
import {modalTextArea, render, renderRadioInput, renderTextArea} from "../../Utils/renderField/renderField";
import { connect } from 'react-redux'
import { Canvas } from "../../Utils/renderField/canvas";
import { Exis } from "../../Utils/Notations/Lamp/exis";

import './lamp_styles';

import { NervioOptico } from "../../Utils/Notations/Lamp/nervioOptico";
import TextArea from 'antd/lib/input/TextArea';

const getLenstypes = (search) => {
    return api.get("record", {search, record_type: 25})
        .then(results => results.results.map(item => {return {label: item.name, value: item.id}}))
        .catch(() => []);
};

const Form = (props) => {
    const {
        handleSubmit,
        od_via_lagrimal,
        os_via_lagrimal,
        od_parpado,
        os_parpado,
        od_conjuntiva,
        os_conjuntiva,
        od_esclera,
        os_esclera,
        od_cornea,
        os_cornea,
        od_camara_anterior,
        os_camara_anterior,
        od_iris,
        os_iris,
        od_pupila,
        os_pupila,
        od_cristalino,
        os_cristalino,

        od_nervio_optico,
        os_nervio_optico,
        od_vitreo,
        os_vitreo,
        od_macula,
        os_macula,

        od_retina_periferica,
        os_retina_periferica,
    } = props;

    const view = window.location.href.includes("reporte");

    return (
        <form onSubmit={handleSubmit} className="d-flex flex-column">
            {!view ? <h4 className='cl-titulo'><strong>LÁMPARA DE HENDIDURA</strong></h4> : null}
            <div className="d-flex flex-column justify-content-center px-0 px-sm-2 px-md-3 px-lg-5 cl-content">

                <div className='d-flex flex-row justify-content-around w-100'>
                    <div className='d-flex flex-column justify-content-start w-50 mt-4'>
                        <span className='cl-subtitulo mb-4'>
                            <span>OD</span>
                        </span>

                        {/* ---------------- Vías Lagrimales --------------------- */}
                        <div className='d-flex flex-row justify-content-end cl-labels-list'>
                            <label className="radio-inline mr-3 d-flex align-items-center justify-content-end">
                                <span style={{fontSize:12}}>Vías lagrimales</span>
                            </label>
                            <label className="radio-inline mr-3 d-flex align-items-center">
                                <Field
                                   id="od_via_lagrimal_lamp1"
                                    name="od_via_lagrimal"
                                    component={renderRadioInput}
                                    type="radio"
                                    value={"True"}
                                    disabled={view}
                                />
                                &nbsp;<span style={{fontSize:12}}>Ok</span>
                            </label>
                            <label className="radio-inline mr-3 d-flex align-items-center">
                                <Field
                                    id="od_via_lagrimal_lamp2"
                                    name="od_via_lagrimal"
                                    component={renderRadioInput}
                                    type="radio"
                                    value={"False"}
                                    disabled={view}
                                />
                                &nbsp;<span style={{fontSize:12}}>Diferido</span>
                            </label>
                            <label className="radio-inline mr-3 d-flex align-items-center">
                                <Field
                                    id="od_via_lagrimal_lamp3"
                                    name="od_via_lagrimal"
                                    component={renderRadioInput}
                                    type="radio"
                                    value={"None"}
                                    disabled={view}
                                />
                                &nbsp;<span style={{fontSize:12}}>Otro</span>
                            </label>
                        </div>
                        {od_via_lagrimal && od_via_lagrimal === 'None' &&
                        <div className='d-flex flex-row justify-content-center'>
                            <Field name="od_via_lagrimal_otro"rows={2} cols={30} component={modalTextArea} disabled={view}/>
                        </div>
                        }
                        {/* ---------------- Párpados y pestañas --------------------- */}
                        <div className='d-flex flex-row justify-content-end cl-labels-list'>
                            <label className="radio-inline mr-3 d-flex align-items-center justify-content-end">
                                <span style={{fontSize:10}}>Párpados y pestañas</span>
                            </label>
                            <label className="radio-inline mr-3 d-flex align-items-center">
                                <Field
                                    id="od_parpado_lamp1"
                                    name="od_parpado"
                                    component={renderRadioInput}
                                    type="radio"
                                    value={"True"}
                                    disabled={view}
                                />
                                &nbsp;<span style={{fontSize:12}}>Ok</span>
                            </label>
                            <label className="radio-inline mr-3 d-flex align-items-center">
                                <Field
                                    id="od_parpado_lamp2"
                                    name="od_parpado"
                                    component={renderRadioInput}
                                    type="radio"
                                    value={"False"}
                                    disabled={view}
                                />
                                &nbsp;<span style={{fontSize:12}}>Diferido</span>
                            </label>
                            <label className="radio-inline mr-3 d-flex align-items-center">
                                <Field
                                    id="od_parpado_lamp3"
                                    name="od_parpado"
                                    component={renderRadioInput}
                                    type="radio"
                                    value={"None"}
                                    disabled={view}
                                />
                                &nbsp;<span style={{fontSize:12}}>Otro</span>
                            </label>
                        </div>
                        {od_parpado && od_parpado === 'None' &&
                        <div className='d-flex flex-row justify-content-center'>
                            <Field name="od_parpado_otro" rows={2} cols={30} component={modalTextArea} disabled={view}/>
                        </div>
                        }

                        {/* ---------------- Conjuntiva --------------------- */}
                        <div className='d-flex flex-row justify-content-end cl-labels-list'>
                            <label className="radio-inline mr-3 d-flex align-items-center justify-content-end">
                                <span style={{fontSize:12}}>Conjuntiva</span>
                            </label>
                            <label className="radio-inline mr-3 d-flex align-items-center">
                                <Field
                                    id="od_conjuntiva_lamp1"
                                    name="od_conjuntiva"
                                    component={renderRadioInput}
                                    type="radio"
                                    value={"True"}
                                    disabled={view}
                                />
                                &nbsp;<span style={{fontSize:12}}>Ok</span>
                            </label>
                            <label className="radio-inline mr-3 d-flex align-items-center">
                                <Field
                                    id="od_conjuntiva_lamp2"
                                    name="od_conjuntiva"
                                    component={renderRadioInput}
                                    type="radio"
                                    value={"False"}
                                    disabled={view}
                                />
                                &nbsp;<span style={{fontSize:12}}>Diferido</span>
                            </label>
                            <label className="radio-inline mr-3 d-flex align-items-center">
                                <Field
                                    id="od_conjuntiva_lamp3"
                                    name="od_conjuntiva"
                                    component={renderRadioInput}
                                    type="radio"
                                    value={"None"}
                                    disabled={view}
                                />
                                &nbsp;<span style={{fontSize:12}}>Otro</span>
                            </label>
                        </div>
                        {od_conjuntiva && od_conjuntiva === 'None' &&
                        <div className='d-flex flex-row justify-content-center'>
                            <Field name="od_conjuntiva_otro" rows={2} cols={30} component={modalTextArea} disabled={view}/>
                        </div>
                        }

                        {/* ---------------- Esclera --------------------- */}
                        <div className='d-flex flex-row justify-content-end cl-labels-list'>
                            <label className="radio-inline mr-3 d-flex align-items-center justify-content-end">
                                <span style={{fontSize:12}}>Esclera</span>
                            </label>
                            <label className="radio-inline mr-3 d-flex align-items-center">
                                <Field
                                    id="od_esclera_lamp1"
                                    name="od_esclera"
                                    component={renderRadioInput}
                                    type="radio"
                                    value={"True"}
                                    disabled={view}
                                />
                                &nbsp;<span style={{fontSize:12}}>Ok</span>
                            </label>
                            <label className="radio-inline mr-3 d-flex align-items-center">
                                <Field
                                    id="od_esclera_lamp2"
                                    name="od_esclera"
                                    component={renderRadioInput}
                                    type="radio"
                                    value={"False"}
                                    disabled={view}
                                />
                                &nbsp;<span style={{fontSize:12}}>Diferido</span>
                            </label>
                            <label className="radio-inline mr-3 d-flex align-items-center">
                                <Field
                                    id="od_esclera_lamp3"
                                    name="od_esclera"
                                    component={renderRadioInput}
                                    type="radio"
                                    value={"None"}
                                    disabled={view}
                                />
                                &nbsp;<span style={{fontSize:12}}>Otro</span>
                            </label>
                        </div>
                        {od_esclera && od_esclera === 'None' &&
                        <div className='d-flex flex-row justify-content-center'>
                            <Field name="od_esclera_otro" rows={2} cols={30} component={modalTextArea} disabled={view}/>
                        </div>
                        }

                        {/* ---------------- Córnea --------------------- */}
                        <div className='d-flex flex-row justify-content-end cl-labels-list'>
                            <label className="radio-inline mr-3 d-flex align-items-center justify-content-end">
                                <span style={{fontSize:12}}>Córnea</span>
                            </label>
                            <label className="radio-inline mr-3 d-flex align-items-center">
                                <Field
                                    id="od_cornea_lamp1"
                                    name="od_cornea"
                                    component={renderRadioInput}
                                    type="radio"
                                    value={"True"}
                                    disabled={view}
                                />
                                &nbsp;<span style={{fontSize:12}}>Ok</span>
                            </label>
                            <label className="radio-inline mr-3 d-flex align-items-center">
                                <Field
                                    id="od_cornea_lamp2"
                                    name="od_cornea"
                                    component={renderRadioInput}
                                    type="radio"
                                    value={"False"}
                                    disabled={view}
                                />
                                &nbsp;<span style={{fontSize:12}}>Diferido</span>
                            </label>
                            <label className="radio-inline mr-3 d-flex align-items-center">
                                <Field
                                    id="od_cornea_lamp3"
                                    name="od_cornea"
                                    component={renderRadioInput}
                                    type="radio"
                                    value={"None"}
                                    disabled={view}
                                />
                                &nbsp;<span style={{fontSize:12}}>Otro</span>
                            </label>
                        </div>
                        {od_cornea && od_cornea === 'None' &&
                        <div className='d-flex flex-row justify-content-center'>
                            <Field name="od_cornea_otro" rows={2} cols={30} component={modalTextArea} disabled={view}/>
                        </div>
                        }

                        {/* ---------------- Cámara anterior --------------------- */}
                        <div className='d-flex flex-row justify-content-end cl-labels-list'>
                            <label className="radio-inline mr-3 d-flex align-items-center justify-content-end">
                                <span style={{fontSize:12}}>Cámara anterior</span>
                            </label>
                            <label className="radio-inline mr-3 d-flex align-items-center">
                                <Field
                                    id="od_camara_anterior_lamp1"
                                    name="od_camara_anterior"
                                    component={renderRadioInput}
                                    type="radio"
                                    value={"True"}
                                    disabled={view}
                                />
                                &nbsp;<span style={{fontSize:12}}>Profunda</span>
                            </label>
                            <label className="radio-inline mr-3 d-flex align-items-center">
                                <Field
                                    id="od_camara_anterior_lamp2"
                                    name="od_camara_anterior"
                                    component={renderRadioInput}
                                    type="radio"
                                    value={"False"}
                                    disabled={view}
                                />
                                &nbsp;<span style={{fontSize:12}}>Estrecha</span>
                            </label>
                            <label className="radio-inline mr-3 d-flex align-items-center">
                                <Field
                                    id="od_camara_anterior_lamp3"
                                    name="od_camara_anterior"
                                    component={renderRadioInput}
                                    type="radio"
                                    value={"None"}
                                    disabled={view}
                                />
                                &nbsp;<span style={{fontSize:12}}>Otro</span>
                            </label>
                        </div>
                        {od_camara_anterior && od_camara_anterior === 'None' &&
                        <div className='d-flex flex-row justify-content-center'>
                            <Field name="od_camara_anterior_otro" rows={2} cols={30} component={modalTextArea} disabled={view} type='text'/>
                        </div>
                        }

                        {/* ---------------- Iris --------------------- */}
                        <div className='d-flex flex-row justify-content-end cl-labels-list'>
                            <label className="radio-inline mr-3 d-flex align-items-center justify-content-end">
                                <span style={{fontSize:12}}>Iris</span>
                            </label>
                            <label className="radio-inline mr-3 d-flex align-items-center">
                                <Field
                                    id="od_iris_lamp3"
                                    name="od_iris"
                                    component={renderRadioInput}
                                    type="radio"
                                    value={"True"}
                                    disabled={view}
                                />
                                &nbsp;<span style={{fontSize:12}}>Ok</span>
                            </label>
                            <label className="radio-inline mr-3 d-flex align-items-center">
                                <Field
                                    id="od_iris_lamp3"
                                    name="od_iris"
                                    component={renderRadioInput}
                                    type="radio"
                                    value={"False"}
                                    disabled={view}
                                />
                                &nbsp;<span style={{fontSize:12}}>Diferido</span>
                            </label>
                            <label className="radio-inline mr-3 d-flex align-items-center">
                                <Field
                                    id="od_iris_lamp3"
                                    name="od_iris"
                                    component={renderRadioInput}
                                    type="radio"
                                    value={"None"}
                                    disabled={view}
                                />
                                &nbsp;<span style={{fontSize:12}}>Otro</span>
                            </label>
                        </div>
                        {od_iris && od_iris === 'None' &&
                        <div className='d-flex flex-row justify-content-center'>
                            <Field name="od_iris_otro" rows={2} cols={30} component={modalTextArea} disabled={view}/>
                        </div>
                        }
                        {/* ---------------- Pupila --------------------- */}
                        <div className='d-flex flex-row justify-content-end cl-labels-list'>
                            <label className="radio-inline mr-3 d-flex align-items-center justify-content-end">
                                <span style={{fontSize:12}}>Pupila</span>
                            </label>
                            <label className="radio-inline mr-3 d-flex align-items-center">
                                <Field
                                    id="od_pupila_lamp1"
                                    name="od_pupila"
                                    component={renderRadioInput}
                                    type="radio"
                                    value={"True"}
                                    disabled={view}
                                />
                                &nbsp;<span style={{fontSize:12}}>Ok</span>
                            </label>
                            <label className="radio-inline mr-3 d-flex align-items-center">
                                <Field
                                    id="od_pupila_lamp2"
                                    name="od_pupila"
                                    component={renderRadioInput}
                                    type="radio"
                                    value={"False"}
                                    disabled={view}
                                />
                                &nbsp;<span style={{fontSize:12}}>Diferido</span>
                            </label>
                            <label className="radio-inline mr-3 d-flex align-items-center">
                                <Field
                                    id="od_pupila_lamp3"
                                    name="od_pupila"
                                    component={renderRadioInput}
                                    type="radio"
                                    value={"None"}
                                    disabled={view}
                                />
                                &nbsp;<span style={{fontSize:12}}>Otro</span>
                            </label>

                                <label className="cl-mini-input">

                                </label>

                        </div>
                        {od_pupila && od_pupila === 'None' &&
                            <div className='d-flex flex-row justify-content-center'>
                                <Field name="od_pupila_otro" rows={2} cols={30} component={modalTextArea} disabled={view}/>
                            </div>
                        }

                        {/* ---------------- Cristalino --------------------- */}
                        <div className='d-flex flex-row justify-content-end cl-labels-list'>
                            <label className="radio-inline mr-3 d-flex align-items-center justify-content-end">
                                <span style={{fontSize:12}}>Cristalino</span>
                            </label>
                            <label className="radio-inline mr-3 d-flex align-items-center">
                                <Field
                                    id="od_cristalino_lamp1"
                                    name="od_cristalino"
                                    component={renderRadioInput}
                                    type="radio"
                                    value={"True"}
                                    disabled={view}
                                />
                                &nbsp;<span style={{fontSize:12}}>Ok</span>
                            </label>
                            <label className="radio-inline mr-3 d-flex align-items-center">
                                <Field
                                    id="od_cristalino_lamp2"
                                    name="od_cristalino"
                                    component={renderRadioInput}
                                    type="radio"
                                    value={"False"}
                                    disabled={view}
                                />
                                &nbsp;<span style={{fontSize:12}}>Diferido</span>
                            </label>
                            <label className="radio-inline mr-3 d-flex align-items-center">
                                <Field
                                    id="od_cristalino_lamp3"
                                    name="od_cristalino"
                                    component={renderRadioInput}
                                    type="radio"
                                    value={"None"}
                                    disabled={view}
                                />
                                &nbsp;<span style={{fontSize:12}}>Otro</span>
                            </label>
                            {od_cristalino && od_cristalino === 'None' &&
                                <label className="cl-mini-input">

                                </label>
                            }
                        </div>
                        {od_cristalino && od_cristalino === 'None' &&
                        <div className='d-flex flex-row justify-content-center'>
                            <Field name="od_cristalino_otro" rows={2} cols={30} component={modalTextArea} disabled={view} type='text'/>
                        </div>
                        }

                        {/* ---------------- Gonloscopia ---------------------
                                                <div className='d-flex flex-row justify-content-end cl-labels-list'>
                            <label className="radio-inline mr-3 d-flex align-items-center justify-content-end">
                                <span style={{fontSize:12}}>Gonloscopia</span>
                            </label>
                            <label className="radio-inline mr-3 d-flex align-items-center">
                                <Field
                                    name="od_nervio_optico"
                                    component={renderField}
                                    type="radio"
                                    value={"diferido"}
                                />
                                &nbsp;<span style={{fontSize:12}}>Ok</span>
                            </label>
                            <label className="radio-inline mr-3 d-flex align-items-center">
                                <Field
                                    name="od_nervio_optico"
                                    component={renderField}
                                    type="radio"
                                    value={"otro"}
                                />
                                &nbsp;<span style={{fontSize:12}}>Diferido</span>
                            </label>
                            <label className="radio-inline mr-3 d-flex align-items-center">
                                <Field
                                    name="od_nervio_optico"
                                    component={renderField}
                                    type="radio"
                                    value={"otro"}
                                />
                                &nbsp;<span style={{fontSize:12}}>Otro</span>
                            </label>
                            {od_nervio_optico && od_nervio_optico === 'otro' &&
                                <label className="cl-mini-input">
                                    <Field name="od_otro" component={renderField} type='text'/>
                                </label>
                            }
                        </div>
                        {/* ---------------- ... --------------------- */}
                    </div>
                    <div className='d-flex flex-column justify-content-start w-50 mt-4'>
                        <span className='cl-subtitulo mb-4'>
                            <span>OS</span>
                        </span>

                        {/* ---------------- Vías Lagrimales --------------------- */}
                        <div className='d-flex flex-row justify-content-end cl-labels-list'>
                            <label className="radio-inline mr-3 d-flex align-items-center justify-content-end">
                                <span style={{fontSize:12}}>Vías lagrimales</span>
                            </label>
                            <label className="radio-inline mr-3 d-flex align-items-center">
                                <Field
                                    id="os_via_lagrimal_lamp1"
                                    name="os_via_lagrimal"
                                    component={renderRadioInput}
                                    type="radio"
                                    value={"True"}
                                    disabled={view}
                                />
                                &nbsp;<span style={{fontSize:12}}>Ok</span>
                            </label>
                            <label className="radio-inline mr-3 d-flex align-items-center">
                                <Field
                                    id="os_via_lagrimal_lamp2"
                                    name="os_via_lagrimal"
                                    component={renderRadioInput}
                                    type="radio"
                                    value={"False"}
                                    disabled={view}
                                />
                                &nbsp;<span style={{fontSize:12}}>Diferido</span>
                            </label>
                            <label className="radio-inline mr-3 d-flex align-items-center">
                                <Field
                                    id="os_via_lagrimal_lamp3"
                                    name="os_via_lagrimal"
                                    component={renderRadioInput}
                                    type="radio"
                                    value={"None"}
                                    disabled={view}
                                />
                                &nbsp;<span style={{fontSize:12}}>Otro</span>
                            </label>
                        </div>
                        {os_via_lagrimal && os_via_lagrimal === 'None' &&
                        <div className='d-flex flex-row justify-content-center'>
                            <Field name="os_via_lagrimal_otro" rows={2} cols={30} component={modalTextArea} disabled={view} />
                        </div>
                        }

                        {/* ---------------- Párpados y pestañas --------------------- */}
                        <div className='d-flex flex-row justify-content-end cl-labels-list'>
                            <label className="radio-inline mr-3 d-flex align-items-center justify-content-end">
                                <span style={{fontSize:10}}>Párpados y pestañas</span>
                            </label>
                            <label className="radio-inline mr-3 d-flex align-items-center">
                                <Field
                                    id="os_parpado_lamp1"
                                    name="os_parpado"
                                    component={renderRadioInput}
                                    type="radio"
                                    value={"True"}
                                    disabled={view}
                                />
                                &nbsp;<span style={{fontSize:12}}>Ok</span>
                            </label>
                            <label className="radio-inline mr-3 d-flex align-items-center">
                                <Field
                                    id="os_parpado_lamp2"
                                    name="os_parpado"
                                    component={renderRadioInput}
                                    type="radio"
                                    value={"False"}
                                    disabled={view}
                                />
                                &nbsp;<span style={{fontSize:12}}>Diferido</span>
                            </label>
                            <label className="radio-inline mr-3 d-flex align-items-center">
                                <Field
                                    id="os_parpado_lamp3"
                                    name="os_parpado"
                                    component={renderRadioInput}
                                    type="radio"
                                    value={"None"}
                                    disabled={view}
                                />
                                &nbsp;<span style={{fontSize:12}}>Otro</span>
                            </label>
                        </div>
                        {os_parpado && os_parpado === 'None' &&
                        <div className='d-flex flex-row justify-content-center'>
                            <Field name="os_parpado_otro" rows={2} cols={30} component={modalTextArea} disabled={view}/>
                        </div>
                        }

                        {/* ---------------- Conjuntiva --------------------- */}
                        <div className='d-flex flex-row justify-content-end cl-labels-list'>
                            <label className="radio-inline mr-3 d-flex align-items-center justify-content-end">
                                <span style={{fontSize:12}}>Conjuntiva</span>
                            </label>
                            <label className="radio-inline mr-3 d-flex align-items-center">
                                <Field
                                    id="os_conjuntiva_lamp1"
                                    name="os_conjuntiva"
                                    component={renderRadioInput}
                                    type="radio"
                                    value={"True"}
                                    disabled={view}
                                />
                                &nbsp;<span style={{fontSize:12}}>Ok</span>
                            </label>
                            <label className="radio-inline mr-3 d-flex align-items-center">
                                <Field
                                    id="os_conjuntiva_lamp2"
                                    name="os_conjuntiva"
                                    component={renderRadioInput}
                                    type="radio"
                                    value={"False"}
                                    disabled={view}
                                />
                                &nbsp;<span style={{fontSize:12}}>Diferido</span>
                            </label>
                            <label className="radio-inline mr-3 d-flex align-items-center">
                                <Field
                                    id="os_conjuntiva_lamp3"
                                    name="os_conjuntiva"
                                    component={renderRadioInput}
                                    type="radio"
                                    value={"None"}
                                    disabled={view}
                                />
                                &nbsp;<span style={{fontSize:12}}>Otro</span>
                            </label>
                        </div>
                        {os_conjuntiva && os_conjuntiva === 'None' &&
                        <div className='d-flex flex-row justify-content-center'>
                            <Field name="os_conjuntiva_otro" rows={2} cols={30} component={modalTextArea} disabled={view} type='text'/>
                        </div>
                        }

                        {/* ---------------- Esclera --------------------- */}
                        <div className='d-flex flex-row justify-content-end cl-labels-list'>
                            <label className="radio-inline mr-3 d-flex align-items-center justify-content-end">
                                <span style={{fontSize:12}}>Esclera</span>
                            </label>
                            <label className="radio-inline mr-3 d-flex align-items-center">
                                <Field
                                    id="os_esclera_lamp1"
                                    name="os_esclera"
                                    component={renderRadioInput}
                                    type="radio"
                                    value={"True"}
                                    disabled={view}
                                />
                                &nbsp;<span style={{fontSize:12}}>Ok</span>
                            </label>
                            <label className="radio-inline mr-3 d-flex align-items-center">
                                <Field
                                    id="os_esclera_lamp2"
                                    name="os_esclera"
                                    component={renderRadioInput}
                                    type="radio"
                                    value={"False"}
                                    disabled={view}
                                />
                                &nbsp;<span style={{fontSize:12}}>Diferido</span>
                            </label>
                            <label className="radio-inline mr-3 d-flex align-items-center">
                                <Field
                                    id="os_esclera_lamp3"
                                    name="os_esclera"
                                    component={renderRadioInput}
                                    type="radio"
                                    value={"None"}
                                    disabled={view}
                                />
                                &nbsp;<span style={{fontSize:12}}>Otro</span>
                            </label>
                        </div>
                        {os_esclera && os_esclera === 'None' &&
                        <div className='d-flex flex-row justify-content-center'>
                            <Field name="os_esclera_otro" rows={2} cols={30} component={modalTextArea} disabled={view} type='text'/>
                        </div>
                        }
                        {/* ---------------- Córnea --------------------- */}
                        <div className='d-flex flex-row justify-content-end cl-labels-list'>
                            <label className="radio-inline mr-3 d-flex align-items-center justify-content-end">
                                <span style={{fontSize:12}}>Córnea</span>
                            </label>
                            <label className="radio-inline mr-3 d-flex align-items-center">
                                <Field
                                    id="os_cornea_lamp1"
                                    name="os_cornea"
                                    component={renderRadioInput}
                                    type="radio"
                                    value={"True"}
                                    disabled={view}
                                />
                                &nbsp;<span style={{fontSize:12}}>Ok</span>
                            </label>
                            <label className="radio-inline mr-3 d-flex align-items-center">
                                <Field
                                    id="os_cornea_lamp2"
                                    name="os_cornea"
                                    component={renderRadioInput}
                                    type="radio"
                                    value={"False"}
                                    disabled={view}
                                />
                                &nbsp;<span style={{fontSize:12}}>Diferido</span>
                            </label>
                            <label className="radio-inline mr-3 d-flex align-items-center">
                                <Field
                                    id="os_cornea_lamp3"
                                    name="os_cornea"
                                    component={renderRadioInput}
                                    type="radio"
                                    value={"None"}
                                    disabled={view}
                                />
                                &nbsp;<span style={{fontSize:12}}>Otro</span>
                            </label>
                        </div>
                        {os_cornea && os_cornea === 'None' &&
                        <div className='d-flex flex-row justify-content-center'>
                            <Field name="os_cornea_otro" rows={2} cols={30} component={modalTextArea} disabled={view} type='text'/>
                        </div>
                        }

                        {/* ---------------- Cámara anterior --------------------- */}
                        <div className='d-flex flex-row justify-content-end cl-labels-list'>
                            <label className="radio-inline mr-3 d-flex align-items-center justify-content-end">
                                <span style={{fontSize:12}}>Cámara anterior</span>
                            </label>
                            <label className="radio-inline mr-3 d-flex align-items-center">
                                <Field
                                    id="os_camara_anterior_lamp1"
                                    name="os_camara_anterior"
                                    component={renderRadioInput}
                                    type="radio"
                                    value={"True"}
                                    disabled={view}
                                />
                                &nbsp;<span style={{fontSize:12}}>Profunda</span>
                            </label>
                            <label className="radio-inline mr-3 d-flex align-items-center">
                                <Field
                                    id="os_camara_anterior_lamp2"
                                    name="os_camara_anterior"
                                    component={renderRadioInput}
                                    type="radio"
                                    value={"False"}
                                    disabled={view}
                                />
                                &nbsp;<span style={{fontSize:12}}>Estrecha</span>
                            </label>
                            <label className="radio-inline mr-3 d-flex align-items-center">
                                <Field
                                    id="os_camara_anterior_lamp3"
                                    name="os_camara_anterior"
                                    component={renderRadioInput}
                                    type="radio"
                                    value={"None"}
                                    disabled={view}
                                />
                                &nbsp;<span style={{fontSize:12}}>Otro</span>
                            </label>

                                <label className="cl-mini-input">

                                </label>

                        </div>
                        {os_camara_anterior && os_camara_anterior === 'None' &&
                        <div className='d-flex flex-row justify-content-center'>
                            <Field name="os_camara_anterior_otro" rows={2} cols={30} component={modalTextArea} disabled={view}/>
                        </div>
                        }


                        {/* ---------------- Iris --------------------- */}
                        <div className='d-flex flex-row justify-content-end cl-labels-list'>
                            <label className="radio-inline mr-3 d-flex align-items-center justify-content-end">
                                <span style={{fontSize:12}}>Iris</span>
                            </label>
                            <label className="radio-inline mr-3 d-flex align-items-center">
                                <Field
                                    id="os_iris_lamp1"
                                    name="os_iris"
                                    component={renderRadioInput}
                                    type="radio"
                                    value={"True"}
                                    disabled={view}
                                />
                                &nbsp;<span style={{fontSize:12}}>Ok</span>
                            </label>
                            <label className="radio-inline mr-3 d-flex align-items-center">
                                <Field
                                    id="os_iris_lamp2"
                                    name="os_iris"
                                    component={renderRadioInput}
                                    type="radio"
                                    value={"False"}
                                    disabled={view}
                                />
                                &nbsp;<span style={{fontSize:12}}>Diferido</span>
                            </label>
                            <label className="radio-inline mr-3 d-flex align-items-center">
                                <Field
                                    id="os_iris_lamp3"
                                    name="os_iris"
                                    component={renderRadioInput}
                                    type="radio"
                                    value={"None"}
                                    disabled={view}
                                />
                                &nbsp;<span style={{fontSize:12}}>Otro</span>
                            </label>

                                <label className="cl-mini-input">

                                </label>

                        </div>
                        {os_iris && os_iris === 'None' &&
                        <div className='d-flex flex-row justify-content-center'>
                            <Field name="os_iris_otro" rows={2} cols={30} component={modalTextArea} disabled={view} type='text'/>
                        </div>
                        }

                        {/* ---------------- Pupila --------------------- */}
                        <div className='d-flex flex-row justify-content-end cl-labels-list'>
                            <label className="radio-inline mr-3 d-flex align-items-center justify-content-end">
                                <span style={{fontSize:12}}>Pupila</span>
                            </label>
                            <label className="radio-inline mr-3 d-flex align-items-center">
                                <Field
                                    id="os_pupila_lamp1"
                                    name="os_pupila"
                                    component={renderRadioInput}
                                    type="radio"
                                    value={"True"}
                                    disabled={view}
                                />
                                &nbsp;<span style={{fontSize:12}}>Ok</span>
                            </label>
                            <label className="radio-inline mr-3 d-flex align-items-center">
                                <Field
                                    id="os_pupila_lamp2"
                                    name="os_pupila"
                                    component={renderRadioInput}
                                    type="radio"
                                    value={"False"}
                                    disabled={view}
                                />
                                &nbsp;<span style={{fontSize:12}}>Diferido</span>
                            </label>
                            <label className="radio-inline mr-3 d-flex align-items-center">
                                <Field
                                    id="os_pupila_lamp3"
                                    name="os_pupila"
                                    component={renderRadioInput}
                                    type="radio"
                                    value={"None"}
                                    disabled={view}
                                />
                                &nbsp;<span style={{fontSize:12}}>Otro</span>
                            </label>
                        </div>
                        {os_pupila && os_pupila === 'None' &&
                        <div className='d-flex flex-row justify-content-center'>
                            <Field name="os_pupila_otro" rows={2} cols={30} component={modalTextArea} disabled={view}/>
                        </div>
                        }

                        {/* ---------------- Cristalino --------------------- */}
                        <div className='d-flex flex-row justify-content-end cl-labels-list'>
                            <label className="radio-inline mr-3 d-flex align-items-center justify-content-end">
                                <span style={{fontSize:12}}>Cristalino</span>
                            </label>
                            <label className="radio-inline mr-3 d-flex align-items-center">
                                <Field
                                    id="os_cristalino_lamp1"
                                    name="os_cristalino"
                                    component={renderRadioInput}
                                    type="radio"
                                    value={"True"}
                                    disabled={view}
                                />
                                &nbsp;<span style={{fontSize:12}}>Ok</span>
                            </label>
                            <label className="radio-inline mr-3 d-flex align-items-center">
                                <Field
                                    id="os_cristalino_lamp2"
                                    name="os_cristalino"
                                    component={renderRadioInput}
                                    type="radio"
                                    value={"False"}
                                    disabled={view}
                                />
                                &nbsp;<span style={{fontSize:12}}>Diferido</span>
                            </label>
                            <label className="radio-inline mr-3 d-flex align-items-center">
                                <Field
                                    id="os_cristalino_lamp3"
                                    name="os_cristalino"
                                    component={renderRadioInput}
                                    type="radio"
                                    value={"None"}
                                    disabled={view}
                                />
                                &nbsp;<span style={{fontSize:12}}>Otro</span>
                            </label>

                                <label className="cl-mini-input">

                                </label>
                        </div>
                        {os_cristalino && os_cristalino === 'None' &&
                        <div className='d-flex flex-row justify-content-center'>
                            <Field name="os_cristalino_otro" rows={2} cols={30} component={modalTextArea} disabled={view} type='text'/>
                        </div>
                        }

                        {/* ---------------- Gonloscopia ---------------------
                        <div className='d-flex flex-row justify-content-end cl-labels-list'>
                            <label className="radio-inline mr-3 d-flex align-items-center justify-content-end">
                                <span style={{fontSize:12}}>Gonloscopia</span>
                            </label>
                            <label className="radio-inline mr-3 d-flex align-items-center">
                                <Field
                                    name="od_nervio_optico"
                                    component={renderField}
                                    type="radio"
                                    value={"diferido"}
                                />
                                &nbsp;<span style={{fontSize:12}}>Ok</span>
                            </label>
                            <label className="radio-inline mr-3 d-flex align-items-center">
                                <Field
                                    name="od_nervio_optico"
                                    component={renderField}
                                    type="radio"
                                    value={"otro"}
                                />
                                &nbsp;<span style={{fontSize:12}}>Diferido</span>
                            </label>
                            <label className="radio-inline mr-3 d-flex align-items-center">
                                <Field
                                    name="od_nervio_optico"
                                    component={renderField}
                                    type="radio"
                                    value={"otro"}
                                />
                                &nbsp;<span style={{fontSize:12}}>Otro</span>
                            </label>
                            {od_nervio_optico && od_nervio_optico === 'otro' &&
                                <label className="cl-mini-input">
                                    <Field name="od_otro" component={renderField} type='text'/>
                                </label>
                            }
                        </div>
                        {/* ------------------ ... --------------------- */}
                    </div>
                </div>

                <div className='d-flex flex-row justify-content-around w-100 mt-1'>
                    <div className='d-flex flex-column justify-content-start w-50 mt-1'>
                        <span className='cl-subtitulo invisible'>
                            <span>FONDO DE OJO DIRECTO</span>
                        </span>

                        <div className='d-flex flex-column align-items-center justify-content-center cl-input-background-group'>
                            <div className='d-flex flex-row justify-content-center'>
                                <Field name="od_gonloscopia_up" component={!view ? Exis : renderField} title='ESCALA' disabled={view} type='text'/>
                            </div>
                            <div className='d-flex flex-row justify-content-center align-items-center'>
                                <div>
                                    <Field name="od_gonloscopia_left" component={!view ? Exis : renderField} title='ESCALA' disabled={view} type='text'/>
                                </div>
                                <div><span style={{fontSize:60}}>X</span></div>
                                <div>
                                    <Field name="od_gonloscopia_right" component={!view ? Exis : renderField} title='ESCALA' disabled={view} type='text'/>
                                </div>
                            </div>
                            <div className='d-flex flex-row justify-content-center'>
                                <Field name="od_gonloscopia_down" component={!view ? Exis : renderField} title='ESCALA' disabled={view} type='text'/>
                            </div>
                            {/*
                            <div className='d-flex flex-row justify-content-center mt-2'>
                                <Field name='od_gonloscopia_note' component={modalTextArea} />
                            </div>
                            */}
                        </div>
                    </div>

                    <div className='d-flex flex-column justify-content-start w-50'>
                        <span className='cl-subtitulo invisible'>
                            <span>FONDO DE OJO DIRECTO</span>
                        </span>

                        <div className='d-flex flex-column align-items-center justify-content-center cl-input-background-group'>
                            <div className='d-flex flex-row justify-content-center'>
                                <Field name="os_gonloscopia_up" component={!view ? Exis : renderField} title='ESCALA' disabled={view} type='text'/>
                            </div>
                            <div className='d-flex flex-row justify-content-center align-items-center'>
                                <div>
                                    <Field name="os_gonloscopia_left" component={!view ? Exis : renderField} title='ESCALA' disabled={view} type='text'/>
                                </div>
                                <div><span style={{fontSize:60}}>X</span></div>
                                <div>
                                    <Field name="os_gonloscopia_right" component={!view ? Exis : renderField} title='ESCALA' disabled={view} type='text'/>
                                </div>
                            </div>
                            <div className='d-flex flex-row justify-content-center'>
                                <Field name="os_gonloscopia_down" component={!view ? Exis : renderField} title='ESCALA' disabled={view} type='text'/>
                            </div>
                            {/*
                            <div className='d-flex flex-row justify-content-center mt-2'>
                                <Field name='os_gonloscopia_note' component={modalTextArea} />
                            </div>
                            */}
                        </div>
                    </div>
                </div>

                <div className='d-flex flex-row justify-content-center w-100 mt-1'>
                    <div className='d-flex flex-column justify-content-center align-items-center w-50 mt-2'>
                        <Field
                            name="left_eye"
                            component={Canvas}
                            view={view}
                            imgSrc={require('../../../../../assets/img/icons/ojo-izquierdo-azul.png')}
                        />
                    </div>
                    <div className='d-flex flex-column justify-content-center align-items-center w-50 mt-2'>
                        <Field
                            name="right_eye"
                            component={Canvas}
                            view={view}
                            imgSrc={require('../../../../../assets/img/icons/ojo-derecho-azul.png')}
                        />
                    </div>
                </div>

                <div className='d-flex flex-row justify-content-around w-100 mt-1'>
                    <div className='d-flex flex-column justify-content-start w-50 mt-2'>
                        <span className='cl-subtitulo'>
                            <span>FONDO DE OJO</span>
                        </span>

                        <div className='d-flex flex-row justify-content-end cl-labels-list'>
                            <label className="radio-inline mr-3 d-flex align-items-center justify-content-end">
                                <span style={{fontSize:12}}>Vitreo</span>
                            </label>
                            <label className="radio-inline mr-3 d-flex align-items-center">
                                <Field
                                    id="od_vitreo_lamp1"
                                    name="od_vitreo"
                                    component={renderRadioInput}
                                    type="radio"
                                    value={"True"}
                                    disabled={view}
                                />
                                &nbsp;<span style={{fontSize:12}}>Ok</span>
                            </label>
                            <label className="radio-inline mr-3 d-flex align-items-center">
                                <Field
                                    id="od_vitreo_lamp2"
                                    name="od_vitreo"
                                    component={renderRadioInput}
                                    type="radio"
                                    value={"False"}
                                    disabled={view}
                                />
                                &nbsp;<span style={{fontSize:12}}>Diferido</span>
                            </label>
                            <label className="radio-inline mr-3 d-flex align-items-center">
                                <Field
                                    id="od_vitreo_lamp3"
                                    name="od_vitreo"
                                    component={renderRadioInput}
                                    type="radio"
                                    value={"None"}
                                    disabled={view}
                                />
                                &nbsp;<span style={{fontSize:12}}>Otro</span>
                            </label>
                        </div>
                        {od_vitreo && od_vitreo === 'None' &&
                        <div className='d-flex flex-row justify-content-center'>
                            <Field name="od_vitreo_otro" rows={2} cols={30} component={modalTextArea} disabled={view} type='text'/>
                        </div>
                        }

                        <div className='d-flex flex-row justify-content-end cl-labels-list'>
                            <label className="radio-inline mr-3 d-flex align-items-center justify-content-end">
                                <span style={{fontSize:12}}>Nervio Óptico</span>
                            </label>
                            <label className="cl-mini-input">
                                    <Field name="od_nervio_optico_escala" component={NervioOptico} disabled={view} type='text'/>
                            </label>
                            <label className="cl-mini-input">
                                    <Field name="od_nervio_optico_escala_segunda" component={NervioOptico} disabled={view} type='text'/>
                            </label>
                            <label className="radio-inline mr-3 d-flex align-items-center">
                                <Field
                                    id="od_nervio_optico_lamp1"
                                    name="od_nervio_optico"
                                    component={renderRadioInput}
                                    type="radio"
                                    value={"True"}
                                    disabled={view}
                                />
                                &nbsp;<span style={{fontSize:12}}>Ok</span>
                            </label>
                            <label className="radio-inline mr-3 d-flex align-items-center">
                                <Field
                                    id="od_nervio_optico_lamp2"
                                    name="od_nervio_optico"
                                    component={renderRadioInput}
                                    type="radio"
                                    value={"False"}
                                    disabled={view}
                                />
                                &nbsp;<span style={{fontSize:12}}>Diferido</span>
                            </label>
                            <label className="radio-inline mr-3 d-flex align-items-center">
                                <Field
                                    id="od_nervio_optico_lamp3"
                                    name="od_nervio_optico"
                                    component={renderRadioInput}
                                    type="radio"
                                    value={"None"}
                                    disabled={view}
                                />
                                &nbsp;<span style={{fontSize:12}}>Otro</span>
                            </label>
                        </div>
                        {od_nervio_optico && od_nervio_optico === 'None' &&
                        <div className='d-flex flex-row justify-content-center'>
                            <Field name="od_nervio_optico_otro" rows={2} cols={30} component={modalTextArea} disabled={view} type='text'/>
                        </div>
                        }
                        <div className='d-flex flex-row justify-content-end cl-labels-list'>
                            <label className="radio-inline mr-3 d-flex align-items-center justify-content-end">
                                <span style={{fontSize:12}}>Mácula</span>
                            </label>
                            <label className="radio-inline mr-3 d-flex align-items-center">
                                <Field
                                    id="od_macula_lamp1"
                                    name="od_macula"
                                    component={renderRadioInput}
                                    type="radio"
                                    value={"True"}
                                    disabled={view}
                                />
                                &nbsp;<span style={{fontSize:12}}>Ok</span>
                            </label>
                            <label className="radio-inline mr-3 d-flex align-items-center">
                                <Field
                                    id="od_macula_lamp2"
                                    name="od_macula"
                                    component={renderRadioInput}
                                    type="radio"
                                    value={"False"}
                                    disabled={view}
                                />
                                &nbsp;<span style={{fontSize:12}}>Diferido</span>
                            </label>
                            <label className="radio-inline mr-3 d-flex align-items-center">
                                <Field
                                    id="od_macula_lamp3"
                                    name="od_macula"
                                    component={renderRadioInput}
                                    type="radio"
                                    value={"None"}
                                    disabled={view}
                                />
                                &nbsp;<span style={{fontSize:12}}>Otro</span>
                            </label>
                        </div>
                        {od_macula && od_macula === 'None' &&
                        <div className='d-flex flex-row justify-content-center'>
                            <Field name="od_macula_otro" rows={2} cols={30} component={modalTextArea} disabled={view} type='text'/>
                        </div>
                        }
                        <div className='d-flex flex-row justify-content-end cl-labels-list'>
                            <label className="radio-inline mr-3 d-flex align-items-center justify-content-end">
                                <span style={{fontSize:12}}>Retina periférica</span>
                            </label>
                            <label className="radio-inline mr-3 d-flex align-items-center">
                                <Field
                                    id="od_retina_periferica_lamp1"
                                    name="od_retina_periferica"
                                    component={renderRadioInput}
                                    type="radio"
                                    value={"True"}
                                    disabled={view}
                                />
                                &nbsp;<span style={{fontSize:12}}>Ok</span>
                            </label>
                            <label className="radio-inline mr-3 d-flex align-items-center">
                                <Field
                                    id="od_retina_periferica_lamp2"
                                    name="od_retina_periferica"
                                    component={renderRadioInput}
                                    type="radio"
                                    value={"False"}
                                    disabled={view}
                                />
                                &nbsp;<span style={{fontSize:12}}>Diferido</span>
                            </label>
                            <label className="radio-inline mr-3 d-flex align-items-center">
                                <Field
                                    id="od_retina_periferica_lamp3"
                                    name="od_retina_periferica"
                                    component={renderRadioInput}
                                    type="radio"
                                    value={"None"}
                                    disabled={view}
                                />
                                &nbsp;<span style={{fontSize:12}}>Otro</span>
                            </label>
                        </div>
                        {od_retina_periferica && od_retina_periferica === 'None' &&
                        <div className='d-flex flex-row justify-content-center'>
                            <Field name="od_retina_periferica_otro" rows={2} cols={30} component={modalTextArea} disabled={view}/>
                        </div>
                        }
                    </div>


                    <div className='d-flex flex-column justify-content-start w-50 mt-2'>
                        <span className='cl-subtitulo'>
                            <span>FONDO DE OJO</span>
                        </span>

                        <div className='d-flex flex-row justify-content-end cl-labels-list'>
                            <label className="radio-inline mr-3 d-flex align-items-center justify-content-end">
                                <span style={{fontSize:12}}>Vitreo</span>
                            </label>
                            <label className="radio-inline mr-3 d-flex align-items-center">
                                <Field
                                    id="os_vitreo_lamp1"
                                    name="os_vitreo"
                                    component={renderRadioInput}
                                    type="radio"
                                    value={"True"}
                                    disabled={view}
                                />
                                &nbsp;<span style={{fontSize:12}}>Ok</span>
                            </label>
                            <label className="radio-inline mr-3 d-flex align-items-center">
                                <Field
                                    id="os_vitreo_lamp2"
                                    name="os_vitreo"
                                    component={renderRadioInput}
                                    type="radio"
                                    value={"False"}
                                    disabled={view}
                                />
                                &nbsp;<span style={{fontSize:12}}>Diferido</span>
                            </label>
                            <label className="radio-inline mr-3 d-flex align-items-center">
                                <Field
                                    id="os_vitreo_lamp3"
                                    name="os_vitreo"
                                    component={renderRadioInput}
                                    type="radio"
                                    value={"None"}
                                    disabled={view}
                                />
                                &nbsp;<span style={{fontSize:12}}>Otro</span>
                            </label>
                        </div>
                        {os_vitreo && os_vitreo === 'None' &&
                        <div className='d-flex flex-row justify-content-center'>
                            <Field name="os_vitreo_otro" rows={2} cols={30} component={modalTextArea} disabled={view}/>
                        </div>
                        }

                        <div className='d-flex flex-row justify-content-end cl-labels-list'>
                            <label className="radio-inline mr-3 d-flex align-items-center justify-content-end">
                                <span style={{fontSize:12}}>Nervio Óptico</span>
                            </label>
                            <label className="cl-mini-input">
                                    <Field name="os_nervio_optico_escala" component={NervioOptico} disabled={view} type='text'/>
                            </label>
                            <label className="cl-mini-input">
                                    <Field name="os_nervio_optico_escala_segunda" component={NervioOptico} disabled={view} type='text'/>
                            </label>
                            <label className="radio-inline mr-3 d-flex align-items-center">
                                <Field
                                    id="os_nervio_optico_lamp1"
                                    name="os_nervio_optico"
                                    component={renderRadioInput}
                                    type="radio"
                                    value={"True"}
                                    disabled={view}
                                />
                                &nbsp;<span style={{fontSize:12}}>Ok</span>
                            </label>
                            <label className="radio-inline mr-3 d-flex align-items-center">
                                <Field
                                    id="os_nervio_optico_lamp2"
                                    name="os_nervio_optico"
                                    component={renderRadioInput}
                                    type="radio"
                                    value={"False"}
                                    disabled={view}
                                />
                                &nbsp;<span style={{fontSize:12}}>Diferido</span>
                            </label>
                            <label className="radio-inline mr-3 d-flex align-items-center">
                                <Field
                                    id="os_nervio_optico_lamp3"
                                    name="os_nervio_optico"
                                    component={renderRadioInput}
                                    type="radio"
                                    value={"None"}
                                    disabled={view}
                                />
                                &nbsp;<span style={{fontSize:12}}>Otro</span>
                            </label>
                        </div>
                        {os_nervio_optico && os_nervio_optico === 'None' &&
                        <div className='d-flex flex-row justify-content-center'>
                            <Field name="os_nervio_optico_otro" rows={2} cols={30} component={modalTextArea} disabled={view}/>
                        </div>
                        }
                        <div className='d-flex flex-row justify-content-end cl-labels-list'>
                            <label className="radio-inline mr-3 d-flex align-items-center justify-content-end">
                                <span style={{fontSize:12}}>Mácula</span>
                            </label>
                            <label className="radio-inline mr-3 d-flex align-items-center">
                                <Field
                                    id="os_macula_lamp1"
                                    name="os_macula"
                                    component={renderRadioInput}
                                    type="radio"
                                    value={"True"}
                                    disabled={view}
                                />
                                &nbsp;<span style={{fontSize:12}}>Ok</span>
                            </label>
                            <label className="radio-inline mr-3 d-flex align-items-center">
                                <Field
                                    id="os_macula_lamp2"
                                    name="os_macula"
                                    component={renderRadioInput}
                                    type="radio"
                                    value={"False"}
                                    disabled={view}
                                />
                                &nbsp;<span style={{fontSize:12}}>Diferido</span>
                            </label>
                            <label className="radio-inline mr-3 d-flex align-items-center">
                                <Field
                                    id="os_macula_lamp3"
                                    name="os_macula"
                                    component={renderRadioInput}
                                    type="radio"
                                    value={"None"}
                                    disabled={view}
                                />
                                &nbsp;<span style={{fontSize:12}}>Otro</span>
                            </label>
                        </div>
                        {os_macula && os_macula === 'None' &&
                        <div className='d-flex flex-row justify-content-center'>
                            <Field name="os_macula_otro" rows={2} cols={30} component={modalTextArea} disabled={view}/>
                        </div>
                        }
                        <div className='d-flex flex-row justify-content-end cl-labels-list'>
                            <label className="radio-inline mr-3 d-flex align-items-center justify-content-end">
                                <span style={{fontSize:12}}>Retina periférica</span>
                            </label>
                            <label className="radio-inline mr-3 d-flex align-items-center">
                                <Field
                                    id="os_retina_periferica_lamp1"
                                    name="os_retina_periferica"
                                    component={renderRadioInput}
                                    type="radio"
                                    value={"True"}
                                    disabled={view}
                                />
                                &nbsp;<span style={{fontSize:12}}>Ok</span>
                            </label>
                            <label className="radio-inline mr-3 d-flex align-items-center">
                                <Field
                                    id="os_retina_periferica_lamp2"
                                    name="os_retina_periferica"
                                    component={renderRadioInput}
                                    type="radio"
                                    value={"False"}
                                    disabled={view}
                                />
                                &nbsp;<span style={{fontSize:12}}>Diferido</span>
                            </label>
                            <label className="radio-inline mr-3 d-flex align-items-center">
                                <Field
                                    id="os_retina_periferica_lamp3"
                                    name="os_retina_periferica"
                                    component={renderRadioInput}
                                    type="radio"
                                    value={"None"}
                                    disabled={view}
                                />
                                &nbsp;<span style={{fontSize:12}}>Otro</span>
                            </label>
                        </div>
                        {os_retina_periferica && os_retina_periferica === 'None' &&
                        <div className='d-flex flex-row justify-content-center'>
                            <Field name="os_retina_periferica_otro" rows={2} cols={30} component={modalTextArea} disabled={view}/>
                        </div>
                        }
                    </div>
                </div>

                <div className='d-flex flex-row justify-content-center w-100 mt-3'>
                    <div className='d-flex flex-column justify-content-center align-items-center w-50 mt-2'>
                        <Field
                            name="left_eye_circle"
                            component={Canvas}
                            view={view}
                            width={150}
                            height={150}
                            imgSrc={require('../../../../../assets/img/icons/ojo-circular-izquierdo-mod-azul.png')}
                        />
                    </div>
                    <div className='d-flex flex-column justify-content-center align-items-center w-50 mt-2'>
                        <Field
                            name="right_eye_circle"
                            component={Canvas}
                            view={view}
                            width={150}
                            height={150}
                            imgSrc={require('../../../../../assets/img/icons/ojo-circular-derecho-mod-azul.png')}
                        />
                    </div>
                </div>

                <div className='d-flex flex-row justify-content-center w-100 mt-5'>
                    <div className='d-flex flex-column w-100'>
                        <label className="radio-inline mr-3 d-flex align-items-center w-100">
                            Impresión clínica
                        </label>
                        <div className="d-flex flex-row w-100">
                            <Field
                                name="historial_dx"
                                component={modalTextArea}
                                className="annotations"
                                disabled={view}
                            />
                        </div>                       
                    </div>
                </div>

                <div className='d-flex flex-row justify-content-center'>
                    <div className="mb-md-4 d-flex justify-content-between flex-column flex-sm-row align-items-stretch align-items-sm-center mt-4 w-50">
                        <div className="m-2 d-flex flex-column w-100">
                            <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                <label htmlFor="notes">Notas</label>
                            </div>
                            <div className='d-flex flex-row'>
                                <label className="radio-inline mr-3 d-flex align-items-center">
                                    OD&nbsp;
                                    <Field
                                        id="type_lamp1"
                                        name="type"
                                        component={renderRadioInput}
                                        type="radio"
                                        value={"1"}
                                        disabled={view}
                                    />
                                </label>
                                <label className="radio-inline mr-3 d-flex align-items-center">
                                    OS&nbsp;
                                    <Field
                                        id="type_lamp2"
                                        name="type"
                                        component={renderRadioInput}
                                        type="radio"
                                        value={"2"}
                                        disabled={view}
                                    />
                                </label>
                                <label className="radio-inline mr-3 d-flex align-items-center">
                                    OU&nbsp;
                                    <Field
                                        id="type_lamp3"
                                        name="type"
                                        component={renderRadioInput}
                                        type="radio"
                                        value={"3"}
                                        disabled={view}
                                    />
                                </label>
                            </div>
                            <div className="d-flex flex-row w-100">
                                <div className="d-flex flex-row w-75">
                                    <Field
                                        name="notes"
                                        component={modalTextArea}
                                        className="annotations"
                                        disabled={view}
                                    />
                                </div>
                                <div className='d-flex flex-row w-25 ml-3'>
                                    <button
                                        type="button"
                                        className='btn btn-danger'
                                        onClick={()=>{props.change('notes', 'DIFERIDO');}}
                                    >DIFERIDO</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    )
};

let CrearForm = reduxForm({
    // a unique name for the form
    form: 'Lamp',    
    enableReinitialize: true,
    validate: (data) => {
        return validate(data, {
        });
    },
})(Form);

const selector = formValueSelector('Lamp');

CrearForm = connect(state => {
    const od_via_lagrimal = selector(state, "od_via_lagrimal");
    const os_via_lagrimal = selector(state, "os_via_lagrimal");
    const od_parpado = selector(state, "od_parpado");
    const os_parpado = selector(state, "os_parpado");
    const od_conjuntiva = selector(state, "od_conjuntiva");
    const os_conjuntiva = selector(state, "os_conjuntiva");
    const od_esclera = selector(state, "od_esclera");
    const os_esclera = selector(state, "os_esclera");
    const od_cornea = selector(state, "od_cornea");
    const os_cornea = selector(state, "os_cornea");
    const od_camara_anterior = selector(state, "od_camara_anterior");
    const os_camara_anterior = selector(state, "os_camara_anterior");
    const od_iris = selector(state, "od_iris");
    const os_iris = selector(state, "os_iris");
    const od_pupila = selector(state, "od_pupila");
    const os_pupila = selector(state, "os_pupila");
    const od_cristalino = selector(state, "od_cristalino");
    const os_cristalino = selector(state, "os_cristalino");
    const od_retina_periferica = selector(state, "od_retina_periferica");
    const os_retina_periferica = selector(state, "os_retina_periferica");

    const od_vitreo = selector(state, 'od_vitreo');
    const os_vitreo = selector(state, 'os_vitreo');
    const od_nervio_optico = selector(state, 'od_nervio_optico');
    const os_nervio_optico = selector(state, 'os_nervio_optico');
    const od_macula = selector(state, 'od_macula');
    const os_macula = selector(state, 'os_macula');

    return {
        od_via_lagrimal,
        os_via_lagrimal,
        od_parpado,
        os_parpado,
        od_conjuntiva,
        os_conjuntiva,
        od_esclera,
        os_esclera,
        od_cornea,
        os_cornea,
        od_camara_anterior,
        os_camara_anterior,
        od_iris,
        os_iris,
        od_pupila,
        os_pupila,
        od_cristalino,
        os_cristalino,

        od_vitreo,
        os_vitreo,
        od_nervio_optico,
        os_nervio_optico,
        od_macula,
        os_macula,

        od_retina_periferica,
        os_retina_periferica,

        enableReinitialize: true
    };

})(CrearForm);

export default CrearForm;
