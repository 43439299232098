import React from 'react';
import { Link } from 'react-router-dom';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import { validate, validators } from 'validate-redux-form';
import {renderSearchCreateSelect, renderField, renderSelectCustom} from "../../Utils/renderField";
import {api} from "../../../../api/api";
import {modalTextArea} from "../../Utils/renderField/renderField";
import { Esf } from "../../Utils/Notations/lensometry/esf";
//import { Esf } from "../../Utils/Notations/lensometry/newEsf";
import { Cil } from "../../Utils/Notations/lensometry/cil";
import { Ejz } from "../../Utils/Notations/lensometry/eje";
import { Add } from "../../Utils/Notations/lensometry/add";
import '../consult_styles';
import {Notations} from "../../Utils/Notations";
import { change } from 'redux-form';
import { Dip } from "../../Utils/Notations/autorefractor/dip";

const getLenstypes = (search) => {
    return api.get("record", {search, record_type: 25})
        .then(results => results.results.map(item => {return {label: item.name, value: item.id}}))
        .catch(() => []);
};


const Form = (props) => {
    const view = window.location.href.includes("reporte");


    const {handleSubmit} = props;
    const {
        od_esf_objetiva,
        os_esf_objetiva,
        od_cil_objetiva,
        os_cil_objetiva,
        od_esf_subjetiva,
        os_esf_subjetiva,
        od_cil_subjetiva,
        os_cil_subjetiva,
        od_esf_final,
        os_esf_final,
        od_cil_final,
        os_cil_final,
        od_esf_ciclo,
        os_esf_ciclo,
        od_cil_ciclo,
        os_cil_ciclo,

        od_esf_blan, 
        os_esf_blan,
        od_cil_blan,
        os_cil_blan,        
    } = props;


    const handleodAddFinalChange = (value, otherValue) => {                
        props.dispatch(change('Refraction', 'os_add_final', otherValue));
    };

    return (
        <form onSubmit={handleSubmit} className="d-flex flex-column">
            <div className='' style={{borderBottom:'solid 1px black'}}>
                {!view ? <h4><strong>REFRACCIÓN</strong></h4> : null}
            </div>

            <div className='d-flex flex-row justify-content-center mt-5'>
                <div className="d-flex flex-column justify-content-center px-0 px-sm-2 px-md-3 px-lg-5">
                    <div className='w-100 mb-3' style={{borderBottom:'solid 1px black'}}>
                        <span>OBJETIVA</span>
                    </div>
                    <table className="table-input-sm w-75">
                        <tbody>
                        <tr>
                            <th />
                            <th>
                                ESF
                            </th>
                            <th>

                            </th>
                            <th>
                                CIL
                            </th>
                            <th>

                            </th>
                            <th>
                                EJE
                            </th>
                            <th>

                            </th>
                            <th>
                                AV
                            </th>
                        </tr>
                        <tr>
                            <td>OD</td>
                            <td className={od_esf_objetiva >= 0 ? 'color-verde' : 'color-rojo'}>
                                <Field name="od_esf_objetiva" component={!view ? Esf : renderField}  disabled={view} title={"OD (ESF)"} className="form-control"/>
                            </td>
                            <td>
                                <strong>-</strong>
                            </td>
                            <td className={od_cil_objetiva >= 0 ? 'color-verde' : 'color-rojo'}>
                                <Field name="od_cil_objetiva" component={!view ? Cil : renderField} disabled={view} title={"OD (CIL)"} className="form-control"/>
                            </td>
                            <td>
                                <strong>x</strong>
                            </td>
                            <td className='font-weight-bold'>
                                <Field name="od_eje_objetiva" component={!view ? Ejz : renderField} disabled={view} title={"OD (EJE)"} className="form-control"/>
                            </td>
                            <td>
                                <strong>+</strong>
                            </td>
                            <td className='font-weight-bold'>
                                <Field name="od_av_objetiva" component={!view ? Notations : renderField} disabled={view} title={"OD (ADD)"} className="form-control"/>
                            </td>
                        </tr>
                        <tr>
                            <td>OS</td>
                            <td  className={os_esf_objetiva >= 0 ? 'color-verde' : 'color-rojo'}>
                                <Field name="os_esf_objetiva" component={!view ? Esf : renderField} disabled={view} title={"OS (ESF)"} className="form-control"/>
                            </td>
                            <td>
                                <strong>-</strong>
                            </td>
                            <td className={os_cil_objetiva >= 0 ? 'color-verde' : 'color-rojo'}>
                                <Field name="os_cil_objetiva" component={!view ? Cil : renderField} disabled={view} title={"OS (CIL)"} className="form-control"/>
                            </td>
                            <td>
                                <strong>x</strong>
                            </td>
                            <td className='font-weight-bold'>
                                <Field name="os_eje_objetiva" component={!view ? Ejz : renderField} disabled={view} title={"OS (EJE)"} className="form-control"/>
                            </td>
                            <td>
                                <strong>+</strong>
                            </td>
                            <td className='font-weight-bold'>
                                <Field name="os_av_objetiva" component={!view ? Notations : renderField} disabled={view} title={"OD (ADD)"} className="form-control"/>
                            </td>
                        </tr>
                        </tbody>
                    </table>

                    <div className='w-100 mb-3 mt-5' style={{borderBottom:'solid 1px black'}}>
                        <span>SUBJETIVA</span>
                    </div>
                    <table className="table-input-sm w-75">
                        <tbody>
                        <tr>
                            <th />
                            <th>
                                ESF
                            </th>
                            <th>

                            </th>
                            <th>
                                CIL
                            </th>
                            <th>

                            </th>
                            <th>
                                EJE
                            </th>

                            <th>
                                ADD
                            </th>
                        </tr>
                        <tr>
                            <td>OD</td>
                            <td className={od_esf_subjetiva >= 0 ? 'color-verde' : 'color-rojo'}>
                                <Field name="od_esf_subjetiva" component={!view ? Esf : renderField} disabled={view} title={"OD (ESF)"} className="form-control"/>
                            </td>
                            <td>
                                <strong>-</strong>
                            </td>
                            <td className={od_cil_subjetiva >= 0 ? 'color-verde' : 'color-rojo'}>
                                <Field name="od_cil_subjetiva" component={!view ? Cil : renderField} disabled={view} title={"OD (CIL)"} className="form-control"/>
                            </td>
                            <td>
                                <strong>x</strong>
                            </td>
                            <td className='font-weight-bold'>
                                <Field name="od_eje_subjetiva" component={!view ? Ejz : renderField} disabled={view} title={"OD (EJE)"} className="form-control"/>
                            </td>
                            <td rowSpan={2} className='font-weight-bold'>
                                <Field name="add_subjetiva" component={!view ? Add : renderField} disabled={view} title={"OD (ADD)"} className="form-control"/>
                            </td>
                        </tr>
                        <tr>
                            <td>OS</td>
                            <td className={os_esf_subjetiva >= 0 ? 'color-verde' : 'color-rojo'}>
                                <Field name="os_esf_subjetiva" component={!view ? Esf : renderField} disabled={view} title={"OS (ESF)"} className="form-control"/>
                            </td>
                            <td>
                                <strong>-</strong>
                            </td>
                            <td className={os_cil_subjetiva >= 0 ? 'color-verde' : 'color-rojo'}>
                                <Field name="os_cil_subjetiva" component={!view ? Cil : renderField} disabled={view} title={"OS (CIL)"} className="form-control"/>
                            </td>
                            <td>
                                <strong>x</strong>
                            </td>
                            <td className='font-weight-bold'>
                                <Field name="os_eje_subjetiva" component={!view ? Ejz : renderField} disabled={view} title={"OS (EJE)"} className="form-control"/>
                            </td>
                        </tr>
                        </tbody>
                    </table>

                    <table className="table-input-sm w-50">
                        <tbody>
                        <tr>
                            <th />
                            <th>
                                AVL
                            </th>
                            <th>
                                AVC
                            </th>
                        </tr>
                        <tr>
                            <td>OD</td>
                            <td className='font-weight-bold'>
                                <Field name="od_avl_subjetiva" component={!view ? Notations : renderField} disabled={view} title={"OD (ESF)"} className="form-control"/>
                            </td>
                            <td className='font-weight-bold'>
                                <Field name="od_avc_subjetiva" component={!view ? Notations : renderField} disabled={view} title={"OD (CIL)"} className="form-control"/>
                            </td>
                        </tr>
                        <tr>
                            <td>OS</td>
                            <td className='font-weight-bold'>
                                <Field name="os_avl_subjetiva" component={!view ? Notations : renderField} disabled={view} title={"OS (ESF)"} className="form-control"/>
                            </td>
                            <td className='font-weight-bold'>
                                <Field name="os_avc_subjetiva" component={!view ? Notations : renderField} disabled={view} title={"OS (CIL)"} className="form-control"/>
                            </td>
                        </tr>
                        </tbody>
                    </table>

                    <div className='w-100 mb-3 mt-5' style={{borderBottom:'solid 1px black'}}>
                        <span>FINAL DE LEJOS</span>
                    </div>
                    <table className="table-input-sm w-75">
                        <tbody>
                        <tr>
                            <th />
                            <th>
                                ESF
                            </th>
                            <th>

                            </th>
                            <th>
                                CIL
                            </th>
                            <th>

                            </th>
                            <th>
                                EJE
                            </th>

                            <th>
                                ADD
                            </th>
                        </tr>
                        <tr>
                            <td>OD</td>
                            <td className={od_esf_final >= 0 ? 'color-verde' : 'color-rojo'}>
                                <Field name="od_esf_final" component={!view ? Esf : renderField} disabled={view} title={"OD (ESF)"} className="form-control"/>
                            </td>
                            <td>
                                <strong>-</strong>
                            </td>
                            <td className={od_cil_final >= 0 ? 'color-verde' : 'color-rojo'}>
                                <Field name="od_cil_final" component={!view ? Cil : renderField} disabled={view} title={"OD (CIL)"} className="form-control"/>
                            </td>
                            <td>
                                <strong>x</strong>
                            </td>
                            <td className='font-weight-bold'>
                                <Field name="od_eje_final" component={!view ? Ejz : renderField} disabled={view} title={"OD (EJE)"} className="form-control"/>
                            </td>
                            <td className='font-weight-bold'>
                                <Field 
                                    name="od_add_final" 
                                    component={!view ? Add : renderField} disabled={view} title={"OD (ADD)"} className="form-control"
                                    onChange={handleodAddFinalChange}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>OS</td>
                            <td className={os_esf_final >= 0 ? 'color-verde' : 'color-rojo'}>
                                <Field name="os_esf_final" component={!view ? Esf : renderField} disabled={view} title={"OS (ESF)"} className="form-control"/>
                            </td>
                            <td>
                                <strong>-</strong>
                            </td>
                            <td className={os_cil_final >= 0 ? 'color-verde' : 'color-rojo'}>
                                <Field name="os_cil_final" component={!view ? Cil : renderField} disabled={view} title={"OS (CIL)"} className="form-control"/>
                            </td>
                            <td>
                                <strong>x</strong>
                            </td>
                            <td className='font-weight-bold'>
                                <Field name="os_eje_final" component={!view ? Ejz : renderField} disabled={view} title={"OS (EJE)"} className="form-control"/>
                            </td>
                            <td className='font-weight-bold'>
                                <Field name="os_add_final" component={!view ? Add : renderField} disabled={view} title={"OS (ADD)"} className="form-control"/>
                            </td>
                        </tr>
                        </tbody>
                    </table>

                    <table className="table-input-sm w-50">
                        <tbody>
                        <tr>
                            <th />
                            <th>
                                AVL
                            </th>
                            <th>
                                AVC
                            </th>
                        </tr>
                        <tr>
                            <td>OD</td>
                            <td className='font-weight-bold'>
                                <Field name="od_avl_final" component={!view ? Notations : renderField} disabled={view} title={"OD (ESF)"} className="form-control"/>
                            </td>
                            <td className='font-weight-bold'>
                                <Field name="od_avc_final" component={!view ? Notations : renderField} disabled={view} title={"OD (CIL)"} className="form-control"/>
                            </td>
                        </tr>
                        <tr>
                            <td>OS</td>
                            <td className='font-weight-bold'>
                                <Field name="os_avl_final" component={!view ? Notations : renderField} disabled={view} title={"OS (ESF)"} className="form-control"/>
                            </td>
                            <td className='font-weight-bold'>
                                <Field name="os_avc_final" component={!view ? Notations : renderField} disabled={view} title={"OS (CIL)"} className="form-control"/>
                            </td>
                        </tr>
                        </tbody>
                    </table>

                    <div className='w-100 mb-3 mt-5' style={{borderBottom:'solid 1px black'}}>
                        <span>FINAL DE LECTURA</span>
                    </div>
                    <table className="table-input-sm w-50">
                        <tbody>
                        <tr>
                            <th />
                            <th>
                                ADD
                            </th>                            
                            <th>
                                DIP
                            </th>                            
                        </tr>
                        <tr>
                            <td>OD</td>
                            <td className='font-weight-bold'>
                                <Field 
                                    name="od_add_lectura" 
                                    component={!view ? Add : renderField} disabled={view} title={"OD (ADD)"} className="form-control"
                                    onChange={handleodAddFinalChange}
                                />
                            </td>                            
                            {/*<td className={od_esf_final >= 0 ? 'color-verde' : 'color-rojo'}>
                                <Field name="od_dip_lectura" component={!view ? Dip : renderField} disabled={view} title={"DIP"} className="form-control"/>
                            </td>*/}
                            <td className={od_esf_final >= 0 ? 'color-verde' : 'color-rojo'}>
                                
                            </td>                            
                        </tr>
                        <tr>
                            <td>OS</td>
                            <td className='font-weight-bold'>
                                <Field name="os_add_lectura" component={!view ? Add : renderField} disabled={view} title={"OS (ADD)"} className="form-control"/> 
                            </td>                            
                            <td className={os_esf_final >= 0 ? 'color-verde' : 'color-rojo'}>
                                <Field name="os_dip_lectura" component={!view ? Dip : renderField} disabled={view} title={"DIP"} className="form-control"/>
                            </td>
                        </tr>
                        </tbody>
                    </table>


                    <div className='w-100 mb-3 mt-5' style={{borderBottom:'solid 1px black'}}>
                        <span>CICLOPLÉGICA</span>
                    </div>
                    <table className="table-input-sm w-75">
                        <tbody>
                        <tr>
                            <th />
                            <th>
                                ESF
                            </th>
                            <th>

                            </th>
                            <th>
                                CIL
                            </th>
                            <th>

                            </th>
                            <th>
                                EJE
                            </th>
                        </tr>
                        <tr>
                            <td>OD</td>
                            <td  className={od_esf_ciclo >= 0 ? 'color-verde' : 'color-rojo'}>
                                <Field name="od_esf_ciclo" component={!view ? Esf : renderField} disabled={view} title={"OD (ESF)"} className="form-control"/>
                            </td>
                            <td>
                                <strong>-</strong>
                            </td>
                            <td className={od_cil_ciclo >= 0 ? 'color-verde' : 'color-rojo'}>
                                <Field name="od_cil_ciclo" component={!view ? Cil : renderField} disabled={view} title={"OD (CIL)"} className="form-control"/>
                            </td>
                            <td>
                                <strong>x</strong>
                            </td>
                            <td className='font-weight-bold'>
                                <Field name="od_eje_ciclo" component={!view ? Ejz : renderField} disabled={view} title={"OD (EJE)"} className="form-control"/>
                            </td>
                        </tr>
                        <tr>
                            <td>OS</td>
                            <td  className={os_esf_ciclo >= 0 ? 'color-verde' : 'color-rojo'}>
                                <Field name="os_esf_ciclo" component={!view ? Esf : renderField} disabled={view} title={"OS (ESF)"} className="form-control"/>
                            </td>
                            <td>
                                <strong>-</strong>
                            </td>
                            <td className={os_cil_ciclo >= 0 ? 'color-verde' : 'color-rojo'}>
                                <Field name="os_cil_ciclo" component={!view ? Cil : renderField} disabled={view} title={"OS (CIL)"} className="form-control"/>
                            </td>
                            <td>
                                <strong>x</strong>
                            </td>
                            <td className='font-weight-bold'>
                                <Field name="os_eje_ciclo" component={!view ? Ejz : renderField} disabled={view} title={"OS (EJE)"} className="form-control"/>
                            </td>
                        </tr>
                        </tbody>
                    </table>

                            {/* Lo que se cambió **/}
                            <div
                                className='d-flex flex-row mt-4'
                                style={{ display: "flex", justifyContent: "center" }}
                            >FINAL EN LENTE DE CONTACTO ESCLERAL O RÍGIDO</div>

                    <table className='w-50 m-auto'>
                        <tbody>
                            <tr>
                                <td>
                                    <label className='radio-inline d-flex align-items-center'>
                                        ESCLERAL&nbsp;
                                        <Field
                                            name="contact_lens_type"
                                            component={renderField}
                                            type="radio"
                                            value={"1"}
                                            //onChange={()=>{props.change('od_mmhg', '')}}
                                            disabled={view}
                                        />
                                    </label>
                                </td>
                                <td>
                                    <label className='radio-inline d-flex align-items-center'>
                                        RÍGIDO&nbsp;
                                        <Field
                                            name="contact_lens_type"
                                            component={renderField}
                                            type="radio"
                                            value={"2"}
                                            //onChange={()=>{props.change('od_mmhg', '')}}
                                            disabled={view}
                                        />
                                    </label>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                            <div className='d-flex flex-row'>
                            <table className='table table-bordered w-50 optics-order-table-head mt-1'>
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th className='text-center'>OD</th>
                                        <th className='text-center'>OS</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>PWR.</td>
                                        <td><div style={{width:150}}><Field name='od_pwr' addClass='w-100' disabled={view} component={renderField} className='form-control'/></div></td>
                                        <td><div style={{width:150}}><Field name='os_pwr' addClass='w-100' disabled={view} component={renderField} className='form-control'/></div></td>
                                    </tr>
                                    <tr>
                                        <td>CYL</td>
                                        <td><div style={{width:150}}><Field name='od_cyl' addClass='w-100' disabled={view} component={renderField} className='form-control'/></div></td>
                                        <td><div style={{width:150}}><Field name='os_cyl' addClass='w-100' disabled={view} component={renderField} className='form-control'/></div></td>
                                    </tr>
                                    <tr>
                                        <td>EJE</td>
                                        <td><div style={{width:150}}><Field name='od_eje' addClass='w-100' disabled={view} component={renderField} className='form-control'/></div></td>
                                        <td><div style={{width:150}}><Field name='os_eje' addClass='w-100' disabled={view} component={renderField} className='form-control'/></div></td>
                                    </tr>
                                    <tr>
                                        <td>B.C.</td>
                                        <td><div style={{width:150}}><Field name='od_bc' addClass='w-100' disabled={view} component={renderField} className='form-control'/></div></td>
                                        <td><div style={{width:150}}><Field name='os_bc' addClass='w-100' disabled={view} component={renderField} className='form-control'/></div></td>
                                    </tr>
                                    <tr>
                                        <td>DIA</td>
                                        <td><div style={{width:150}}><Field name='od_dia' addClass='w-100' disabled={view} component={renderField} className='form-control'/></div></td>
                                        <td><div style={{width:150}}><Field name='os_dia' addClass='w-100' disabled={view} component={renderField} className='form-control'/></div></td>
                                    </tr>
                                    <tr>
                                        <td>ADD</td>
                                        <td><div style={{width:150}}><Field name='od_add' addClass='w-100' disabled={view} component={renderField} className='form-control'/></div></td>
                                        <td><div style={{width:150}}><Field name='os_add' addClass='w-100' disabled={view} component={renderField} className='form-control'/></div></td>
                                    </tr>
                                    <tr>
                                        <td>MARCA</td>
                                        <td><div style={{width:150}}><Field name='od_marca' addClass='w-100' disabled={view} component={renderField} className='form-control'/></div></td>
                                        <td><div style={{width:150}}><Field name='os_marca' addClass='w-100' disabled={view} component={renderField} className='form-control'/></div></td>
                                    </tr>
                                    <tr>
                                        <td>OTRO</td>
                                        <td><div style={{width:150}}><Field name='od_otro' addClass='w-100' disabled={view} component={renderField} className='form-control'/></div></td>
                                        <td><div style={{width:150}}><Field name='os_otro' addClass='w-100' disabled={view} component={renderField} className='form-control'/></div></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        {/* ... */}

                    <div
                            className='d-flex flex-row mt-4'
                            style={{ display: "flex", justifyContent: "center" }}
                        >FINAL EN LENTE DE CONTACTO BLANDO</div>                        
                        <div className='d-flex flex-row'>
                            <table className='table table-bordered w-50 optics-order-table-head mt-1'>
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th className='text-center'>OD</th>
                                        <th className='text-center'>OS</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>ESF</td>
                                        <td className={`${od_esf_blan >= 0 ? 'color-verde' : 'color-rojo'} width-full`}>
                                            
                                            <Field name='od_esf_blan' disabled={view} component={!view ? Esf : renderField} title={"OD (ESF)"} className='form-control' />
                                        </td>
                                        <td className={`${os_esf_blan >= 0 ? 'color-verde' : 'color-rojo'} width-full`}><div style={{width:150}}>
                                            <Field name='os_esf_blan' disabled={view} component={!view ? Esf : renderField} title={"OS (ESF)"} className='form-control' /></div>
                                        </td>
                                    </tr>                                    
                                    <tr>
                                        <td>CIL</td>
                                        <td className={`${od_cil_blan >= 0 ? 'color-verde' : 'color-rojo'} width-full`}><div style={{width:150}}>
                                            <Field name='od_cil_blan' disabled={view} component={!view ? Cil : renderField} title={"OD (CIL)"} className='form-control' />
                                            </div>
                                        </td>
                                        <td className={`${os_cil_blan >= 0 ? 'color-verde' : 'color-rojo'} width-full`}><div style={{width:150}}>
                                            <Field name='os_cil_blan' disabled={view} component={!view ? Cil: renderField} title={"OS (CIL)"} className='form-control ' />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>EJE</td>
                                        <td><div style={{width:150}} className='width-full'>
                                            <Field name='od_eje_blan' disabled={view} component={!view ? Ejz: renderField} title={"OD (EJE)"} className='form-control' />
                                            </div>
                                        </td>
                                        <td><div style={{width:150}} className='width-full'>
                                            <Field name='os_eje_blan' disabled={view} component={!view ? Ejz : renderField} title={"OS (EJE)"} className='form-control' />
                                            </div>
                                        </td>
                                    </tr>                                    
                                    <tr> 
                                        <td>MARCA</td>
                                        <td><div style={{width:150}}><Field name='od_marca_blan' addClass='w-100' disabled={view} component={renderField} className='form-control'/></div></td>
                                        <td><div style={{width:150}}><Field name='os_marca_blan' addClass='w-100' disabled={view} component={renderField} className='form-control'/></div></td>
                                    </tr>
                                    <tr>
                                        <td>OTRO</td>
                                        <td><div style={{width:150}}><Field name='od_otro_blan' addClass='w-100' disabled={view} component={renderField} className='form-control'/></div></td>
                                        <td><div style={{width:150}}><Field name='os_otro_blan' addClass='w-100' disabled={view} component={renderField} className='form-control'/></div></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                    <div className='d-flex flex-row justify-content-center'>
                        <div className="mb-md-4 d-flex justify-content-between flex-column flex-sm-row align-items-stretch align-items-sm-center mt-4 w-75">
                            <div className="m-2 d-flex flex-column w-100">
                                <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                    <label htmlFor="notes">Notas</label>
                                </div>
                                <div className='d-flex flex-row'>
                                    <label className="radio-inline mr-3 d-flex align-items-center">
                                        OD&nbsp;
                                        <Field
                                            name="type"
                                            component={renderField}
                                            type="radio"
                                            value={"1"}
                                            disabled={view}
                                        />
                                    </label>
                                    <label className="radio-inline mr-3 d-flex align-items-center">
                                        OS&nbsp;
                                        <Field
                                            name="type"
                                            component={renderField}
                                            type="radio"
                                            value={"2"}
                                            disabled={view}
                                        />
                                    </label>
                                    <label className="radio-inline mr-3 d-flex align-items-center">
                                        OU&nbsp;
                                        <Field
                                            name="type"
                                            component={renderField}
                                            type="radio"
                                            value={"3"}
                                            disabled={view}
                                        />
                                    </label>
                                </div>
                                <div className="d-flex flex-row w-100">
                                    <div className="d-flex flex-row w-75">
                                    <Field
                                        name="notes"
                                        component={modalTextArea}
                                        className="annotations"
                                        disabled={view}
                                    />
                                    </div>
                                    <div className='d-flex flex-row w-25 ml-3'>
                                        <button
                                            type="button"
                                            className='btn btn-danger'
                                            onClick={()=>{props.change('notes', 'DIFERIDO');}}
                                        >DIFERIDO</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    )
};

let CrearForm = reduxForm({
    // a unique name for the form
    form: 'Refraction',
    validate: (data) => {
        return validate(data, {
        });
    },
})(Form);

const selector = formValueSelector('Refraction');

CrearForm = connect(state => {
    const od_esf_objetiva = selector(state, 'od_esf_objetiva');
    const os_esf_objetiva = selector(state, 'os_esf_objetiva');
    const od_cil_objetiva = selector(state, 'od_cil_objetiva');
    const os_cil_objetiva = selector(state, 'os_cil_objetiva');

    const od_esf_subjetiva = selector(state, 'od_esf_subjetiva');
    const os_esf_subjetiva = selector(state, 'os_esf_subjetiva');
    const od_cil_subjetiva = selector(state, 'od_cil_subjetiva');
    const os_cil_subjetiva = selector(state, 'os_cil_subjetiva');

    const od_esf_final = selector(state, 'od_esf_final');
    const os_esf_final = selector(state, 'os_esf_final');
    const od_cil_final = selector(state, 'od_cil_final');
    const os_cil_final = selector(state, 'os_cil_final');

    const od_esf_ciclo = selector(state, 'od_esf_ciclo');
    const os_esf_ciclo = selector(state, 'os_esf_ciclo');
    const od_cil_ciclo = selector(state, 'od_cil_ciclo');
    const os_cil_ciclo = selector(state, 'os_cil_ciclo');

    const od_add_final = selector(state, 'od_add_final');
    const os_add_final = selector(state, 'os_add_final');

    const od_esf_blan = selector(state, 'od_esf_blan');
    const os_esf_blan = selector(state, 'os_esf_blan');
    const od_cil_blan = selector(state, 'od_cil_blan');
    const os_cil_blan = selector(state, 'os_cil_blan');    

    return {
        od_esf_objetiva,
        os_esf_objetiva,
        od_cil_objetiva,
        os_cil_objetiva,
        od_esf_subjetiva,
        os_esf_subjetiva,
        od_cil_subjetiva,
        os_cil_subjetiva,
        od_esf_final,
        os_esf_final,
        od_cil_final,
        os_cil_final,
        od_esf_ciclo,
        os_esf_ciclo,
        od_cil_ciclo,
        os_cil_ciclo,
        od_add_final,
        os_add_final,

        od_esf_blan, 
        os_esf_blan,
        od_cil_blan,
        os_cil_blan,        

        enableRenitialize: true
    }
    
    
})(CrearForm);

export default CrearForm;
