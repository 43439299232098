import { connect } from 'react-redux';
import Recipe from './Recipe';
import { actions } from '../../../../redux/modules/consultPrint';
import { resetStore } from "../../../../redux/modules/login";

const ms2p = (state) => {
    return {
        ...state.print_consult,
    };
};

const md2p = { ...actions, resetStore };

export default connect(ms2p, md2p)(Recipe);
