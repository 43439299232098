const url = 'medical_history';
const urlDoctors = 'doctors';
import { api } from "api";
import moment from "moment";
import Swal from 'sweetalert2';
import { push } from 'react-router-redux';
import { goBack } from 'react-router-redux';
import { initialize as initializeForm } from 'redux-form';
import { createActions, handleActions } from 'redux-actions';

const LOADER_MEDICAL_HISTORY = 'LOADER_MEDICAL_HISTORY';
const SET_PATIENT_INFO = 'SET_PATIENT_INFO';
const SET_SURGERY_HISTORY = 'SET_SURGERY_HISTORY';
const SET_CONSULT_HISTORY = 'SET_CONSULT_HISTORY';
const SET_DIAGNOSTIC_HISTORY = 'SET_DIAGNOSTIC_HISTORY';
const OPEN_MODAL_SCAN = 'OPEN_MODAL_SCAN';
const OPEN_MODAL_UPDATE_SCAN = 'OPEN_MODAL_UPDATE_SCAN';
const CARGANDO_ARCHIVO = "CARGANDO_ARCHIVO";

const getPatientInfo = (patient_id) =>  (dispatch, getStore) => {
    api.get(`patients/${patient_id}`).catch((error) => {
        dispatch({type: LOADER_MEDICAL_HISTORY, cargando: false});
        Swal(
            'Error',
            error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).then((data) => {
        if(data){

            dispatch({type: SET_PATIENT_INFO, patient: data});
        }

        dispatch({type: LOADER_MEDICAL_HISTORY, cargando: false});
    })
};

const getSurgeryHistory = (patient_id) =>  (dispatch, getStore) => {
    dispatch({type: LOADER_MEDICAL_HISTORY, cargando: true});

    api.get(`${url}/getSurgeryHistory`, {patient_id}).then((data) => {
        if(data){
            console.log("HISTORIAL DE CIRUGÍA: ", data);
            dispatch({type: SET_SURGERY_HISTORY, surgery_history: data});
        }

        dispatch({type: LOADER_MEDICAL_HISTORY, cargando: false});
    }).catch((error) => {
        dispatch({type: LOADER_MEDICAL_HISTORY, cargando: false});
        Swal(
            'Error',
            error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    });
};

const openModalScan = (open) => (dispatch, getStore) => {
    dispatch({type: OPEN_MODAL_SCAN, open_modal_scan: open});
}

const openModalUpdateScan = (open) => (dispatch, getStore) => {
    dispatch({type: OPEN_MODAL_UPDATE_SCAN, open_modal_update_scan: open});
}

const saveScan = (files) => (dispatch, getStore) => {
    let formData = getStore().form.InformationSheet.values;
    formData.files = files;

    console.log("Form Data Save Scan: ", formData);
    dispatch({type: CARGANDO_ARCHIVO, cargando_archivo: true});
    api.postAttachments(`${url}/saveScan`, formData, files).then((data) => {
        if(data){
            dispatch({type: OPEN_MODAL_SCAN, open_modal_scan: false});
            dispatch({type: OPEN_MODAL_UPDATE_SCAN, open_modal_update_scan: false});
            console.log("SAVE SCAN RESPONSE: ", data);
            Swal('Éxito', 'El archivo se ha creado correctamente', 'success')
            .then(() => {

                dispatch(getConsultHistory(formData.patient_id,1));
            })
        }

        dispatch({type: LOADER_MEDICAL_HISTORY, cargando: false});
    }).catch((error) => {
        dispatch({type: LOADER_MEDICAL_HISTORY, cargando: false});
        Swal(
            'Error',
            error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).finally(()=>{
        dispatch({type: CARGANDO_ARCHIVO, cargando_archivo: false});
    });
}

const getConsultHistory = (patient_id, page) =>  (dispatch, getStore) => {
    dispatch({type: LOADER_MEDICAL_HISTORY, cargando: true});

    api.get(`${url}/getConsultHistory`, {patient_id, page}).then((data) => {
        if(data){ 
            console.log("HISTORIAL DE CONSULTA: ", data);
            dispatch({type: SET_CONSULT_HISTORY, consult_history: data});
        }

        dispatch({type: LOADER_MEDICAL_HISTORY, cargando: false});
    }).catch((error) => {
        dispatch({type: LOADER_MEDICAL_HISTORY, cargando: false});
        Swal(
            'Error',
            error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    });
};

const getDiagnosticHistory = (patient_id) => (dispatch, getStore) => {
    dispatch({type: LOADER_MEDICAL_HISTORY, cargando: true});

    api.get(`${url}/getDiagnosticHistory`, {patient_id}).then((data) => {
        if(data){
            console.log("HISTORIAL DE DIAGNÓSTICO: ", data);
            dispatch({type: SET_DIAGNOSTIC_HISTORY, diagnostic_history: data});
        }

        dispatch({type: LOADER_MEDICAL_HISTORY, cargando: false});
    }).catch((error) => {
        dispatch({type: LOADER_MEDICAL_HISTORY, cargando: false});
        Swal(
            'Error',
            error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    });
};

const deleteScanAppointment = (appointment_id, patient_id) => (dispatch) => {
    Swal({
        title: '¿Desea eliminar este elemento?',
        text: 'No puede deshacer esta acción',
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sí, eliminar',
        cancelButtonText: 'No, cancelar',
        reverseButtons: true
    }).then((result) => {
        if (result.value) {
            dispatch({type: CARGANDO_ARCHIVO, cargando: true});
            api.post(`${url}/deleteScanAppointment`, {appointment: appointment_id}).then((data) => {
                if(data){
                    Swal('Éxito', 'El archivo se ha eliminado correctamente', 'success')
                    .then(() => {
                        dispatch({type: OPEN_MODAL_SCAN, open_modal_scan: false});
                        dispatch({type: OPEN_MODAL_UPDATE_SCAN, open_modal_update_scan: false});
                        dispatch(getConsultHistory(patient_id, 1));
                    })
                }
            }).catch((error) => {
                Swal(
                    'Error',
                    error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
                    'error'
                );
            }).finally(()=>{
                dispatch({type: CARGANDO_ARCHIVO, cargando_archivo: false});
            });
        }
    });
}

export const actions = {
    getPatientInfo,
    getSurgeryHistory,
    openModalScan,
    openModalUpdateScan,
    saveScan,
    getConsultHistory,
    getDiagnosticHistory,
    deleteScanAppointment,
};

export const reducer = {
    [LOADER_MEDICAL_HISTORY]: (state, { cargando }) => {
        return {...state, cargando }
    },
    [SET_PATIENT_INFO]: (state, { patient }) => {
        return {...state, patient }
    },
    [SET_SURGERY_HISTORY]: (state, { surgery_history }) => {
        return {...state, surgery_history }
    },
    [SET_CONSULT_HISTORY]: (state, { consult_history }) => {
        return {...state, consult_history }
    },
    [SET_DIAGNOSTIC_HISTORY]: (state, { diagnostic_history }) => {
        return {...state, diagnostic_history }
    },
    [OPEN_MODAL_SCAN]: (state, { open_modal_scan }) => {
        return {...state, open_modal_scan }
    },
    [OPEN_MODAL_UPDATE_SCAN]: (state, { open_modal_update_scan }) => {
        return {...state, open_modal_update_scan }
    },
    [CARGANDO_ARCHIVO]: (state, { cargando_archivo }) => {
        return {...state, cargando_archivo }
    },
}

export const initialState = {
    cargando: false,
    patient: null,
    surgery_history: [],
    consult_history: [],
    diagnostic_history: [],
    open_modal_scan: false,
    open_modal_update_scan: false,
    cargando_archivo: false,
};

export default handleActions(reducer, initialState)
