import React, { Component } from "react";

export default class ConsultSheet extends Component {
  componentWillUnmount() {
      this.props.resetStore();
  }

  componentWillMount() {
    /*
      if (this.props.match.params.id) {
          this.props.getDiagnosticPlan(this.props.match.params.id);
      }      
    */
  }
  
  render(){
    const handlePrint = () => {
      const originalContent = document.body.innerHTML;
      const printContent = document.querySelector('.print-area').innerHTML;
      
      document.body.innerHTML = `
        <div style="width:800px;margin:0 auto;background:white;color:black;">
          ${printContent}
        </div>
      `;
      
      window.print();
      document.body.innerHTML = originalContent;
      //window.close();
    };

    return(
      <React.Fragment>
        <style>
          {`
            @media print {
              @page {
                size: A4;
                margin: 0;
              }
              body {
                -webkit-print-color-adjust: exact !important;
                print-color-adjust: exact !important;
                background: white !important;
              }
              .no-print {
                display: none !important;
              }
              .print-area {
                all: initial;
                width: 100% !important;
                position: static !important;
                overflow: visible !important;
              }
              .print-content {
                width: 800px;
                margin: 0 auto;
                padding: 20px;
                background: white !important;
                color: black !important;
              }
            }
          `}
        </style>       
        <div
            className="dont-print"
            style={{
                display: "flex",
                justifyContent: "right",
                margin: "20px 20px 20px 0px",
            }}
        >
            <button
                className="btn btn-primary ml-3 mr-3"
                style={{
                    maxWidth: "100px",
                }}
                //onClick={() => print()}
                onClick={handlePrint}
            >
                IMPRIMIR
            </button>
        </div>
        <div
          className="print-area"
          style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "black"
          }}
        >
          <div
            className="print-content"
            style={{
                width: "800px",
                height: "100%",
                backgroundColor: "white",                  
            }}
          >
          <h3>Hoja de consulta acá</h3>
          </div>        
        </div>       
      </React.Fragment>
    )
  }
}